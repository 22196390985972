import { FormatDate } from "../../../utils";
import { baseUrl, deleteAttachment } from "../../../api";
import CustomDialog from "../../../components/CustomDialog";
import { Button } from "@mui/material";

export default function AttachmentItem({ id, name, ext, url, update_date }) {
  return (
    <li className="file-item">
      <div className="overflow-hidden">
        <p className="mb-2 truncate">
          {name}.{ext}
        </p>
        <div className="text-[var(--color-neutral-400)] truncate">
          <i className="ri-time-line text-base mr-[9px]"></i>
          <span>{FormatDate(update_date)}</span>
        </div>
      </div>
      <div className="flex gap-9">
        <CustomDialog
          icon={<i className="ri-pencil-line"></i>}
          dialogTitle={"重新命名"}
          inputProps={{
            url: `/api/file/update/attachment/${id}`,
            method: "patch",
            props: { name: name },
          }}
          reload={true}
        />
        <Button
          sx={{
            color: "var(--color-neutral-900)",
            p: 0,
            minWidth: "auto",
            ":hover": { bgcolor: "transparent" },
          }}
          href={baseUrl + url}
          target="_blank"
          download={name}
        >
          <i className="ri-download-2-line"></i>
        </Button>
        <CustomDialog
          icon={<i className="ri-delete-bin-7-line"></i>}
          confirmFuncion={() => deleteAttachment(id)}
          dialogTitle={"確定要刪除嗎？"}
          reload={true}
        />
      </div>
    </li>
  );
}
