import { useContext, useEffect, useState } from "react";
import { getContactorsByTeam, getTeamList } from "../../../api";
import {
  // Checkbox,
  Divider,
  FormControl,
  // FormControlLabel,
  MenuItem,
  Select,
} from "@mui/material";
import CustomModal from "../../../components/CustomModal";
import { customizedMailContext, receiverContext } from "./MailContent";
import { FixedSizeList } from "react-window";
import { useCheckedAll, useSearch, useShowPopup } from "../../../hooks";
import CustomAlertDialog from "../../../components/CustomAlertDialog";
import { CheckAllButton } from "../../../components/Button";
import SearchInput from "../../../components/SearchInput";

export default function ContactList() {
  const [name, setName] = useState("");
  const [teamList, setTeamList] = useState([]);
  const [data, setData] = useState([]);
  const [receiverList, setReceiverList] = useState([]);
  const list = receiverList
    .filter((item) => item.status === "normal")
    .map((item) => item.receiver_id);
  const { handleSetReceivers } = useContext(receiverContext);
  const { selectedCustomizedMail } = useContext(customizedMailContext);
  const { getSearchValue, setSearchValue } = useSearch();
  const { setFalse, getShowPopup, setIsShow } = useShowPopup();
  const {
    getCheckState,
    setCheckState,
    getSelectedItems,
    setSelectedItemsState,
  } = useCheckedAll(
    receiverList.filter((item) => item.status === "normal").length
    // receiverList.length
  );

  useEffect(() => {
    getTeamList().then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        setTeamList(response.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (receiverList.length === 0) {
      setCheckState(false);
    } else {
      const result = getSelectedItems().filter(
        (item) => list.indexOf(item.receiver_id) > -1
      );
      if (result.length === list.length) {
        setCheckState(true);
      } else {
        setCheckState(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiverList, list]);

  /**
   * 選擇群組後向後端取得該群組收件者（可判斷無效、封鎖信箱）
   */
  const handleChange = (e) => {
    const id = teamList.filter((item) => item.name === e.target.value)[0]
      .team_id;
    setName(e.target.value);
    getContactorsByTeam().then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        const result = response.data.filter((item) => item.team_id === id);
        if (result[0]) {
          setReceiverList(result[0].data);
          setData(result[0].data);
        }
      }
    });
  };

  const checkboxHandler = (e) => {
    const isSelected = e.target.checked;
    const receiver_id = parseInt(e.target.name);
    const email = e.target.value;
    setSelectedItemsState(
      isSelected,
      [...getSelectedItems(), { receiver_id, email }],
      getSelectedItems().filter((item) => item.receiver_id !== receiver_id)
    );
  };

  /**
   * 全選
   */
  const checkedAllHandler = (e) => {
    const isSelected = e.target.checked;
    const set = new Set();
    let result = [];
    if (isSelected) {
      getSelectedItems()
        .concat(
          receiverList.filter(
            (item) =>
              getSelectedItems().indexOf({
                id: item.receiver_id,
                email: item.email,
              }) === -1 && item.status === "normal"
          )
        )
        .forEach((item) => {
          if (!set.has(item.receiver_id)) {
            set.add(item.receiver_id);
            result.push(item);
          }
        });
    }
    setSelectedItemsState(
      isSelected,
      result,
      getSelectedItems().filter((item) => list.indexOf(item.receiver_id) === -1)
    );
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setReceiverList(
      data.filter(
        (item) =>
          item.receiver_name.includes(value) ||
          item.email.includes(value) ||
          (item.unit !== null && item.unit.includes(value))
      )
    );
  };

  const item = ({ index, style, data }) => (
    <label
      key={index}
      style={style}
      aria-disabled={data[index].status !== "normal"}
      className="px-9 py-4 mb-0 aria-disabled:bg-[var(--color-neutral-100)] aria-disabled:text-[var(--color-neutral-400)]"
    >
      <input
        type="checkbox"
        name={data[index].receiver_id.toString()}
        value={data[index].email}
        checked={
          getSelectedItems().filter(
            (item) => item.receiver_id === data[index].receiver_id
          ).length > 0
        }
        disabled={data[index].status !== "normal"}
        onChange={checkboxHandler}
      />
      <div className="inline-flex w-[calc(100%-29px)] ml-4 items-center gap-[30px]">
        <span className="w-40 truncate">{data[index].unit}</span>
        <span className="w-20 truncate">{data[index].receiver_name}</span>
        <span className="flex-1 truncate">{data[index].email}</span>
      </div>
    </label>
    // <FormControlLabel
    //   key={index}
    //   style={style}
    //   sx={{
    //     m: 0,
    //     px: 4.5,
    //     py: 2,
    //     ":hover": { backgroundColor: "var(--color-primary-5)" },
    //     "& .MuiCheckbox-root": { p: 0, mr: 2 },
    //     "& .MuiFormControlLabel-label": {
    //       display: "flex",
    //       gap: 3.75,
    //     },
    //   }}
    //   control={
    //     <Checkbox
    //       size="sm"
    //       value={data[index].email}
    //       name={data[index].receiver_id.toString()}
    //       checked={
    //         getSelectedItems().filter(
    //           (item) => item.receiver_id === data[index].receiver_id
    //         ).length > 0
    //       }
    //       onChange={checkboxHandler}
    //     />
    //   }
    //   label={
    //     <>
    //       <span className="w-40 truncate">{data[index].unit}</span>
    //       <span className="w-20 truncate">{data[index].receiver_name}</span>
    //       <span className="w-[317px] truncate">{data[index].email}</span>
    //     </>
    //   }
    // ></FormControlLabel>
  );

  const handleCancel = () => {
    setSelectedItemsState(
      getCheckState(),
      getSelectedItems(),
      getSelectedItems()
    );
  };

  /**
   * 插入按鈕執行動作
   */
  const handleConfirm = () => {
    const result = getSelectedItems().map((item) => item.email);
    handleSetReceivers(result);
  };

  return (
    <>
      <CustomModal
        disabled={
          selectedCustomizedMail && selectedCustomizedMail.id ? true : false
        }
        className={"btn third-btn !ml-4"}
        maxWidth={744}
        buttonTitle={"選取聯絡人"}
        modalTitle={
          <>
            <p className="px-9 mt-4">選取聯絡人</p>
            <p className="px-9 my-6 p2 text-[var(--color-neutral-800)] text-base">
              可選多個群組中的聯絡人，系統會自動排除重複的名單
            </p>
            <div className="px-9 flex justify-between items-center gap-[40px]">
              <CheckAllButton
                data={receiverList}
                state={getCheckState()}
                onChange={checkedAllHandler}
              />
              <div className="flex-1 h-9">
                <FormControl fullWidth>
                  <Select
                    displayEmpty
                    value={name}
                    onChange={handleChange}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return "選擇群組";
                      } else {
                        return selected;
                      }
                    }}
                    sx={{ "& .MuiOutlinedInput-input": { py: 0.75, pl: 2 } }}
                  >
                    {teamList.map((item) => (
                      <MenuItem
                        key={item.team_id}
                        value={item.name}
                        sx={{
                          pl: 3.75,
                          ":hover": {
                            bgcolor: "var(--color-primary-5)",
                          },
                          ":focus": {
                            bgcolor: "var(--color-primary-5)",
                            color: "var(--color-primary-1)",
                          },
                        }}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <SearchInput
                searchValue={getSearchValue()}
                onChange={handleSearch}
              />
            </div>
            <Divider sx={{ mt: 3, mb: 1.5 }} />
          </>
        }
        dialogActions={<h4>已選取 {getSelectedItems().length} 個</h4>}
        cancelFunction={handleCancel}
        confirmFunction={handleConfirm}
      >
        <p className="px-9 text-[var(--color-neutral-600)]">
          {name}
          {name && `(${receiverList.length})`}
        </p>
        {name && (
          <FixedSizeList
            height={receiverList.length > 6 ? 6 * 56 : receiverList.length * 56}
            itemCount={receiverList.length}
            itemSize={56}
            width={744}
            itemData={receiverList.sort((a, b) => {
              if (
                (a.status === "blocked" || a.status === "invalid") &&
                b.status === "normal"
              ) {
                return 1;
              }
              if (
                a.status === "normal" &&
                (b.status === "blocked" || b.status === "invalid")
              ) {
                return -1;
              }
              return 0;
            })}
          >
            {item}
          </FixedSizeList>
        )}
        <Divider />
      </CustomModal>
      {getShowPopup().show && (
        <CustomAlertDialog
          show={getShowPopup().show}
          message={getShowPopup().message}
          confirmFunction={() => setIsShow(false)}
        />
      )}
    </>
  );
}
