import { createContext, useEffect, useState } from "react";
import { getLocalStorage } from "./utils";
import Login from "./pages/Login";
import { menuContext } from "./context";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import { Routes } from "./route";
import { useLocation } from "react-router-dom";

export const sidebarContext = createContext();

export default function App() {
  const root = document.documentElement;
  const location = useLocation();

  const [showSidebar, setShowSidebar] = useState(true);
  const [isLogin, setIsLogin] = useState(
    getLocalStorage("token") === "" ? false : true
  );
  const [menu, setMenu] = useState({
    text: ["電子報", "信件管理"],
    href: ["/", "/mail/sended"],
  });

  root.setAttribute("data-theme", getLocalStorage("mode"));

  useEffect(() => {
    setIsLogin(getLocalStorage("token") === "" ? false : true);
  }, [location.pathname]);

  if (!isLogin) {
    return <Login />;
  }

  return (
    <sidebarContext.Provider value={{ showSidebar, setShowSidebar }}>
      <menuContext.Provider value={{ menu, setMenu }}>
        <Navbar />
        <Sidebar />
        <Routes />
      </menuContext.Provider>
    </sidebarContext.Provider>
  );
}
