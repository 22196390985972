import { useRef, useState } from "react";

/**
 *
 * @typedef {Object} UseSearchReturns
 * @property {Function} setSearchValue Sets a new search value
 * @property {Function} getSearchValue Returns a search value
 */
export function useSearch() {
  const [value, setValue] = useState("");

  function setSearchValue(newValue) {
    setValue(newValue);
  }

  function getSearchValue() {
    return value;
  }

  return { setSearchValue, getSearchValue };
}

export function useShowPopup() {
  const [message, setMessage] = useState({});
  const [show, setShow] = useState(false);
  const [path, setPath] = useState(false);

  function setFalse(show, errorMesg, path) {
    setShow(show);
    setMessage({ title: "發生錯誤", content: errorMesg });
    if (path) {
      setPath(path);
    }
  }

  function setSuccess(show, title, mesg) {
    setShow(show);
    setMessage({ title: title, content: mesg });
  }

  function getShowPopup() {
    return { show, message, path };
  }

  function setIsShow(value) {
    setShow(value);
  }

  return { setFalse, setSuccess, getShowPopup, setIsShow };
}

export function useOpen() {
  const [isOpen, setIsOpen] = useState(false);

  function getOpen() {
    return isOpen;
  }

  function setOpen(value) {
    setIsOpen(value);
  }

  return { getOpen, setOpen };
}

export function useSort(value) {
  const [clicked, setClicked] = useState(new Array(value).fill(0));

  function getClicked(position) {
    return clicked[position];
  }

  function updateClicked(position) {
    const updateClick = clicked.map((item, index) => {
      return index === position ? (item + 1 > 2 ? 0 : item + 1) : 0;
    });
    return updateClick;
  }

  function sortByPosition(newValue) {
    setClicked(newValue);
  }

  return { getClicked, updateClicked, sortByPosition };
}

export function useBackdropClick() {
  const ref = useRef();

  function backdropClick(e, func) {
    if (ref.current && !ref.current.contains(e.target)) {
      func();
    }
  }

  return { backdropClick };
}

export function useCheckedAll(length) {
  const [checked, setChecked] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  function getCheckState() {
    return checked;
  }

  function getSelectedItems() {
    return selectedItems;
  }

  function setCheckState(newValue) {
    setChecked(newValue);
  }

  function setSelectedItemsState(isSelected, data, data2) {
    if (isSelected) {
      if (length === data.length) {
        setChecked(true);
      }
      setSelectedItems(data);
    } else {
      setChecked(false);
      setSelectedItems(data2);
    }
  }

  return {
    getCheckState,
    getSelectedItems,
    setCheckState,
    setSelectedItemsState,
  };
}
