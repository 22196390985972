import { menuContext } from "../../context";
import { useContext } from "react";
import Menu from "../Menu";
import Submenu from "../Submenu";
import { sidebarContext } from "../../App";

export default function Sidebar() {
  const { menu } = useContext(menuContext);
  const { showSidebar } = useContext(sidebarContext);

  return (
    <div className={"sidebar " + (showSidebar ? "left-0" : "-left-[312px]")}>
      <ul>
        <Menu
          submenuItem={
            <>
              <Submenu
                text={"信件管理"}
                href={"/mail/sended"}
                isActive={menu.text[1] === "信件管理"}
              />
              <Submenu
                text={"檔案存放區"}
                href={"/file/template/text"}
                isActive={menu.text[1] === "檔案存放區"}
              />
              <Submenu
                text={"名單管理"}
                href={"/receiverlist/grouplist"}
                isActive={menu.text[1] === "名單管理"}
              />
              <Submenu
                text={"數據統計"}
                href={"/statistics"}
                isActive={menu.text[1] === "數據統計"}
              />
            </>
          }
          text={"電子報"}
          href={"/"}
          isActive={menu.text[0] === "電子報"}
        />
      </ul>
    </div>
  );
}
