import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { userRequest } from "../../api";
import { PrimaryButton, SecondaryButton, ThirdButton } from "../Button";
import { useBackdropClick, useOpen, useShowPopup } from "../../hooks";
import CustomAlertDialog from "../CustomAlertDialog";

export default function CustomDialog({
  className,
  disabled,
  buttonTitle,
  icon,
  confirmFuncion,
  inputProps,
  copyFuncion,
  dialogTitle,
  dialogContent,
  navigatePath,
  showChildrenPopup,
  reload,
}) {
  const { getOpen, setOpen } = useOpen();

  const [value, setValue] = useState(
    inputProps
      ? Object.values(inputProps.props)[
          Object.values(inputProps.props).length - 1
        ]
      : ""
  );
  const [loading, setLoading] = useState(false);

  const { setFalse, setSuccess, getShowPopup, setIsShow } = useShowPopup();
  const { backdropClick } = useBackdropClick();

  const location = useLocation();
  const navigate = useNavigate();

  const handleBackdropClick = (e) => backdropClick(e, setLoading(false));

  useEffect(() => {
    setValue(
      inputProps
        ? Object.values(inputProps.props)[
            Object.values(inputProps.props).length - 1
          ]
        : ""
    );
  }, [inputProps]);

  const handleClickOpen = () => {
    copyFuncion
      ? copyFuncion().then((response) => {
          if (response.success === false) {
            dialogTitle = "複製失敗";
            dialogContent = response.errorMesg;
          }
          setOpen(true);
        })
      : setOpen(true);
  };

  const handleClose = (e) => {
    if (e.target.innerText === "取消") {
      setOpen(false);
    } else if (e.target.innerText === "確認") {
      setLoading(true);
      if (confirmFuncion) {
        confirmFuncion().then((response) => {
          if (response.success === false) {
            setFalse(true, response.errorMesg);
          } else {
            if (
              response.data.failed_email_array &&
              response.data.failed_email_array.length > 0
            ) {
              setFalse(
                true,
                <>
                  <p className="text-center">
                    正確名單已暫存成功或寄出，<br></br>失敗名單如下：
                  </p>
                  <ul>
                    {response.data.failed_email_array.map((item) => (
                      <li className="text-center">{item}</li>
                    ))}
                  </ul>
                </>,
                navigatePath
              );
            } else {
              if (navigatePath) {
                navigate(navigatePath);
              } else {
                reload && window.location.reload();
              }
            }
          }
        });
        setOpen(false);
      } else if (inputProps) {
        const keys = Object.keys(inputProps.props);
        const values = Object.values(inputProps.props);
        let data = {};

        keys.forEach((key, index) => {
          if (index === keys.length - 1) {
            data[key] = value;
          } else {
            data[key] = values[index];
          }
        });
        if (inputProps.method === "post") {
          userRequest
            .post(inputProps.url, JSON.stringify(data))
            .then((response) => {
              if (response.data.success === false) {
                setFalse(true, response.data.errorMesg);
                setOpen(false);
              } else {
                setOpen(false);
                if (showChildrenPopup) {
                  setSuccess(true, "儲存成功", "可至檔案存放區查詢");
                }
                reload && window.location.reload();
              }
            });
        } else if (inputProps.method === "patch") {
          userRequest
            .patch(inputProps.url, JSON.stringify(data))
            .then((response) => {
              if (response.data.success === false) {
                setFalse(true, response.data.errorMesg);
                setOpen(false);
              } else {
                setOpen(false);
                reload && window.location.reload();
              }
            });
        }
      } else {
        setOpen(false);
        navigate(location.pathname, {
          state: location.state ? !location.state : getOpen(),
        });
      }
      setLoading(false);
    } else {
      setOpen(false);
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <>
      {buttonTitle ? (
        <SecondaryButton
          text={buttonTitle}
          classname={className}
          disabled={disabled}
          onClick={handleClickOpen}
        />
      ) : (
        <IconButton
          sx={{
            color: "var(--color-neutral-900)",
            ":hover": { bgcolor: "transparent" },
          }}
          onClick={handleClickOpen}
        >
          {icon}
        </IconButton>
      )}
      {loading ? (
        <Backdrop
          sx={{ zIndex: 999 }}
          open={loading}
          onClick={handleBackdropClick}
        >
          <CircularProgress />
        </Backdrop>
      ) : (
        <Dialog
          sx={{ "& .MuiDialog-paper": { borderRadius: 5, p: 3 } }}
          open={getOpen()}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            sx={{
              fontWeight: "normal",
              p: 0,
              fontSize: 32,
              lineHeight: 1.5,
              textAlign: dialogTitle.includes("刪除") && "center",
            }}
            id="alert-dialog-title"
          >
            {dialogTitle}
          </DialogTitle>
          <DialogContent sx={{ p: 0 }}>
            {dialogContent && (
              <DialogContentText
                id="alert-dialog-description"
                sx={{ textAlign: "center", mt: "29px" }}
              >
                {dialogContent}
              </DialogContentText>
            )}
            {inputProps && (
              <TextField
                sx={{
                  mt: 3,
                  "& .MuiOutlinedInput-input": { px: 2, py: "10.5px" },
                }}
                autoFocus
                margin="none"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
                value={value}
              />
            )}
          </DialogContent>
          <DialogActions sx={{ p: 0, mt: "29px", justifyContent: "center" }}>
            {!copyFuncion && (
              <ThirdButton text={"取消"} onClick={handleClose} />
            )}
            <PrimaryButton
              text={"確認"}
              disabled={loading}
              onClick={handleClose}
              margin={copyFuncion && "0px"}
            />
          </DialogActions>
        </Dialog>
      )}
      {getShowPopup().show && (
        <CustomAlertDialog
          show={getShowPopup().show}
          message={getShowPopup().message}
          confirmFunction={() => {
            setIsShow(false);
            if (getShowPopup().path) {
              navigate(getShowPopup().path);
            }
          }}
        />
      )}
    </>
  );
}
