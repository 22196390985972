import { useContext, useEffect, useState } from "react";
import CustomModal from "../../../components/CustomModal";
import { createCustomizedMail } from "../../../api";
import { VisuallyHiddenInput } from "../../../utils";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { customizedMailContext, receiverContext } from "./MailContent";
import { useOpen } from "../../../hooks";

export default function CustomizedMailPop() {
  const { customizedList, selectedCustomizedMail, handleSetCustomizedMail } =
    useContext(customizedMailContext);
  const { selectedReceivers } = useContext(receiverContext);
  const [uploadCustomizedMail, setUploadCustomizedMail] = useState();
  const [selected, setSelected] = useState(selectedCustomizedMail);
  const { getOpen, setOpen } = useOpen();
  const [dialog, setDialog] = useState({ title: "", content: "", actions: [] });

  useEffect(() => {
    setSelected(selectedCustomizedMail);
  }, [selectedCustomizedMail]);

  /**
   * 上傳個人化信件
   */
  const handleUpload = async (e) => {
    let customizedmailFile = e.target;
    let formdata = new FormData();

    if (customizedmailFile.files[0] !== undefined) {
      formdata.append("name", customizedmailFile.files[0].name.split(".")[0]);
      formdata.append(
        "file",
        customizedmailFile.files[0],
        customizedmailFile.files[0].name
      );
      createCustomizedMail(formdata).then((response) => {
        if (response.success === false) {
          const failed_emails = response.errorMesg
            .split("以下email格式不符合:")[1]
            .split(",");
          setDialog({
            title: "上傳失敗",
            content: (
              <>
                <p className="p3 text-[var(--color-neutral-600)]">
                  請修正格式後，重新匯入名單:
                </p>
                <ul className="p3 text-[var(--color-neutral-600)]">
                  {failed_emails.map((item) => (
                    <li className="text-center">{item}</li>
                  ))}
                </ul>
              </>
            ),
            actions: [{ name: "確認", actions: handleClose }],
          });
          setOpen(true);
        } else {
          setUploadCustomizedMail({
            id: response.data.customized_data_id,
            name: customizedmailFile.files[0].name,
            headers: response.data.headers,
          });
          setSelected({
            id: response.data.customized_data_id,
            headers: response.data.headers,
          });
        }
      });
    }
  };

  /**
   * 刪除上傳的檔案
   */
  const removeUpload = () => {
    setUploadCustomizedMail();
    if (uploadCustomizedMail.id === parseInt(selected.id)) {
      setSelected({});
    }
  };

  /**
   * 選擇個人化信件
   */
  const handleClick = (e) => {
    setSelected({ id: e.target.name, headers: e.target.value.split(",") });
  };

  const handleUnselected = () => {
    setSelected();
  };

  const handleCancel = () => {
    setSelected(selectedCustomizedMail);
    setUploadCustomizedMail();
  };

  /**
   * 假如收件者有值，並且選擇了個人化信件，跳出popup提醒使用者
   */
  const handleConfirm = () => {
    if (selectedReceivers && selectedReceivers.length > 0) {
      setDialog({
        title: "收件人將清空",
        content: (
          <p className="p3 text-[var(--color-neutral-600)] text-center">
            將覆蓋成檔案裡的收件人
          </p>
        ),
        actions: [
          { name: "取消", actions: handleClose },
          { name: "確認", actions: handleDoubleConfirm },
        ],
      });
      setOpen(true);
    } else {
      handleSetCustomizedMail(selected);
    }
  };

  /**
   * 提醒popup確認動作
   */
  const handleDoubleConfirm = () => {
    handleSetCustomizedMail(selected);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomModal
        className={"btn svg-btn third-btn !ml-6"}
        maxWidth={392}
        buttonTitle={
          <>
            <i className="ri-file-excel-2-line"></i>個人化信件
          </>
        }
        modalTitle={"選擇個人化信件"}
        cancelFunction={handleCancel}
        confirmFunction={handleConfirm}
      >
        {!uploadCustomizedMail && (
          <Button
            component="label"
            variant="contained"
            sx={{
              fontSize: 16,
              bgcolor: "transparent",
              textDecoration: "underline",
              color: "var(--color-primary-1)",
              fontWeight: "normal",
              boxShadow: "none",
              letterSpacing: 0,
              px: 2,
              py: 1.25,
              ":hover": {
                bgcolor: "transparent",
                textDecoration: "underline",
                boxShadow: "none",
              },
            }}
          >
            上傳檔案
            <VisuallyHiddenInput type="file" onChange={handleUpload} />
          </Button>
        )}
        <ul>
          {uploadCustomizedMail && (
            <li
              className={
                "w-full flex items-center hover:bg-[var(--color-neutral-100)] " +
                (selected && parseInt(selected.id) === 0
                  ? "bg-[var(--color-neutral-100)] text-[var(--color-primary-1)]"
                  : "")
              }
            >
              <button
                className="w-full truncate text-left px-4 py-[10px]"
                name={uploadCustomizedMail.id}
                value={uploadCustomizedMail.headers}
                onClick={handleClick}
              >
                {uploadCustomizedMail.name}
              </button>
              <button onClick={removeUpload}>
                <i className="ri-close-line px-4"></i>
              </button>
            </li>
          )}
          {customizedList.map((result) => {
            return (
              <li
                key={result.customized_data_id}
                className={
                  "w-full hover:bg-[var(--color-neutral-100)] flex justify-between items-center " +
                  (selected &&
                  parseInt(selected.id) === result.customized_data_id
                    ? "bg-[var(--color-primary-5)] text-[var(--color-primary-1)]"
                    : "")
                }
              >
                <button
                  className="w-full truncate text-left pl-4 py-[10px]"
                  name={result.customized_data_id}
                  value={result.headers}
                  onClick={handleClick}
                >
                  {result.customized_data_name}
                </button>
                {selected &&
                parseInt(selected.id) === result.customized_data_id ? (
                  <button
                    name={result.customized_data_id}
                    onClick={handleUnselected}
                    className="px-4 py-[10px]"
                  >
                    <i className="ri-close-line"></i>
                  </button>
                ) : (
                  <></>
                )}
              </li>
            );
          })}
        </ul>
      </CustomModal>
      <Dialog
        open={getOpen()}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            p: 3,
            borderRadius: 5,
          },
        }}
      >
        <DialogTitle
          component={"h2"}
          sx={{
            fontSize: 32,
            fontWeight: "normal",
            p: 0,
            mb: "29px",
            textAlign: "center",
          }}
        >
          {dialog.title}
        </DialogTitle>
        <DialogContent sx={{ p: 0, mb: "29px" }}>
          {dialog.content}
        </DialogContent>
        <DialogActions
          sx={
            dialog.actions.length > 1
              ? { p: 0 }
              : { p: 0, justifyContent: "center" }
          }
        >
          {dialog.actions.map((item) => (
            <Button
              className={item.name === "取消" ? "btn secondary-btn" : ""}
              sx={
                item.name === "確認"
                  ? {
                      color: "var(--color-neutral-50)",
                      bgcolor: "var(--color-primary-1)",
                      px: 6,
                      py: 1.25,
                      fontSize: 16,
                      lineHeight: 1.5,
                      fontWeight: "normal",
                      ":hover": { bgcolor: "var(--color-primary-2)" },
                    }
                  : {
                      color: "var(--color-primary-1)",
                      bgcolor: "transparent",
                      fontSize: 16,
                      fontWeight: "normal",
                      border: 1,
                      ":hover": { bgcolor: "var(--color-primary-5)" },
                    }
              }
              onClick={item.actions}
            >
              {item.name}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </>
  );
}
