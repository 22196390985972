import { styled } from "@mui/material/styles";

// 將使用者資訊儲存到 localstorage
export const setLocalStorage = (name, email, password, token, mode) => {
  localStorage.setItem("name", name);
  localStorage.setItem("email", email);
  localStorage.setItem("password", password);
  localStorage.setItem("token", token);
  localStorage.setItem("mode", mode);
};

// 儲存群組排序順序
export const setTeamPosition = (position) => {
  localStorage.setItem("team_position", position);
};

/**
 *
 * @param {*} request
 * @returns string
 */
export const getLocalStorage = (request) => {
  if (
    localStorage.getItem(request) === undefined ||
    localStorage.getItem(request) === null
  ) {
    return "";
  }
  return localStorage.getItem(request);
};

/**
 *
 * @param {*} dateString
 * @returns date
 */
export const FormatDate = (dateString) => {
  const date = dateString.split(" ")[0].replaceAll("-", "/");
  const time = dateString.split(" ")[1].substring(0, 5);
  return date + " " + time;
};

export const filterResult = (value, sortBy) => {
  return Object.values(
    Object.fromEntries(
      Object.entries(value).filter((item) => {
        return item[0] === sortBy;
      })
    )
  ).toString();
};

/**
 * 上傳檔案的input component
 */
export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

/**
 * 讀取檔案內容（模板）
 * @param {*} file
 * @returns string
 */
export const readFileContent = (file) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = (event) => resolve(event.target.result);
    reader.onerror = (error) => reject(error);
    reader.readAsText(file);
  });
};

/**
 * 將資料按規則排序
 * @param {number} clicked
 * @param {Array} items
 * @param {string} sortBy
 * @param {Function} elseFunc
 * @returns {Array}
 */
export function sortFunc(clicked, items, sortBy, elseFunc) {
  const reg = /[a-zA-Z0-9]/;
  if (clicked === 1) {
    return items.sort((a, b) => {
      let x = filterResult(a, sortBy);
      let y = filterResult(b, sortBy);
      if (reg.test(x) || reg.test(y)) {
        if (x > y) {
          return -1;
        } else if (x < y) {
          return 1;
        } else {
          return 0;
        }
      } else {
        return y.localeCompare(x);
      }
    });
  } else if (clicked === 2) {
    return items.sort((a, b) => {
      let x = filterResult(a, sortBy);
      let y = filterResult(b, sortBy);
      if (reg.test(x) || reg.test(y)) {
        if (x > y) {
          return 1;
        } else if (x < y) {
          return -1;
        } else {
          return 0;
        }
      } else {
        return x.localeCompare(y);
      }
    });
  } else {
    elseFunc();
  }
}
