import { Breadcrumbs, Link } from "@mui/material";
import { useContext } from "react";
import { menuContext } from "../../context";

export default function Bread() {
  const { menu } = useContext(menuContext);

  return (
    <div role="presentation" className="ml-10">
      <Breadcrumbs aria-label="breadcrumb">
        {menu.text.map((element, index) => {
          return (
            <Link
              key={index}
              underline="hover"
              color={
                index < menu.text.length - 1
                  ? "var(--color-neutral-400)"
                  : "var(--color-primary-1)"
              }
              href={menu.href[index]}
            >
              {element}
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}
