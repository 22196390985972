import { useLocation, useParams } from "react-router-dom";
import PersonItem from "../../PersonItem";
import { useContext, useEffect, useState } from "react";
import {
  baseUrl,
  deleteReceivers,
  exportReceiver,
  getParticularTeamReceiver,
  updateReceiverToOtherGroup,
} from "../../../../api";
import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
} from "@mui/material";
import { sortFunc } from "../../../../utils";
import {
  selectedContext,
  openContext,
  menuContext,
  teamContext,
} from "../../../../context";
import CustomDialog from "../../../../components/CustomDialog";
import { ReceiverModal } from "../../../../components/ReceiverModal";
import { FixedSizeList } from "react-window";
import CustomModal from "../../../../components/CustomModal";
import { CheckAllButton, SortButton } from "../../../../components/Button";
import {
  useBackdropClick,
  useCheckedAll,
  useOpen,
  useSearch,
  useShowPopup,
  useSort,
} from "../../../../hooks";
import CustomAlertDialog from "../../../../components/CustomAlertDialog";
import SearchInput from "../../../../components/SearchInput";

export default function ReceiverInnerPage() {
  const { setMenu } = useContext(menuContext);
  const { teamList } = useContext(teamContext);

  const [data, setData] = useState([]);
  const [receiverList, setReceiverList] = useState([]);
  const [title, setTitle] = useState("");
  const [selectedGroupId, setSelectedGroupId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showSnackerbar, setShowSnackerbar] = useState(false);

  const param = useParams();
  const location = useLocation();

  const { setFalse, getShowPopup, setIsShow } = useShowPopup();
  const { getSearchValue, setSearchValue } = useSearch();
  const { getOpen, setOpen } = useOpen();
  const open = getOpen();
  const { getClicked, updateClicked, sortByPosition } = useSort(5);
  const { backdropClick } = useBackdropClick();
  const { getCheckState, getSelectedItems, setSelectedItemsState } =
    useCheckedAll(receiverList.length);
  const selectedItems = getSelectedItems();

  const handleBackdropClick = (e) => backdropClick(e, setIsLoading(false));

  useEffect(() => {
    setMenu({
      text: ["電子報", "名單管理", title],
      href: ["/", "/receiverlist/grouplist", location.pathname],
    });
  }, [setMenu, location.pathname, title]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);

    if (teamList.length > 0) {
      setTitle(
        teamList.filter((item) => item.team_id === parseInt(param.id))[0].name
      );
    }
    getParticularTeamReceiver(param.id).then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        if (response.data.length > 0) {
          let list = [];
          response.data.map((result) => {
            list = list.concat(result);
            return list;
          });
          setData(response.data);
          setReceiverList(list);
        }
        setIsLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param.id, teamList]);

  /**
   * 匯出群組
   */
  const handleExport = () => {
    exportReceiver(parseInt(param.id)).then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        let a = document.createElement("a");
        a.href = baseUrl + response.data.url;
        a.download = "result";
        a.click();
      }
    });
  };

  /**
   * 點擊排序按鈕
   */
  const handleClick = (e) => {
    let position = parseInt(e.target.value);
    const updateClick = updateClicked(position);
    sortByPosition(updateClick);
    sortFunc(updateClick[position], receiverList, e.target.name, () => {
      let list = [];
      data.map((result) => {
        list = list.concat(result);
        return list;
      });
      return setReceiverList(list);
    });
  };

  /**
   * 全選
   */
  function checkAllHandler(e) {
    let isSelected = e.target.checked;
    const postIds = receiverList.map((item) => {
      return item.receiver_id;
    });
    setSelectedItemsState(isSelected, postIds, []);
  }

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setReceiverList(
      data.filter(
        (item) =>
          item.receiver_name.includes(value) ||
          item.email.includes(value) ||
          (item.status === "normal"
            ? "正常"
            : item.status === "invalid"
            ? "無效信箱"
            : "被封鎖"
          ).includes(value) ||
          (item.unit !== null && item.unit.includes(value)) ||
          (item.phone !== null && item.phone.includes(value)) ||
          (item.remark !== null && item.remark.includes(value))
      )
    );
  };

  /**
   * 開啟編輯聯絡人視窗
   */
  const handleOpen = () => {
    setOpen(true);
  };

  /**
   * 點擊加入其他群組內的選項
   */
  const handleGroupClick = (e) => {
    const id = teamList.filter((item) => item.name === e.target.innerText);
    if (id.length > 0) {
      setSelectedGroupId(id[0].team_id);
    } else {
      setSelectedGroupId();
    }
  };

  /**
   * 加入其他群組確認按鈕執行動作
   */
  const handleConfirm = () => {
    updateReceiverToOtherGroup([selectedGroupId], getSelectedItems()).then(
      (response) => {
        if (response.success === false) {
        } else {
          setShowSnackerbar(true);
        }
      }
    );
  };

  const handleCancel = () => {
    setSelectedGroupId(selectedGroupId);
  };

  /**
   * 關閉跳出訊息
   */
  const handleClose = () => {
    setShowSnackerbar(false);
    window.location.reload();
  };

  const item = ({ index, style, data }) => (
    <PersonItem key={index} style={style} form={data[index]} />
  );

  return (
    <>
      {isLoading ? (
        <Backdrop open={isLoading} onClick={handleBackdropClick}>
          <CircularProgress />
        </Backdrop>
      ) : (
        <selectedContext.Provider
          value={{ selectedItems, setSelectedItemsState }}
        >
          <openContext.Provider value={{ open, setOpen }}>
            <div className="p-9 bg-white">
              <h2>
                {title}({data.length})
              </h2>
              <div className="flex justify-between my-5">
                <div className="flex gap-6 items-center">
                  <CheckAllButton
                    data={receiverList}
                    state={getCheckState()}
                    onChange={checkAllHandler}
                  />
                  <CustomDialog
                    className="delete-btn"
                    disabled={getSelectedItems().length === 0 ? true : false}
                    buttonTitle={"移出群組"}
                    confirmFuncion={() =>
                      deleteReceivers(parseInt(param.id), selectedItems)
                    }
                    dialogTitle={"確定要移出群組嗎？"}
                    reload={true}
                  />
                  <CustomModal
                    className="move-btn"
                    disabled={getSelectedItems().length === 0 ? true : false}
                    buttonTitle={"加入其他群組"}
                    modalTitle={"加入其他群組"}
                    cancelFunction={handleCancel}
                    confirmFunction={handleConfirm}
                  >
                    <ul className="mt-6">
                      {teamList
                        .filter((item) => item.team_id !== parseInt(param.id))
                        .map((item, index) => {
                          return (
                            <li
                              key={index}
                              className={
                                "hover:bg-[var(--color-primary-4)] hover:text-[var(--color-primary-1)] " +
                                (selectedGroupId === item.team_id
                                  ? "bg-[var(--color-neutral-100)]"
                                  : "")
                              }
                            >
                              <button
                                className="w-full px-4 py-[10px] flex items-center"
                                onClick={handleGroupClick}
                              >
                                <i className="ri-folder-fill leading-none text-[var(--color-neutral-400)] mr-[10px]"></i>
                                <span
                                  className={
                                    "p3 " +
                                    (selectedGroupId === item.team_id
                                      ? "text-[var(--color-primary-1)]"
                                      : "text-[var(--color-neutral-900)]")
                                  }
                                >
                                  {item.name}
                                </span>
                              </button>
                            </li>
                          );
                        })}
                    </ul>
                  </CustomModal>
                  <span>選取: {getSelectedItems().length}筆</span>
                </div>
                <div className="flex gap-6 items-center">
                  <Button
                    className="btn svg-btn third-btn !pl-[10px] !pr-4 !py-[6px] h-9"
                    disabled={data.length === 0}
                    sx={{
                      bgcolor: "var(--color-primary-4)",
                      color: "var(--color-neutral-900)",
                      fontWeight: "normal",
                      fontSize: 16,
                      ":hover": { bgcolor: "var(--color-primary-3)" },
                    }}
                    onClick={handleExport}
                  >
                    <i className="ri-file-excel-2-line text-2xl"></i>
                    匯出名單
                  </Button>
                  <Button
                    className="btn svg-btn !pl-[10px] !pr-4 !py-[6px] h-9"
                    sx={{
                      bgcolor: "var(--color-secondary-4)",
                      color: "var(--color-neutral-900)",
                      fontWeight: "normal",
                      fontSize: 16,
                      ":hover": { bgcolor: "var(--color-secondary-3)" },
                    }}
                    onClick={handleOpen}
                  >
                    <i className="ri-add-line text-2xl"></i>新增聯絡人
                  </Button>
                  <ReceiverModal />
                  <SearchInput
                    searchValue={getSearchValue()}
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div className="flex items-center py-4 px-10 bg-[var(--color-neutral-100)]">
                <span className="w-[10%] font-bold mr-1">選取</span>
                <span className="w-[15%] font-bold mr-1">
                  <label className="flex items-center gap-[2px] m-0 cursor-pointer">
                    <input
                      type="button"
                      name="unit"
                      value={0}
                      className="absolute top-0 invisible"
                      onClick={handleClick}
                    />
                    單位
                    <SortButton props={getClicked(0)} />
                  </label>
                </span>
                <span className="w-[20%] font-bold mr-1">
                  <label className="flex items-center gap-[2px] m-0 cursor-pointer">
                    <input
                      type="button"
                      name="receiver_name"
                      value={1}
                      className="absolute top-0 invisible"
                      onClick={handleClick}
                    />
                    聯絡人
                    <SortButton props={getClicked(1)} />
                  </label>
                </span>
                <span className="w-[30%] font-bold mr-1">
                  <label className="flex items-center gap-[2px] m-0 cursor-pointer">
                    <input
                      type="button"
                      name="email"
                      value={2}
                      className="absolute top-0 invisible"
                      onClick={handleClick}
                    />
                    Email
                    <SortButton props={getClicked(2)} />
                  </label>
                </span>
                <span className="w-[15%] font-bold mr-1">
                  <label className="flex items-center gap-[2px] m-0 cursor-pointer">
                    <input
                      type="button"
                      name="phone"
                      value={3}
                      className="absolute top-0 invisible"
                      onClick={handleClick}
                    />
                    電話
                    <SortButton props={getClicked(3)} />
                  </label>
                </span>
                <span className="w-[10%] font-bold">
                  <label className="flex items-center gap-[2px] m-0 cursor-pointer">
                    <input
                      type="button"
                      name="status"
                      value={4}
                      className="absolute top-0 invisible"
                      onClick={handleClick}
                    />
                    狀態
                    <SortButton props={getClicked(4)} />
                  </label>
                </span>
              </div>
              <FixedSizeList
                height={
                  window.innerHeight > receiverList.length * 68
                    ? receiverList.length * 68
                    : window.innerHeight - 152
                }
                itemCount={receiverList.length}
                itemSize={68}
                width={"100%"}
                itemData={receiverList}
              >
                {item}
              </FixedSizeList>
            </div>
            <Snackbar
              open={showSnackerbar}
              autoHideDuration={1000}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              onClose={handleClose}
              message="已加入其他群組"
              sx={{
                "& .MuiSnackbarContent-root ": {
                  bgcolor: "#3D3D3D",
                  borderRadius: 5,
                  minWidth: "auto",
                  fontSize: 18,
                  px: 2,
                  py: 1,
                  opacity: "0.8!important",
                  "& .MuiSnackbarContent-message": { p: 0 },
                  "& .MuiIconButton-root": { p: 0 },
                  "& .MuiSnackbarContent-action": { pl: 1.25 },
                },
              }}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleClose}
                >
                  <i className="ri-close-line"></i>
                </IconButton>
              }
            />
            {getShowPopup().show && (
              <CustomAlertDialog
                show={getShowPopup().show}
                message={getShowPopup().message}
                confirmFunction={() => setIsShow(false)}
              />
            )}
          </openContext.Provider>
        </selectedContext.Provider>
      )}
    </>
  );
}
