import { useState } from "react";
import { Link } from "react-router-dom";

export default function Menu({ submenuItem, text, href, isActive }) {
  const [showSubmenu, setShowSubmenu] = useState(true);

  const handleClick = () => setShowSubmenu((prev) => !prev);

  return (
    <li>
      <div className={"menu" + (isActive ? " activeMenu" : "")}>
        <Link className="flex-1" to={href}>
          <div className="flex items-center">
            <i className="ri-mail-line mr-2"></i>
            <span>{text}</span>
          </div>
        </Link>
        <button onClick={handleClick}>
          <i className="ri-arrow-down-s-line"></i>
        </button>
      </div>
      <ul className={!showSubmenu ? "hidden" : ""}>{submenuItem}</ul>
    </li>
  );
}
