/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { createAttachment, getAttachmentList } from "../../../api";
import { attachmentContext } from "./MailContent";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { VisuallyHiddenInput } from "../../../utils";
import CustomModal from "../../../components/CustomModal";
import { useShowPopup } from "../../../hooks";
import CustomAlertDialog from "../../../components/CustomAlertDialog";

export default function AttachmentPop() {
  const { selectedAttachments, handleSetAttachment } =
    useContext(attachmentContext);
  const [attachmentList, setAttachmentList] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [selected, setSelected] = useState(selectedAttachments);
  const { setFalse, getShowPopup, setIsShow } = useShowPopup();

  useEffect(() => {
    setSelected(selectedAttachments);
    getAttachmentList().then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        setAttachmentList(response.data);
      }
    });
  }, []);

  const handleChange = (e) => {
    let isSelected = e.target.checked;
    let id = e.target.value;
    let name = e.target.name;

    if (isSelected) {
      setSelected((prev) => [...prev, { id: parseInt(id), name: name }]);
    } else {
      setSelected((prev) => prev.filter((item) => item.id !== parseInt(id)));
    }
  };

  /**
   * 上傳附件（最多可上傳三個）
   */
  const handleUpload = (e) => {
    let attachmentFile = e.target;
    if (attachmentFile.files[0]) {
      const type = attachmentFile.files[0].type.split("/")[1];
      let formdata = new FormData();
      formdata.append(
        "name",
        attachmentFile.files[0].name.split("." + type)[0]
      );
      formdata.append(
        "file",
        attachmentFile.files[0],
        attachmentFile.files[0].name
      );
      setUploadFiles((prev) => [
        ...prev,
        {
          id:
            (attachmentList[0] ? attachmentList[0].attachment_id : 0) +
            uploadFiles.length +
            1,
          name: attachmentFile.files[0].name,
          file: formdata,
        },
      ]);
      setSelected((prev) => [
        ...prev,
        {
          id:
            (attachmentList[0] ? attachmentList[0].attachment_id : 0) +
            uploadFiles.length +
            1,
          name: attachmentFile.files[0].name,
        },
      ]);
    }
  };

  /**
   * 刪除上傳的附件
   */
  const removeUpload = (position) => {
    setSelected((prev) =>
      prev.filter((item) => item.id !== uploadFiles[position].id)
    );
    setUploadFiles((prev) =>
      prev.filter((item) => item.id !== uploadFiles[position].id)
    );
  };

  const handleCancel = () => {
    setSelected(selectedAttachments);
    setUploadFiles([]);
  };

  /**
   * 確認按鈕執行動作
   */
  const handleConfirm = () => {
    uploadFiles.forEach((element, index) => {
      createAttachment(element.file).then((response) => {
        if (response.success === false) {
          if (response.tooLarge === true) {
            setFalse(true, "檔案大小超過限制");
          } else {
            setFalse(true, response.errorMesg);
          }
          setSelected((prev) => prev.filter((item) => item.id !== element.id));
        } else {
          removeUpload(index);
          getAttachmentList().then((response) => {
            if (response.success === false) {
              setFalse(true, response.errorMesg);
            } else {
              setAttachmentList(response.data);
              setSelected((prev) => [
                ...prev,
                {
                  id: response.data[0].attachment_id,
                  name:
                    response.data[0].attachment_name +
                    "." +
                    response.data[0].ext,
                },
              ]);
            }
          });
        }
      });
    });
    handleSetAttachment(selected);
  };

  return (
    <>
      <CustomModal
        className="btn svg-btn third-btn mr-4"
        maxWidth={392}
        buttonTitle={
          <>
            <i className="ri-attachment-line"></i>附件
          </>
        }
        modalTitle={"選擇附件"}
        cancelFunction={handleCancel}
        confirmFunction={handleConfirm}
      >
        <p className="text-[var(--color-neutral-600)] mb-6">最多選擇三個附件</p>
        {selected.length < 3 && uploadFiles.length < 3 && (
          <Button
            component="label"
            variant="contained"
            sx={{
              fontSize: 16,
              bgcolor: "transparent",
              textDecoration: "underline",
              color: "var(--color-primary-1)",
              fontWeight: "normal",
              boxShadow: "none",
              letterSpacing: 0,
              mb: 1,
              ":hover": {
                bgcolor: "transparent",
                textDecoration: "underline",
                boxShadow: "none",
              },
            }}
          >
            上傳檔案
            <VisuallyHiddenInput
              id="attachment-file"
              type="file"
              onChange={handleUpload}
            />
          </Button>
        )}
        <ul className="flex gap-2 flex-wrap">
          {uploadFiles.map((item, index) => {
            return (
              <li
                key={index}
                className="w-full text-[var(--color-primary-1)] hover:bg-[var(--color-neutral-100)]"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ p: 0, mr: 1 }}
                      name={uploadFiles[index].name}
                      value={
                        (attachmentList[0]
                          ? attachmentList[0].attachment_id
                          : 0) +
                        index +
                        1
                      }
                      checked={
                        selected.filter(
                          (item) =>
                            item.id ===
                            (attachmentList[0]
                              ? attachmentList[0].attachment_id
                              : 0) +
                              index +
                              1
                        ).length > 0
                      }
                      disabled={
                        selected.length >= 3
                          ? selected.filter(
                              (item) =>
                                item.id ===
                                (attachmentList[0]
                                  ? attachmentList[0].attachment_id
                                  : 0) +
                                  index +
                                  1
                            ).length > 0
                            ? false
                            : true
                          : false
                      }
                      onChange={handleChange}
                    />
                  }
                  label={
                    <>
                      {item.name}
                      <i
                        className="ri-close-line"
                        onClick={() => removeUpload(index)}
                      ></i>
                    </>
                  }
                  sx={{
                    m: 0,
                    p: 2,
                    width: "100%",
                    "& .MuiFormControlLabel-label": {
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexGrow: 1,
                    },
                  }}
                />
              </li>
            );
          })}
          {attachmentList.map((result, index) => {
            return (
              <li
                key={index}
                className={
                  "w-full hover:bg-[var(--color-neutral-100)] " +
                  (selected.filter((item) => item.id === result.attachment_id)
                    .length > 0
                    ? "text-[var(--color-primary-1)]"
                    : "")
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ p: 0, mr: 1 }}
                      name={result.attachment_name + "." + result.ext}
                      value={result.attachment_id}
                      checked={
                        selected.filter(
                          (item) => item.id === result.attachment_id
                        ).length > 0
                      }
                      disabled={
                        selected.length >= 3
                          ? selected.filter(
                              (item) => item.id === result.attachment_id
                            ).length > 0
                            ? false
                            : true
                          : false
                      }
                      onChange={handleChange}
                    />
                  }
                  label={result.attachment_name + "." + result.ext}
                  sx={{ m: 0, width: "100%", p: 2 }}
                />
              </li>
            );
          })}
        </ul>
      </CustomModal>
      {getShowPopup().show && (
        <CustomAlertDialog
          show={getShowPopup().show}
          message={getShowPopup().message}
          confirmFunction={() => setIsShow(false)}
        />
      )}
    </>
  );
}
