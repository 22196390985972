import { useContext, useEffect, useState } from "react";
import PageWrap from "../../components/Pagewrap";
import { menuContext } from "../../context";
import {
  getMailCountLastYear,
  getMaxOpentime,
  getRangeOpentime,
  getStatisticsPersonCount,
} from "../../api";
import { getLocalStorage } from "../../utils";
import { Chart } from "react-chartjs-2";
import {
  CategoryScale,
  LinearScale,
  LineElement,
  LineController,
  BarElement,
  BarController,
  Chart as ChartJS,
  registerables,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { useShowPopup } from "../../hooks";
import CustomAlertDialog from "../../components/CustomAlertDialog";

ChartJS.register(
  ...registerables,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  LineElement,
  LineController,
  zoomPlugin
);

/**
 * 共用區塊元件
 */
function Block({ children }) {
  return (
    <div className="bg-[var(--color-neutral-50)] w-[calc(50%-12px)] p-5">
      {children}
    </div>
  );
}

export default function Statistics() {
  const datePerWeek = [
    "星期日",
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六",
  ];
  const [personCount, setPersonCount] = useState(0);
  const [date, setDate] = useState({
    from: new Date().toISOString(),
    to: new Date().toISOString(),
  });
  const [rangeOpentime, setRangeOpentime] = useState();
  const [ym, setYM] = useState({});
  const [maxOpentime, setMaxOpentime] = useState();
  const [mailCount, setMailCount] = useState();
  const { setMenu } = useContext(menuContext);
  const { setFalse, getShowPopup, setIsShow } = useShowPopup();

  //初始數據
  useEffect(() => {
    setMenu({ text: ["電子報", "數據統計"], href: ["/", "/statistics"] });

    let date = new Date();
    let day = date.getDate().toString().padStart(2, "0");
    let lastMon = "";
    if (date.getMonth() === 0) {
      lastMon = date.getFullYear() - 1 + "-" + 12 + "-" + day;
    } else {
      lastMon =
        date.getFullYear() +
        "-" +
        date.getMonth().toString().padStart(2, "0") +
        "-" +
        day;
    }
    let thisMon =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      day;

    setDate({
      from: lastMon,
      to: thisMon,
    });

    setYM({ year: date.getFullYear(), month: date.getMonth() + 1 });

    getStatisticsPersonCount().then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        setPersonCount(response.data[0].count);
      }
    });

    getMailCountLastYear(getLocalStorage("mode")).then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        setMailCount(response.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMenu]);

  //當日期改變時呼叫api取得區間開信率
  useEffect(() => {
    if (date.from && date.to) {
      getRangeOpentime(getLocalStorage("mode"), date.from, date.to).then(
        (response) => {
          if (response.success === false) {
            setFalse(true, response.errorMesg);
          } else {
            setRangeOpentime(response.data);
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  //當日期改變時呼叫api取得最多人開信時段
  useEffect(() => {
    if (ym.month) {
      getMaxOpentime(getLocalStorage("mode"), ym.year, ym.month).then(
        (response) => {
          if (response.success === false) {
            setFalse(true, response.errorMesg);
          } else {
            setMaxOpentime(response.data);
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ym]);

  const handleDateChange = (e) => {
    setDate((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  /**
   * 最多人開信時段的時間調整
   */
  const handleAddYm = () => {
    if (ym.month === 12) {
      setYM((prev) => ({ year: prev.year + 1, month: 1 }));
    } else {
      setYM((prev) => ({ year: prev.year, month: prev.month + 1 }));
    }
  };
  const handleMinusYm = () => {
    if (ym.month === 1) {
      setYM((prev) => ({ year: prev.year - 1, month: 12 }));
    } else {
      setYM((prev) => ({ year: prev.year, month: prev.month - 1 }));
    }
  };

  //區間開信率圖表
  let range_opentime_chart = {};

  /**
   * 將區間開信率整理成符合格式的資料
   */
  function setRangeOpentimeChart() {
    if (rangeOpentime) {
      range_opentime_chart[date.from] = {
        count: 1,
        ratio: 0,
      };

      rangeOpentime.datas.forEach((item) => {
        range_opentime_chart[item.date] = range_opentime_chart[item.date] || {
          count: 0,
          ratio: 0,
        };

        range_opentime_chart[item.date].count += 1;
        range_opentime_chart[item.date].ratio += item.open_ratio;
      });
      for (const date in range_opentime_chart) {
        range_opentime_chart[date] = Math.round(
          (range_opentime_chart[date].ratio /
            range_opentime_chart[date].count) *
            100
        );
      }
    }
  }

  setRangeOpentimeChart();

  const lineData = {
    labels: Object.keys(range_opentime_chart),
    datasets: [
      {
        label: "開信率",
        data: Object.values(range_opentime_chart),
        borderColor: getLocalStorage("mode") === "1" ? "#1b67b2" : "#a76402",
      },
    ],
  };

  const lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        position: "left",
        grid: {
          display: true,
          color: "rgba(183, 183, 183, 1)",
        },
        border: { display: true, dash: [4, 4], color: "#D9D9D9" },
        beginAtZero: true,
        suggestedMin: 0,
        max: 100,
        ticks: {
          stepSize: 20,
          font: {
            size: 14,
          },
          callback: function (value, index, ticks) {
            return value + "%";
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 14,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      zoom: {
        zoom: {
          pinch: {
            enabled: false, // Enable pinch zooming
          },
          wheel: {
            enabled: true, // Enable wheel zooming
          },
          mode: "x",
        },
      },
    },
  };

  //最多人開信時段表格
  let max_opentime_chart = {};

  /**
   * 將最多人開信時段的表格資料整理成符合格式的資料
   * level => 四個層級（深藍1、中藍2、淺藍3、最淺藍4）
   */
  function setMaxOpentimeChart() {
    for (let i = 23; i >= 0; i--) {
      max_opentime_chart[i] = {};
      for (let j = 1; j <= 7; j++) {
        max_opentime_chart[i][j] = { level: 0, count: 0 };
      }
    }

    if (maxOpentime && maxOpentime.datas.length > 0) {
      let max_count;
      maxOpentime.datas.forEach((item, index) => {
        if (index === maxOpentime.max_index) {
          max_count = item.count;
          max_opentime_chart[item.hour][item.week_day] = {
            level: 1,
            count: item.count,
          };
        } else {
          //判斷層級
          const rate = item.count / max_count;
          if (rate > 0.9) {
            max_opentime_chart[item.hour][item.week_day] = {
              level: 1, //深藍
              count: item.count,
            };
          } else if (rate >= 0.8 && rate < 0.9) {
            max_opentime_chart[item.hour][item.week_day] = {
              level: 2, //中藍
              count: item.count,
            };
          } else if (rate >= 0.6 && rate < 0.8) {
            max_opentime_chart[item.hour][item.week_day] = {
              level: 3, //淺藍
              count: item.count,
            };
          } else if (rate < 0.6) {
            max_opentime_chart[item.hour][item.week_day] = {
              level: 4, //最淺藍
              count: item.count,
            };
          }
        }
      });
    }
  }

  setMaxOpentimeChart();

  //過去一年寄信量圖表
  let mail_count_chart = {};

  /**
   * 將過去一年寄信量整理成符合格式的資料
   */
  function setMailCountChart() {
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    for (let i = month + 1; i <= 12; i++) {
      mail_count_chart[i] = 0;
    }
    if (month !== 1) {
      for (let i = 1; i <= month; i++) {
        mail_count_chart[`${year}/${i}`] = 0;
      }
    }
    if (mailCount) {
      mailCount.forEach((item) => {
        if (month !== 1) {
          if (Object.hasOwn(mail_count_chart, item.month)) {
            mail_count_chart[item.month] = item.count;
          } else if (Object.hasOwn(mail_count_chart, `${year}/${item.month}`)) {
            mail_count_chart[`${year}/${item.month}`] = item.count;
          }
        } else {
          mail_count_chart[item.month] = item.count;
        }
      });
    }
  }

  setMailCountChart();

  const barData = {
    labels: Object.keys(mail_count_chart),
    datasets: [
      {
        label: "寄信量",
        data: Object.values(mail_count_chart),
        backgroundColor:
          getLocalStorage("mode") === "1" ? "#1b67b2" : "#a76402",
      },
    ],
  };

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        position: "left",
        grid: {
          display: true,
          color: "rgba(183, 183, 183, 1)",
        },
        border: { display: true, dash: [4, 4], color: "#D9D9D9" },
        beginAtZero: true,
        suggestedMin: 0,
        ticks: {
          font: {
            size: 12,
          },
          callback: function (value, index, ticks) {
            return value + "封";
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      zoom: {
        zoom: {
          pinch: {
            enabled: false, // Enable pinch zooming
          },
          wheel: {
            enabled: true, // Enable wheel zooming
          },
          mode: "x",
        },
      },
    },
  };

  return (
    <PageWrap header={"數據統計"}>
      <div className="w-full px-9 py-[30px] my-6 bg-[var(--color-neutral-50)] p3">
        聯絡人總數 : {personCount}人(不重複)
      </div>
      <div className="flex gap-6 flex-wrap">
        <Block>
          <div className="flex items-center gap-4">
            <input
              type="date"
              className="py-1 outline outline-1 outline-[var(--color-neutral-400)]"
              name="from"
              value={date.from}
              onChange={handleDateChange}
            />
            <span>-</span>
            <input
              type="date"
              className="py-1 outline outline-1 outline-[var(--color-neutral-400)]"
              name="to"
              value={date.to}
              onChange={handleDateChange}
            />
            <span>區間開信率</span>
          </div>
          <p className="text-[64px] leading-none font-bold text-[var(--color-neutral-800)] my-[19px]">
            {rangeOpentime && Math.round(rangeOpentime.average_ratio * 100)}%
          </p>
          <div className="min-h-[400px]">
            {rangeOpentime && (
              <Chart type="line" data={lineData} options={lineOptions} />
            )}
          </div>
        </Block>
        <Block>
          <div className="flex items-center gap-5 mb-[19px]">
            <button onClick={handleMinusYm}>
              <i className="ri-arrow-left-s-fill"></i>
            </button>
            <span>
              <span>{ym.year}</span>年<span>{ym.month}</span>月
            </span>
            <button onClick={handleAddYm}>
              <i className="ri-arrow-right-s-fill"></i>
            </button>
          </div>
          <h5 className="text-[var(--color-neutral-800)] mb-[19px]">
            最多人開信時段
          </h5>
          <p className="text-[64px] leading-none font-bold text-[var(--color-neutral-800)] mb-[19px]">
            {maxOpentime &&
              maxOpentime.datas.length > 0 &&
              maxOpentime.datas[maxOpentime.max_index].hour +
                ":00 " +
                datePerWeek.filter(
                  (item, index) =>
                    index ===
                    maxOpentime.datas[maxOpentime.max_index].week_day - 1
                )}
          </p>
          <table className="w-full text-[var(--color-neutral-600)]">
            <thead>
              <tr>
                <th></th>
                {datePerWeek.map((item, index) => (
                  <th key={index} className="font-normal text-sm text-center">
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(max_opentime_chart).map((hour, index) => (
                <tr key={index}>
                  <th className="font-normal text-sm">
                    {hour.padStart(2, "0")}
                  </th>
                  {Object.values(max_opentime_chart[hour]).map(
                    (week_day, index) => (
                      <td
                        key={index}
                        className={
                          "text-center text-xs border-[1px] border-[var(--color-neutral-50)] " +
                          (week_day.level === 1
                            ? "bg-[var(--color-primary-1)] text-[var(--color-neutral-50)]"
                            : week_day.level === 2
                            ? "bg-[var(--color-primary-2)] text-[var(--color-neutral-50)]"
                            : week_day.level === 3
                            ? "bg-[var(--color-primary-4)]"
                            : "bg-[var(--color-primary-5)]")
                        }
                      >
                        {week_day.count}
                      </td>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </Block>
        <Block>
          <h5 className="text-[var(--color-neutral-800)] mb-[19px]">
            過去一年寄信量
          </h5>
          <div className="min-h-[400px]">
            <Chart type="bar" data={barData} options={barOptions} />
          </div>
        </Block>
      </div>
      {getShowPopup().show && (
        <CustomAlertDialog
          show={getShowPopup().show}
          message={getShowPopup().message}
          confirmFunction={() => setIsShow(false)}
        />
      )}
    </PageWrap>
  );
}
