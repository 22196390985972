import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { PrimaryButton, ThirdButton } from "../Button";
import { useOpen } from "../../hooks";

export default function CustomModal({
  className,
  maxWidth,
  variant,
  disabled,
  children,
  buttonTitle,
  modalTitle,
  dialogActions,
  cancelFunction,
  confirmFunction,
}) {
  const { getOpen, setOpen } = useOpen();

  const handleOpen = () => setOpen(true);

  const handleClose = (e) => {
    if (e.target.innerText === "確認" || e.target.innerText === "插入") {
      confirmFunction();
      setOpen(false);
    } else {
      cancelFunction();
      setOpen(false);
    }
  };

  return (
    <div>
      <Button
        className={className}
        variant={variant}
        disabled={disabled}
        onClick={handleOpen}
        sx={{
          minWidth: "auto",
          color: "var(--color-neutral-900)",
          fontSize: 16,
          fontWeight: "normal",
          letterSpacing: 0,
          boxShadow: "none",
          ":hover": {
            boxShadow: "none",
          },
        }}
      >
        {buttonTitle}
      </Button>
      <Dialog
        open={getOpen()}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          "& .MuiDialog-paper": {
            p: buttonTitle !== "選取聯絡人" && 3,
            borderRadius: 5,
            maxWidth: maxWidth,
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: 32,
            fontWeight: "normal",
            color: "var(--color-neutral-900)",
            p: 0,
          }}
          id="modal-modal-title"
          component="h2"
        >
          {modalTitle}
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>{children}</DialogContent>
        {buttonTitle !== "選取聯絡人" ? (
          <DialogActions
            sx={{ mt: 3, p: 0, justifyContent: "center", gap: 3.75 }}
          >
            <ThirdButton text={"取消"} onClick={handleClose} />
            <PrimaryButton text={"確認"} onClick={handleClose} />
          </DialogActions>
        ) : (
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: 4.5,
              py: 2,
              bgcolor: "var(--color-primary-5)",
            }}
          >
            <Button
              sx={{
                color: "var(--color-primary-1)",
                p: 0,
                ":hover": { bgcolor: "transparent" },
              }}
              onClick={handleClose}
            >
              <i className="ri-close-line text-[44px]"></i>
            </Button>
            {dialogActions}
            <PrimaryButton text={"插入"} onClick={handleClose} />
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
