import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { getLocalStorage } from "../../utils";
import { updatePassword } from "../../api";
import PageWrap from "../../components/Pagewrap";
import CustomAlertDialog from "../../components/CustomAlertDialog";
import { useShowPopup } from "../../hooks";
import { PrimaryButton, ThirdButton } from "../../components/Button";
import { FormControl, FormLabel, TextField } from "@mui/material";

export default function Password() {
  const [originPassword, setOriginPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { setFalse, setSuccess, getShowPopup, setIsShow } = useShowPopup();

  const navigate = useNavigate();

  const handleUpdate = () => {
    setLoading(true);
    if (originPassword === getLocalStorage("password")) {
      if (originPassword !== newPassword) {
        updatePassword(newPassword).then((response) => {
          if (response.success === false) {
            setFalse(true, response.errorMesg);
          } else {
            localStorage.setItem("password", newPassword);
            setSuccess(true, "更改密碼成功", "");
            navigate("/mail/sended");
          }
        });
      } else {
        setFalse(true, "新密碼不能與舊密碼相同");
      }
    } else {
      setFalse(true, "舊密碼輸入錯誤");
    }
    setLoading(false);
  };

  const handlOriginPassword = (e) => {
    setOriginPassword(e.target.value);
  };

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  return (
    <PageWrap header={"更改密碼"}>
      <div className="bg-white p-9 mt-6">
        <form className="w-[850px]">
          <FormControl sx={{ mb: 3 }} fullWidth>
            <FormLabel sx={{ color: "var(--color-neutral-800)" }}>
              舊密碼<span className="req">*</span>
            </FormLabel>
            <TextField
              type="password"
              variant="outlined"
              required
              sx={{
                "& .MuiOutlinedInput-input": {
                  px: 2,
                  py: "10px",
                  border: 1,
                  borderColor: "var(--color-neutral-400)",
                },
              }}
              value={originPassword}
              onChange={handlOriginPassword}
            />
          </FormControl>
          <FormControl sx={{ mb: 2 }} fullWidth>
            <FormLabel sx={{ color: "var(--color-neutral-800)" }}>
              新密碼<span className="req">*</span>
            </FormLabel>
            <TextField
              type="password"
              variant="outlined"
              required
              sx={{
                "& .MuiOutlinedInput-input": {
                  px: 2,
                  py: "10px",
                  border: 1,
                  borderColor: "var(--color-neutral-400)",
                },
              }}
              value={newPassword}
              onChange={handleNewPassword}
            />
          </FormControl>
          <ol className="list-decimal list-inside text-[var(--color-neutral-400)] mb-4">
            <li>密碼設定長度至少為8個字元</li>
            <li>密碼應包含數字、英文字母</li>
          </ol>
          <PrimaryButton
            text={"儲存"}
            disabled={
              loading || originPassword.length === 0 || newPassword.length === 0
            }
            margin={"0px"}
            onClick={handleUpdate}
          />
          <ThirdButton
            text={"取消"}
            onClick={() => {
              setOriginPassword("");
              setNewPassword("");
            }}
            margin={"30px"}
          />
        </form>
      </div>
      {getShowPopup().show && (
        <CustomAlertDialog
          show={getShowPopup().show}
          message={getShowPopup().message}
          confirmFunction={() => setIsShow(false)}
        />
      )}
    </PageWrap>
  );
}
