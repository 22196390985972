import { Tab } from "../../components/Tab";
import PageWrap from "../../components/Pagewrap";
import { TabContent } from "../../components/TabContent";
import Template from "./Template";
import Attachment from "./Attachment";
import Image from "./Image";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { menuContext } from "../../context";
import CustomizedMail from "./CustomizedMail";

export default function File() {
  const location = useLocation();
  const { setMenu } = useContext(menuContext);

  useEffect(() => {
    setMenu({ text: ["電子報", "檔案存放區"], href: ["/", "/file/template"] });
  }, [setMenu]);

  return (
    <PageWrap
      header={"檔案存放區"}
      tabs={
        <>
          <Tab
            href={"template/text"}
            text={"HTML模版"}
            isActive={location.pathname === "/file/template/text"}
          />
          <Tab
            href={"template/editor"}
            text={"編輯器模版"}
            isActive={location.pathname === "/file/template/editor"}
          />
          <Tab
            href={"attachment"}
            text={"附件檔案"}
            isActive={location.pathname === "/file/attachment"}
          />
          <Tab
            href={"image"}
            text={"上傳圖片"}
            isActive={location.pathname === "/file/image"}
          />
          <Tab
            href={"customizedmail"}
            text={"個人化信件"}
            isActive={location.pathname === "/file/customizedmail"}
          />
        </>
      }
    >
      <TabContent
        isActive={location.pathname === "/file/template/text"}
        children={<Template type={"text"} />}
      />
      <TabContent
        isActive={location.pathname === "/file/template/editor"}
        children={<Template type={"editor"} />}
      />
      <TabContent
        isActive={location.pathname === "/file/attachment"}
        children={<Attachment />}
      />
      <TabContent
        isActive={location.pathname === "/file/image"}
        children={<Image />}
      />
      <TabContent
        isActive={location.pathname === "/file/customizedmail"}
        children={<CustomizedMail />}
      />
    </PageWrap>
  );
}
