import { PrimaryButton, ThirdButton } from "../Button";

export default function ImportFailed({
  type,
  data,
  handleBack,
  handleReupload,
}) {
  return (
    <>
      <div className="p-9 bg-white mb-6">
        <h1 className="text-center mb-6">
          {type === "customized"
            ? data.failedData.length > 0
              ? "上傳失敗"
              : "上傳完成"
            : "匯入完成"}
        </h1>
        <p className="p1 text-[var(--color-primary-1)] text-center">
          {type === "customized"
            ? `檔案共有${data.totalLength}筆，失敗${data.failedData.length}筆`
            : `共${data.totalLength}筆，新增${data.success_count}筆，失敗${data.failedData.length}筆
              `}
        </p>
        {data.failedData.length > 0 && (
          <>
            <p className="p3 text-[var(--color-neutral-800)] text-center my-6">
              {type === "customized"
                ? "請檢查「失敗」名單，可能是因為格式錯誤而將無法送達。請修正格式後，重新匯入。"
                : "「失敗」名單並沒有匯入後台，請將調整正確再重新匯入"}
            </p>
            <div className="border-[1px] border-[var(--color-neutral-400)] p-9">
              <p className="p1 text-center mb-6">失敗名單:</p>
              <ul className="flex flex-col items-center gap-6">
                {data.failedData.map((result, index) => {
                  return <li key={index}>{result}</li>;
                })}
              </ul>
            </div>
          </>
        )}
      </div>
      <PrimaryButton
        text={`${type === "customized" ? "回到列表" : "重新匯入"}`}
        margin={"0px!important"}
        onClick={handleReupload}
      />
      {type !== "customized" && (
        <ThirdButton
          text={"回到名單"}
          margin={"16px!important"}
          onClick={handleBack}
        />
      )}
    </>
  );
}
