import { createContext, useEffect, useState } from "react";
import ImageItem from "./ImageItem";
import {
  baseUrl,
  createImage,
  deleteImage,
  getImageList,
  updateImage,
} from "../../../api";
import { FormatDate } from "../../../utils";
import { useLocation } from "react-router-dom";
import { selectedContext } from "../../../context";
import CustomDialog from "../../../components/CustomDialog";
import { UploadButton } from "../../../components/Button";
import { useSearch, useShowPopup } from "../../../hooks";
import CustomAlertDialog from "../../../components/CustomAlertDialog";
import SearchInput from "../../../components/SearchInput";

export const imgContext = createContext();

export default function Image() {
  const { getSearchValue, setSearchValue } = useSearch();
  const [imageList, setImageList] = useState([]);
  const [currImg, setCurrImg] = useState(null);
  const [currId, setCurrId] = useState(0);
  const [name, setName] = useState("");
  const [copytext, setCopytext] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const { setFalse, setSuccess, getShowPopup, setIsShow } = useShowPopup();
  const location = useLocation();

  useEffect(() => {
    setCurrId(0);
    setSelectedItems([]);
    window.scrollTo(0, 0);
    getImageList().then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        setImageList(response.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    imageList.forEach((element) => {
      if (element.image_id === currId) {
        setCurrImg(element);
        setName(element.image_name);
        setCopytext(baseUrl + element.url);
      }
    });
  }, [currId, imageList]);

  const handleName = (e) => {
    setName(e.target.value);
  };

  /**
   * 複製圖片連結
   */
  const handleCopytext = (e) => {
    setCopytext(e.target.value);
  };

  /**
   * 按下enter後重新命名
   */
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      updateImage(currImg.image_id).then((response) => {
        if (response.success === false) {
          setFalse(true, response.errorMesg);
        } else {
          setSuccess(true, "重新命名成功", "");
        }
      });
    }
  };

  /**
   * 上傳檔案
   */
  const handleUpload = (e) => {
    var formdata = new FormData();
    let imageFile = e.target;
    if (imageFile.files[0] !== undefined) {
      formdata.append("name", imageFile.files[0].name);
      formdata.append("file", imageFile.files[0], imageFile.files[0].name);
      createImage(formdata).then((response) => {
        if (response.success === false) {
          if (response.errorMesg.includes("file size too large")) {
            setFalse(true, "檔案大小超過限制");
          } else {
            setFalse(true, response.errorMesg);
          }
        } else {
          window.location.reload();
        }
      });
    }
  };

  const handleSearch = (items) => {
    return items
      .filter((item) => item.image_name.includes(getSearchValue()))
      .map((result, index) => {
        return <ImageItem key={index} props={result} />;
      });
  };

  return (
    <selectedContext.Provider value={{ selectedItems, setSelectedItems }}>
      <imgContext.Provider value={{ currId, setCurrId }}>
        <div className="flex justify-between mb-6">
          <div className="flex items-center gap-8">
            <UploadButton
              text={
                <>
                  <i className="ri-upload-2-line"></i>上傳圖片
                </>
              }
              onChange={handleUpload}
            />
            <span className="text-[var(--color-neutral-600)]">
              圖片大小上限: 1MB
            </span>
            <CustomDialog
              className="delete-btn"
              disabled={selectedItems.length > 0 ? false : true}
              buttonTitle={"刪除"}
              confirmFuncion={() => deleteImage(selectedItems)}
              dialogTitle={"確定要刪除嗎？"}
              reload={true}
            />
            <span>選取：{selectedItems.length}筆</span>
          </div>
          <SearchInput
            searchValue={getSearchValue()}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div className="h-[calc(100vh-350px)] flex justify-between gap-3">
          <ul className="w-[calc(60%-12px)] flex flex-wrap content-start items-start gap-6 overflow-y-auto">
            {handleSearch(imageList)}
          </ul>
          <div className="w-[calc(40%-12px)] bg-white p-6 overflow-auto">
            {currId !== 0 && currImg ? (
              <>
                <h4 className="mb-6">圖片詳細資料</h4>
                <div className="text-[var(--color-neutral-600)] whitespace-nowrap mb-6">
                  <span className="mr-6">
                    {FormatDate(currImg.update_date).substring(0, 11)}
                  </span>
                  <span className="mr-6">{currImg.size}</span>
                  <span>{currImg.lw.replace("X", " x ")}px</span>
                </div>
                <img
                  src={baseUrl + currImg.url}
                  alt={currImg.image_name}
                  className="w-full h-[206px] object-contain border-[1px] border-[var(--color-neutral-200)] mb-6 overflow-hidden"
                />
                <div className="flex items-center gap-6 mb-6">
                  <h5 className="whitespace-nowrap">名稱</h5>
                  <input
                    type="text"
                    className="px-4 py-[10px] outline outline-1 outline-[var(--color-neutral-400)]"
                    value={name}
                    onChange={handleName}
                    onKeyDown={handleKeyDown}
                  />
                  <span>.{currImg.ext}</span>
                </div>
                <div className="flex items-center gap-6">
                  <h5 className="whitespace-nowrap">網址</h5>
                  <input
                    className="px-4 py-[10px] outline outline-1 outline-[var(--color-neutral-400)]"
                    value={copytext}
                    disabled
                    onChange={handleCopytext}
                  />
                  <button
                    onClick={async () => {
                      await navigator.clipboard.writeText(copytext);
                      setSuccess(true, "複製成功", "");
                    }}
                  >
                    <i className="ri-file-copy-line"></i>
                  </button>
                </div>
              </>
            ) : (
              "您尚未選取圖片"
            )}
          </div>
        </div>
        {getShowPopup().show && (
          <CustomAlertDialog
            show={getShowPopup().show}
            message={getShowPopup().message}
            confirmFunction={() => setIsShow(false)}
          />
        )}
      </imgContext.Provider>
    </selectedContext.Provider>
  );
}
