import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

/**
 * 成效數據中各個數據的component
 * props: { color, title, count, totalCount }
 * color: string -> 'blue' || 'yellow' -> component顏色
 * title: string -> component標題
 * count: number -> 行為人數
 * totalCount: number -> 寄送/開信總人數
 */
export default function RatioItem({ color, title, count, totalCount }) {
  return (
    <div
      className={"ratio" + (color === "blue" ? " blue-ratio" : " yellow-ratio")}
    >
      <div className="flex flex-col justify-between">
        <h5 className="text-[var(--color-neutral-800)]">{title}</h5>
        <p className="text-[var(--color-neutral-800)]">
          <strong className="text-[64px] leading-none">
            {count ? count : 0}
          </strong>
          人
        </p>
        <p className="p3 text-[var(--color-neutral-400)]">
          {title === "點擊率" ? "開信人數：" : "寄送人數："}
          {totalCount}人
        </p>
      </div>
      <div className="w-[140px] h-[140px]">
        <CircularProgressbarWithChildren
          value={totalCount === 0 || !count ? 0 : (count / totalCount) * 100}
          styles={buildStyles({
            rotation: 0, //起始點
            strokeLinecap: "round", //端點形狀
            pathTransitionDuration: 0.5, //動畫時間
            pathColor: color === "blue" ? "#3297E3" : "#F5B518", //填滿顏色
            textColor: "#000000", //中間字顏色
            trailColor: "#F3F3F3", //未填滿顏色
          })}
        >
          <div className="text-[40px]">
            {totalCount === 0 || !count
              ? 0
              : Math.round((count / totalCount) * 100)}
            %
          </div>
        </CircularProgressbarWithChildren>
      </div>
    </div>
  );
}
