import axios from "axios";
import { getLocalStorage } from "../utils";
import { Navigate } from "react-router-dom";

export const baseUrl = "https://api.edm.luckertw.com.tw/";

export const userRequest = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    Authorization: getLocalStorage("token"),
  },
});

userRequest.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    console.dir(error);
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      Navigate("/", true);
    }
  }
);

/**
 * 登入
 * @param {string} email
 * @param {string} password
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const login = async (email, password) => {
  try {
    if (!email || !password) {
      return { success: false, data: "", errorMesg: "必填欄位不得為空" };
    }

    const response = await userRequest.post(
      "/api/auth/login",
      JSON.stringify({ email, password })
    );
    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 更改密碼
 * @param {string} password
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const updatePassword = async (password) => {
  try {
    if (!password) {
      return { success: false, data: "", errorMesg: "password為空" };
    }

    const response = await userRequest.patch(
      "/api/auth/update/password",
      JSON.stringify({ password })
    );
    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 取得信件
 * @param {string} status
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const getMailList = async (status) => {
  try {
    const response = await userRequest.get(`/api/mail/management/${status}`);
    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 複製信件到草稿區
 * @param {number} id
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const copyMail = async (id) => {
  try {
    if (!id) {
      return { success: false, data: "", errorMesg: "id為空" };
    }

    const response = await userRequest.get(
      `/api/mail/management/copy/edm/${id}`
    );
    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 刪除信件
 * @param {Array} id_array
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const deleteMails = async (id_array) => {
  try {
    if (!id_array) {
      return { success: false, data: "", errorMesg: "id_array為空" };
    } else if (!Array.isArray(id_array)) {
      return { success: false, data: "", errorMesg: "傳遞值資料型別有誤" };
    }

    const response = await userRequest.delete(
      "/api/mail/management/delete_edm",
      { data: { id_array: id_array } }
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 新增EDM
 * @param {object} form
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const createMail = async (form) => {
  try {
    let {
      receiver,
      customized_data_id,
      sender,
      sender_email,
      title,
      template_id,
      content,
      attachments,
      status,
      send_time,
      remark,
      contentMode,
    } = form;

    if (
      (!receiver && !customized_data_id) ||
      (receiver !== null && receiver.length === 0) ||
      !sender ||
      !sender_email ||
      !title ||
      !content ||
      send_time.length <= 0
    ) {
      return { success: false, data: "", errorMesg: "必填欄位不得為空" };
    } else if (
      template_id === null ||
      attachments === null ||
      remark === null ||
      customized_data_id === null
    ) {
      return { success: false, data: "", errorMesg: "空值不需傳遞" };
    } else if (status === "draft" && send_time === "now") {
      delete form.send_time;
    }

    if (receiver !== null && !Array.isArray(receiver)) {
      form.receiver = receiver.split(",");
    }

    if (!content.includes(contentMode)) {
      form.content = `<div class='${contentMode}'></div>${content}`;
      delete form.contentMode;
    }

    const response = await userRequest.post(
      "/api/mail/management/create_edm",
      form
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 更新EDM
 * @param {object} form
 * @param {number} edm_id
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const updateMail = async (form, edm_id) => {
  try {
    let {
      receiver,
      customized_data_id,
      sender,
      sender_email,
      title,
      template_id,
      content,
      attachments,
      status,
      send_time,
      remark,
      contentMode,
    } = form;

    if (
      (!receiver && !customized_data_id) ||
      (receiver !== null && receiver.length === 0) ||
      !sender ||
      !sender_email ||
      !title ||
      !content ||
      send_time.length <= 0
    ) {
      return { success: false, data: "", errorMesg: "必填欄位不得為空" };
    } else if (
      template_id === null ||
      attachments === null ||
      remark === null ||
      customized_data_id === null
    ) {
      return { success: false, data: "", errorMesg: "空值不需傳遞" };
    } else if (status === "draft" && send_time === "now") {
      delete form.send_time;
    }

    if (receiver !== null && !Array.isArray(receiver)) {
      form.receiver = receiver.split(",");
    }

    if (!content.includes(contentMode)) {
      form.content = `<div class='${contentMode}'></div>${content}`;
      delete form.contentMode;
    }

    const response = await userRequest.patch(
      `/api/mail/management/update_edm/${edm_id}`,
      form
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 將已排程改成草稿
 * @param {number} id
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const scheduledToDraft = async (id) => {
  try {
    if (!id) {
      return { success: false, data: "", errorMesg: "id為空" };
    }

    const response = await userRequest.patch(
      `/api/mail/management/schedule/transformDraft/${id}`
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 取得信件所有資料夾
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const getFolderList = async () => {
  try {
    const response = await userRequest.get(
      "/api/mail/management/select/all/edm_files"
    );
    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 新增資料夾
 * @param {string} name
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const createFolder = async (name) => {
  try {
    if (!name) {
      return { success: false, data: "", errorMesg: "name為空" };
    }

    const response = await userRequest.post(
      "/api/mail/management/create/edm_file",
      JSON.stringify({ file_name: name })
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 刪除資料夾
 * @param {number} id
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const deleteFolder = async (id) => {
  try {
    if (!id) {
      return { success: false, data: "", errorMesg: "id為空" };
    }

    const response = await userRequest.delete(
      `/api/mail/management/delete/edm_file/${id}`
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 將信件新增&修改到資料夾
 * @param {Array} id_array
 * @param {number} folder_id
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const moveMailToFolder = async (id_array, folder_id) => {
  try {
    if (!id_array) {
      return {
        success: false,
        data: "",
        errorMesg: "id_array為空或folder_id為空",
      };
    } else if (
      !Array.isArray(id_array) ||
      (folder_id && !Number.isInteger(folder_id))
    ) {
      return { success: false, data: "", errorMesg: "傳遞值資料型別有誤" };
    }

    const response = await userRequest.post(
      "/api/mail/management/insert/edm/into/file",
      JSON.stringify({ edm_id_array: id_array, edm_file_id: folder_id })
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 取得特定EDM
 * @param {number} edm_id
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const getParticularMail = async (edm_id) => {
  try {
    if (!edm_id) {
      return { success: false, data: "", errorMesg: "id為空" };
    }

    const response = await userRequest.get(`/api/mail/${edm_id}`);

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

export const getMailEffectiveness = async (id) => {
  try {
    const response = await userRequest.get(`/api/mail/effectiveness/${id}`);

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 根據群組取得聯絡人
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const getContactorsByTeam = async () => {
  try {
    const response = await userRequest.get("/api/mail/management/contacts");

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

export const getMailContent = async (id) => {
  try {
    const response = await userRequest.get(`/api/mail/content/${id}`);

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 取得信件所有收件者
 * @param {number} id
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const getMailDetail = async (id) => {
  try {
    if (!id) {
      return { success: false, data: "", errorMesg: "id為空" };
    }

    const response = await userRequest.get(`/api/mail/detail/${id}`);

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 取得模板
 * @param {string} type
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const getTemplateList = async (type) => {
  try {
    if (!type) {
      return { success: false, data: "", errorMesg: "type為空" };
    } else if (!type === "text" || !type === "editor") {
      return { success: false, data: "", errorMesg: "type必為text或editor" };
    }

    const response = await userRequest.get(`/api/file/templates/${type}`);
    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 新增模板
 * @param {string} name
 * @param {string} content
 * @param {string} type
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const createTemplate = async (name, content, type) => {
  try {
    if (!name || !content || !type) {
      return { success: false, data: "", errorMesg: "必填欄位不得為空" };
    } else if (!type === "text" || !type === "editor") {
      return { success: false, data: "", errorMesg: "type必為text或editor" };
    }

    const response = await userRequest.post(
      "/api/file/create/template",
      JSON.stringify({ name: name, content: content, type: type })
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 複製模板
 * @param {number} id
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const copyTemplate = async (id) => {
  try {
    if (!id) {
      return { success: false, data: "", errorMesg: "id為空" };
    }

    const response = await userRequest.get(`/api/file/copyTemplate/${id}`);

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 刪除模板
 * @param {number} id
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const deleteTemplate = async (id) => {
  try {
    if (!id) {
      return { success: false, data: "", errorMesg: "id為空" };
    }

    const response = await userRequest.delete(
      `/api/file/delete/template/${id}`
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 取得附件
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const getAttachmentList = async () => {
  try {
    const response = await userRequest.get("/api/file/attachments");
    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 新增附件
 * @param {FormData} formdata
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const createAttachment = async (formdata) => {
  try {
    if (!formdata) {
      return { success: false, data: "", errorMesg: "formdata為空" };
    }

    const response = await userRequest.post(
      "/api/file/create/attachment",
      formdata,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 刪除附件
 * @param {number} id
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const deleteAttachment = async (id) => {
  try {
    if (!id) {
      return { success: false, data: "", errorMesg: "id為空" };
    }

    const response = await userRequest.delete(
      `/api/file/delete/attachment/${id}`
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 取得圖片
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const getImageList = async () => {
  try {
    const response = await userRequest.get("/api/file/images");
    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 新增圖片
 * @param {FormData} formdata
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const createImage = async (formdata) => {
  try {
    const response = await userRequest.post(
      "/api/file/create/image",
      formdata,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 更新圖片
 * @param {number} id
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const updateImage = async (id) => {
  try {
    const response = await userRequest.patch(`/api/file/update/image/${id}`);

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 刪除圖片
 * @param {Array} id_array
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const deleteImage = async (id_array) => {
  try {
    if (!id_array) {
      return { success: false, data: "", errorMesg: "id_array為空" };
    } else if (!Array.isArray(id_array)) {
      return { success: false, data: "", errorMesg: "傳遞值資料型別有誤" };
    }

    const response = await userRequest.delete("/api/file/delete/image", {
      data: { id_array: id_array },
    });

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 取得個人化信件
 * @param {number} id
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const getCustomizedMail = async (id) => {
  try {
    let response;
    if (id) {
      response = await userRequest.get(`/api/file/customized/data?${id}`);
    } else {
      response = await userRequest.get("/api/file/customized/data");
    }
    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 新增個人化信件
 * @param {FormData} formdata
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const createCustomizedMail = async (formdata) => {
  try {
    if (!formdata) {
      return { success: false, data: "", errorMesg: "formdata為空" };
    }

    const response = await userRequest.post(
      "/api/file/create/customized/data",
      formdata,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 刪除個人化信件
 * @param {Array} id_array
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const deleteCustomizedMail = async (id_array) => {
  try {
    if (!id_array) {
      return { success: false, data: "", errorMesg: "id_array為空" };
    } else if (!Array.isArray(id_array)) {
      return { success: false, data: "", errorMesg: "傳遞值資料型別有誤" };
    }

    const response = await userRequest.delete(
      "/api/file/delete/customized/data",
      {
        data: { id_array: id_array },
      }
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 取得個人化信件範例
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const getCustomizedMailExample = async () => {
  try {
    const response = await userRequest.get("/api/file/example/customized/data");

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 取得群組
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const getTeamList = async () => {
  try {
    const response = await userRequest.get("/api/contact/team");
    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 新增群組
 * @param {string} name
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const createTeam = async (name) => {
  try {
    if (!name) {
      return { success: false, data: "", errorMesg: "name為空" };
    }
    const response = await userRequest.post("/api/contact/create/team", {
      name: name,
    });

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 複製群組
 * @param {number} id
 * @param {string} name
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const copyTeam = async (id, name) => {
  try {
    if (!id || !name) {
      return { success: false, data: "", errorMesg: "必填欄位不得為空" };
    } else if (!Number.isInteger(id)) {
      return { success: false, data: "", errorMesg: "傳遞值資料型別有誤" };
    }

    const response = await userRequest.post(
      "/api/contact/copy/team",
      JSON.stringify({ team_id: id, team_name: name })
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 刪除群組
 * @param {number} id
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const deleteTeam = async (id) => {
  try {
    if (!id) {
      return { success: false, data: "", errorMesg: "id為空" };
    }

    const response = await userRequest.delete(`/api/contact/delete/team/${id}`);

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 獲取特定群組收件者
 * @param {number} id
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const getParticularTeamReceiver = async (id) => {
  try {
    let response;
    if (id) {
      response = await userRequest.get(`/api/contact/receivers?id=${id}`);
    } else {
      response = await userRequest.get("/api/contact/receivers");
    }

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 新增單一收件者
 * @param {object} form
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const createReceiver = async (form) => {
  try {
    const { name, team_id, email, unit, phone, remark } = form;

    if (!name || !team_id || !email) {
      return "必填欄位有誤";
    }
    if (unit && unit.length === 0) {
      delete form.unit;
    }
    if (phone && phone.length === 0) {
      delete form.phone;
    }
    if (remark && remark.length === 0) {
      delete form.remark;
    }

    const response = await userRequest.post(
      "/api/contact/create/one/receiver",
      form
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 編輯收件者
 * @param {object} form
 * @param {number} id
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const updateReceiver = async (form, id) => {
  try {
    const { name, team_id_array, status, unit, phone, remark } = form;

    if (!name || !team_id_array || status === null) {
      return "必填欄位有誤";
    }
    if (unit && unit.length === 0) {
      delete form.unit;
    }
    if (phone && phone.length === 0) {
      delete form.phone;
    }
    if (remark && remark.length === 0) {
      delete form.remark;
    }

    const response = await userRequest.patch(
      `/api/contact/update/one/receiver/${id}`,
      form
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 將收件者加入其他群組
 * @param {Array} team_id_array
 * @param {Array} receiver_id_array
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const updateReceiverToOtherGroup = async (
  team_id_array,
  receiver_id_array
) => {
  try {
    if (!team_id_array || !receiver_id_array) {
      return { success: false, data: "", errorMesg: "必填欄位不得為空" };
    } else if (
      !Array.isArray(team_id_array) ||
      !Array.isArray(receiver_id_array)
    ) {
      return { success: false, data: "", errorMesg: "傳遞值資料型別有誤" };
    }

    const response = await userRequest.patch(
      "/api/contact/insert/multi_receiver/team",
      { team_id_array: team_id_array, receiver_id_array: receiver_id_array }
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 刪除收件者
 * @param {number} team_id
 * @param {Array} id_array
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const deleteReceivers = async (team_id, id_array) => {
  try {
    if (!id_array) {
      return { success: false, data: "", errorMesg: "必填欄位不得為空" };
    } else if (
      !Array.isArray(id_array) ||
      (team_id && !Number.isInteger(team_id))
    ) {
      return { success: false, data: "", errorMesg: "傳遞值資料型別有誤" };
    }

    const response = await userRequest.delete("/api/contact/delete/receivers", {
      data: { team_id: team_id, receiver_id: id_array },
    });

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 匯出收件者
 * @param {number} id
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const exportReceiver = async (id) => {
  try {
    if (!id) {
      return { success: false, data: "", errorMesg: "id為空" };
    } else if (!Number.isInteger(id)) {
      return { success: false, data: "", errorMesg: "傳遞值資料型別有誤" };
    }

    const response = await userRequest.get(
      `/api/contact/export/receivers/${id}`
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 匯出自訂篩選收件者
 * @param {Array} receiver_id_array
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const exportParticularReceivers = async (receiver_id_array) => {
  try {
    if (!receiver_id_array) {
      return { success: false, data: "", errorMesg: "receiver_id_array為空" };
    } else if (!Array.isArray(receiver_id_array)) {
      return { success: false, data: "", errorMesg: "傳遞值資料型別有誤" };
    }

    const response = await userRequest.post(
      "/api/contact/export/receivers/all",
      { receiver_id_array: receiver_id_array }
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 新增收件者範例檔案
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const getCreateReceiversExample = async () => {
  try {
    const response = await userRequest.get("/api/contact/example");

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 匯入收件者
 * @param {FormData} formdata
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const createReceiversByFile = async (formdata) => {
  try {
    if (!formdata) {
      return { success: false, data: "", errorMesg: "formdata為空" };
    }

    const response = await userRequest.post(
      "/api/contact/create/receivers",
      formdata,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 獲取總收件人數量(扣除被封鎖與無效信箱)
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const getStatisticsPersonCount = async () => {
  try {
    const response = await userRequest.get("/api/statistics/total/receiver");

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 獲取區間開信率
 * @param {number} platform_id
 * @param {Date} from_date
 * @param {Date} to_date
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const getRangeOpentime = async (platform_id, from_date, to_date) => {
  try {
    if (!platform_id || !from_date || !to_date) {
      return { success: false, data: "", errorMesg: "必填欄位不得為空" };
    }

    const response = await userRequest.get(
      `/api/statistics/interval/opened/ratio/${platform_id}/${from_date}/${to_date}`
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 獲取最多人開信時段
 * @param {number} platform_id
 * @param {number} year
 * @param {number} month
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const getMaxOpentime = async (platform_id, year, month) => {
  try {
    if (!platform_id || !year || !month) {
      return { success: false, data: "", errorMesg: "必填欄位不得為空" };
    }

    const response = await userRequest.get(
      `/api/statistics/open/time/${platform_id}/${year}/${month}`
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};

/**
 * 獲取過去一年寄信量
 * @param {number} platform_id
 * @returns {object} {success: boolean, data: any, errorMesg: string}
 */
export const getMailCountLastYear = async (platform_id) => {
  try {
    if (!platform_id) {
      return { success: false, data: "", errorMesg: "platform_id為空" };
    }

    const response = await userRequest.get(
      `/api/statistics/sent/count/${platform_id}`
    );

    return response.data;
  } catch (error) {
    return error.toString();
  }
};
