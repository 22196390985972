import { Link } from "react-router-dom";

export const Tab = ({ mailInfo, text, href, isActive }) => {
  return (
    <li>
      <Link
        className={"tab" + (isActive ? " activeTab" : "")}
        to={href}
        state={mailInfo}
      >
        {text}
      </Link>
    </li>
  );
};
