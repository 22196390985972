import { useContext, useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import { openContext, teamContext } from "../../context";
import { createReceiver, updateReceiver } from "../../api";
import { useParams } from "react-router-dom";
import { PrimaryButton, ThirdButton } from "../Button";
import { useShowPopup } from "../../hooks";
import CustomAlertDialog from "../CustomAlertDialog";
import { getLocalStorage } from "../../utils";

const Tag = ({ id, platform, name, handleFunction }) => {
  return (
    <li
      className={`flex h-[30px] px-[10px] rounded-[15px] items-center justify-center text-white ${
        platform === parseInt(getLocalStorage("mode"))
          ? "bg-[var(--color-primary-1)]"
          : "bg-[var(--color-secondary-1)]"
      }`}
    >
      {name}
      {platform === parseInt(getLocalStorage("mode")) && (
        <button type="button" onClick={handleFunction}>
          <i id={id} className="ri-close-line"></i>
        </button>
      )}
    </li>
  );
};

const statusList = [
  { name: "正常", value: "normal" },
  { name: "信箱無效", value: "invalid" },
  { name: "被封鎖", value: "blocked" },
  { name: "黑名單", value: "blacked" },
];

/**
 * @param {object} props
 */
export const ReceiverModal = ({ props, hasCustomizedData }) => {
  const param = useParams();
  const { open, setOpen } = useContext(openContext);
  const { teamList } = useContext(teamContext);
  const team_id_array = teamList.map((item) => item.team_id);
  const [form, setForm] = useState(
    props
      ? {
          name: props.receiver_name,
          team_id_array: props.team_id_array.map((item) => item.team_id),
        }
      : {
          team_id: parseInt(param.id),
          name: "",
          email: "",
        }
  );
  const [unit, setUnit] = useState(props ? props.unit : "");
  const [phone, setPhone] = useState(props ? props.phone : "");
  const [remark, setRemark] = useState(props ? props.remark : "");
  const [status, setStatus] = useState(props ? props.status : "normal");
  const [selectedTeams, setSelectedTeams] = useState(
    props ? props.team_id_array : [parseInt(param.id)]
  );
  const [showTeamList, setShowTeamList] = useState(false);
  const [showStatusList, setShowStatusList] = useState(false);

  const { setFalse, getShowPopup, setIsShow } = useShowPopup();

  const teamRef = useRef();
  const statusRef = useRef();
  const handleBackdropClick = (e) => {
    if (teamRef.current && !teamRef.current.contains(e.target)) {
      setShowTeamList(false);
    }
    if (statusRef.current && !statusRef.current.contains(e.target)) {
      setShowStatusList(false);
    }
  };

  useEffect(() => {
    if (props) {
      setForm((prev) => ({ ...prev, status: props.status }));
    }
  }, [props]);

  useEffect(() => {
    if (teamList.length > 0 && !props) {
      setSelectedTeams([
        {
          team_id: parseInt(param.id),
          team_name: teamList.filter(
            (item) => item.team_id === parseInt(param.id)
          )[0].name,
        },
      ]);
    }
  }, [param.id, props, teamList]);

  const handleFormChange = (e) => {
    setForm((form) => ({ ...form, [e.target.name]: e.target.value }));
  };

  const handleUnit = (e) => {
    setUnit(e.target.value);
    setForm((form) => ({ ...form, unit: e.target.value }));
    if (!props && e.target.value.length === 0) {
      delete form.unit;
    }
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
    setForm((form) => ({ ...form, phone: e.target.value }));
    if (!props && e.target.value.length === 0) {
      delete form.phone;
    }
  };

  const handleRemark = (e) => {
    setRemark(e.target.value);
    setForm((form) => ({ ...form, remark: e.target.value }));
    if (!props && e.target.value.length === 0) {
      delete form.remark;
    }
  };

  const handleShowTeamList = (e) => {
    setShowTeamList((prev) => !prev);
  };

  const handleShowStatusList = (e) => {
    setShowStatusList((prev) => !prev);
  };

  const handleTeamChange = (e) => {
    const value = parseInt(e.target.value);
    const name = e.target.name;
    const filterResult = selectedTeams.filter((item) => item.team_id === value);
    if (filterResult.length === 0) {
      setSelectedTeams((prev) => [
        ...prev,
        { team_id: value, team_name: name },
      ]);
    }
    setShowTeamList(false);
  };

  const handleDeleteTeamChange = (e) => {
    const value = parseInt(e.target.id);
    const filterResult = selectedTeams.filter((item) => item.team_id === value);
    if (filterResult.length > 0 && selectedTeams.length > 1) {
      setSelectedTeams((prev) => prev.filter((item) => item.team_id !== value));
    }
  };

  const handleStatusClick = (e) => {
    setStatus(e.target.name);
    setForm((prev) => ({ ...prev, status: e.target.name }));
    setShowStatusList(false);
  };

  const handleCancel = () => {
    setForm(
      props
        ? {
            name: props.receiver_name,
            team_id_array: props.team_id_array,
          }
        : {
            team_id: parseInt(param.id),
            name: "",
            email: "",
          }
    );
    setSelectedTeams(props ? props.team_id_array : [parseInt(param.id)]);
    setOpen(false);
  };

  const handleConfirm = () => {
    if (props) {
      if (props.team_id_array.length === 0) {
        setOpen(false);
        return;
      }
      form.team_id_array = selectedTeams.map((item) => item.team_id);
      updateReceiver(form, props.receiver_id).then((response) => {
        if (response.success === false) {
          setFalse(true, response.errorMesg);
        } else {
          setOpen(false);
          window.location.reload();
        }
      });
    } else {
      const emailRegex =
        /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,8})$/;
      if (!emailRegex.test(form.email)) {
        setFalse(true, "email格式錯誤");
      } else {
        createReceiver(form).then((response) => {
          if (response.success === false) {
            setFalse(true, response.errorMesg);
          } else {
            setOpen(false);
            window.location.reload();
          }
        });
      }
    }
  };

  if (props && props.team_id_array.length === 0) {
    return (
      <Dialog
        sx={{ "& .MuiDialog-paper": { p: 3, borderRadius: 5 } }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle
          sx={{
            fontSize: 32,
            fontWeight: "normal",
            color: "var(--color-neutral-900)",
            mb: 3,
            p: 0,
            textAlign: "center",
          }}
          id="modal-modal-title"
          component="h2"
        >
          無法編輯
        </DialogTitle>
        <DialogContent
          sx={{ p: 0, color: "var(--color-neutral-600)" }}
          onClick={handleBackdropClick}
        >
          {hasCustomizedData && hasCustomizedData === true
            ? "無法編輯個人化信件的收件人"
            : "無法編輯自行輸入的收件人"}
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            mt: 3,
          }}
        >
          <PrimaryButton
            text={"確認"}
            margin={"0px!important"}
            onClick={handleConfirm}
          />
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog
        sx={{ "& .MuiDialog-paper": { p: 3, borderRadius: 5, width: "400px" } }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle
          sx={{
            fontSize: 32,
            fontWeight: "normal",
            color: "var(--color-neutral-900)",
            mb: 3,
            p: 0,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          id="modal-modal-title"
          component="h2"
        >
          編輯聯絡人
          <button onClick={() => setOpen(false)}>
            <i className="ri-close-line"></i>
          </button>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }} onClick={handleBackdropClick}>
          <form className="flex flex-col gap-3">
            <FormControl fullWidth>
              <FormLabel sx={{ mb: 1 }}>單位</FormLabel>
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-input": {
                    px: 2,
                    py: "10px",
                    border: 1,
                    borderColor: "var(--color-neutral-400)",
                    bgcolor: "var(--color-neutral-50)",
                  },
                }}
                name="unit"
                value={unit}
                onChange={handleUnit}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel sx={{ mb: 1 }}>
                聯絡人<span className="req">*</span>
              </FormLabel>
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-input": {
                    px: 2,
                    py: "10px",
                    border: 1,
                    borderColor: "var(--color-neutral-400)",
                    bgcolor: "var(--color-neutral-50)",
                  },
                }}
                name="name"
                value={form.name}
                onChange={handleFormChange}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel sx={{ mb: 1 }}>
                E-mail<span className="req">*</span>
              </FormLabel>
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-input": {
                    px: 2,
                    py: "10px",
                    border: 1,
                    borderColor: "var(--color-neutral-400)",
                    bgcolor: "var(--color-neutral-50)",
                    ":disabled": { bgcolor: "var(--color-neutral-100)" },
                  },
                }}
                name="email"
                value={props && props.email}
                onChange={handleFormChange}
                disabled={props ? true : false}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel sx={{ mb: 1 }}>電話</FormLabel>
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-input": {
                    px: 2,
                    py: "10px",
                    border: 1,
                    borderColor: "var(--color-neutral-400)",
                    bgcolor: "var(--color-neutral-50)",
                  },
                }}
                name="phone"
                value={phone}
                onChange={handlePhone}
              />
            </FormControl>
            <FormControl fullWidth className="relative">
              <FormLabel sx={{ mb: 1 }}>所在群組</FormLabel>
              <button
                type="button"
                className="select w-full flex justify-between items-center disabled:bg-[var(--color-neutral-100)]"
                disabled={!props ? true : false}
                onClick={handleShowTeamList}
                ref={teamRef}
              >
                新增既有群組
                <i className="ri-arrow-down-s-line leading-none"></i>
              </button>
              {showTeamList && (
                <ul className="max-h-40 overflow-auto option-list top-[74px]">
                  {teamList.map((result, index) => {
                    return (
                      <li key={index}>
                        <button
                          type="button"
                          className="dropdown"
                          name={result.name}
                          value={result.team_id}
                          onClick={handleTeamChange}
                        >
                          {result.name}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              )}
              <ul className="w-full flex items-center gap-[10px] flex-wrap border-[1px] border-[var(--color-neutral-400)] rounded-[3px] min-h-[70px] mt-2 p-4">
                {selectedTeams.map((selected, index) => {
                  if (team_id_array.indexOf(selected.team_id) > -1) {
                    return (
                      <Tag
                        key={index}
                        id={selected.team_id}
                        platform={parseInt(getLocalStorage("mode"))}
                        name={selected.team_name}
                        handleFunction={handleDeleteTeamChange}
                      />
                    );
                  } else {
                    return <></>;
                  }
                })}
                {selectedTeams.map((selected, index) => {
                  if (team_id_array.indexOf(selected.team_id) === -1) {
                    return (
                      <Tag
                        key={index + selectedTeams.length}
                        id={selected.team_id}
                        platform={
                          parseInt(getLocalStorage("mode")) === 1 ? 2 : 1
                        }
                        name={selected.team_name}
                        handleFunction={handleDeleteTeamChange}
                      />
                    );
                  } else {
                    return <></>;
                  }
                })}
              </ul>
            </FormControl>
            <FormControl fullWidth>
              <FormLabel sx={{ mb: 1 }}>狀態</FormLabel>
              <div className="relative">
                <button
                  type="button"
                  className="select w-full flex justify-between items-center disabled:bg-[var(--color-neutral-100)]"
                  disabled={props ? false : true}
                  onClick={handleShowStatusList}
                  ref={statusRef}
                >
                  {status === "normal"
                    ? "正常"
                    : status === "invalid"
                    ? "信箱無效"
                    : status === "blocked"
                    ? "被封鎖"
                    : "黑名單"}
                  <i className="ri-arrow-down-s-line leading-none"></i>
                </button>
                {props && showStatusList && (
                  <ul className="option-list">
                    {statusList.map((item, index) => (
                      <li key={index}>
                        <input
                          type="button"
                          name={item.value}
                          value={item.name}
                          className="border-none w-full dropdown leading-6 cursor-pointer"
                          onClick={handleStatusClick}
                        />
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </FormControl>
            <FormControl fullWidth>
              <FormLabel sx={{ mb: 1 }}>備註</FormLabel>
              <textarea
                name="remark"
                placeholder="請填寫備註"
                className="mb-[29px] p-4 w-full border-[1px] focus:outline-none"
                value={remark}
                onChange={handleRemark}
              ></textarea>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <ThirdButton text={"取消"} onClick={handleCancel} />
          <PrimaryButton text={"確認"} onClick={handleConfirm} />
        </DialogActions>
      </Dialog>
      {getShowPopup().show && (
        <CustomAlertDialog
          show={getShowPopup().show}
          message={getShowPopup().message}
          confirmFunction={() => setIsShow(false)}
        />
      )}
    </>
  );
};
