import { useContext, useEffect, useState } from "react";
import { createTemplate, getTemplateList } from "../../../api";
import { templateContext } from "./MailContent";
import { Button } from "@mui/material";
import { VisuallyHiddenInput, readFileContent } from "../../../utils";
import CustomModal from "../../../components/CustomModal";
import { useShowPopup } from "../../../hooks";
import CustomAlertDialog from "../../../components/CustomAlertDialog";

/**
 *
 * @param {string} type
 */
export default function TemplatePop({ type }) {
  const { templateList, selectedTemplate, handleSetTemplate } =
    useContext(templateContext);
  const [uploadTemplate, setUploadTemplate] = useState();
  const [selected, setSelected] = useState(selectedTemplate);

  const { setFalse, getShowPopup, setIsShow } = useShowPopup();

  useEffect(() => {
    setSelected(selectedTemplate);
  }, [type, selectedTemplate]);

  /**
   * 設定選擇的模板
   */
  const handleClick = (e) => {
    if (selected && parseInt(e.target.name) === parseInt(selected.id)) {
      setSelected();
    } else {
      setSelected({ id: e.target.name, content: e.target.value });
    }
  };

  /**
   * 上傳模板
   */
  const handleUpload = async (e) => {
    const templateFile = e.target;
    const fileContent = await readFileContent(templateFile.files[0]).then(
      (result) => result
    );
    setUploadTemplate({
      id: 0,
      name: templateFile.files[0].name,
      content: fileContent,
    });
    setSelected({ id: 0, content: fileContent });
  };

  /**
   * 刪除上傳的檔案
   */
  const removeUpload = () => {
    setUploadTemplate();
    if (uploadTemplate.id === parseInt(selected.id)) {
      setSelected({});
    }
  };

  const handleCancel = () => {
    setSelected(selectedTemplate);
    setUploadTemplate();
  };

  const handleConfirm = () => {
    if (uploadTemplate) {
      createTemplate(uploadTemplate.name, uploadTemplate.content, type).then(
        (response) => {
          if (response.success === false) {
            if (response.tooLarge === true) {
              setFalse(true, "檔案大小超過限制");
            } else {
              setFalse(true, response.errorMesg);
            }
          } else {
            if (parseInt(selected.id) === uploadTemplate.id) {
              getTemplateList(type).then((response) => {
                if (response.success === false) {
                  setFalse(true, response.errorMesg);
                } else {
                  setSelected({
                    id: response.data[0].template_id,
                    content: response.data[0].content,
                  });
                  handleSetTemplate({
                    id: response.data[0].template_id,
                    content: response.data[0].content,
                  });
                }
              });
            }
            setUploadTemplate();
          }
        }
      );
    } else {
      handleSetTemplate(selected);
    }
  };

  return (
    <>
      <CustomModal
        className={"btn svg-btn third-btn"}
        maxWidth={392}
        buttonTitle={
          <>
            <i className="ri-pages-line"></i>模版
          </>
        }
        modalTitle={"選擇模板"}
        cancelFunction={handleCancel}
        confirmFunction={handleConfirm}
      >
        {!uploadTemplate && (
          <Button
            component="label"
            variant="contained"
            sx={{
              fontSize: 16,
              bgcolor: "transparent",
              textDecoration: "underline",
              color: "var(--color-primary-1)",
              fontWeight: "normal",
              boxShadow: "none",
              letterSpacing: 0,
              px: 2,
              py: 1.25,
              mt: 3,
              ":hover": {
                bgcolor: "transparent",
                textDecoration: "underline",
                boxShadow: "none",
              },
            }}
          >
            上傳檔案
            <VisuallyHiddenInput
              id="template-file"
              type="file"
              onChange={handleUpload}
            />
          </Button>
        )}
        <ul>
          {uploadTemplate && (
            <li
              className={
                "w-full flex items-center hover:bg-[var(--color-neutral-100)] mt-6 " +
                (selected && parseInt(selected.id) === 0
                  ? "bg-[var(--color-neutral-100)] text-[var(--color-primary-1)]"
                  : "")
              }
            >
              <button
                className="w-full truncate text-left px-4 py-[10px]"
                name={uploadTemplate.id}
                value={uploadTemplate.content}
                onClick={handleClick}
              >
                {uploadTemplate.name}
              </button>
              <button onClick={removeUpload}>
                <i className="ri-close-line px-4"></i>
              </button>
            </li>
          )}
          {templateList.map((result) => {
            return (
              <li
                key={result.template_id}
                className={
                  "w-full hover:bg-[var(--color-neutral-100)] " +
                  (selected && parseInt(selected.id) === result.template_id
                    ? "bg-[var(--color-primary-5)] text-[var(--color-primary-1)]"
                    : "")
                }
              >
                <button
                  className="w-full truncate text-left px-4 py-[10px]"
                  name={result.template_id}
                  value={result.content}
                  onClick={handleClick}
                >
                  {result.template_name}
                </button>
              </li>
            );
          })}
        </ul>
      </CustomModal>
      {getShowPopup().show && (
        <CustomAlertDialog
          show={getShowPopup().show}
          message={getShowPopup().message}
          confirmFunction={() => setIsShow(false)}
        />
      )}
    </>
  );
}
