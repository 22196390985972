import { FormatDate } from "../../utils";
import { ReceiverModal } from "../ReceiverModal";
import { openContext } from "../../context";
import { useOpen } from "../../hooks";

export default function MailDetailItem({
  index,
  style,
  data,
  list,
  hasCustomizedData,
}) {
  const clicked = Object.entries(data).filter(
    (item) => item[1].email_status === "clicked"
  );

  const { getOpen, setOpen } = useOpen();
  const open = getOpen();

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <openContext.Provider value={{ open, setOpen }}>
      <div className="table" onClick={handleOpen} style={style}>
        <span className="w-[10%] truncate mr-1">{index + 1}</span>
        <span className="w-[15%] truncate mr-1">{list.unit || "-"}</span>
        <span className="w-[15%] truncate mr-1">
          {list.receiver_name || "-"}
        </span>
        <span className="w-[30%] truncate mr-1">
          {list.receiver_email !== undefined && list.receiver_email !== null
            ? list.receiver_email
            : list.email}
        </span>
        <span className="w-[10%] truncate mr-1">
          <input
            type="checkbox"
            checked={
              clicked.length !== 0
                ? Object.values(Object.fromEntries(clicked))[0]
                    .data.map((list2) => {
                      return list2.email;
                    })
                    .toString()
                    .includes(list.email)
                : false
            }
            disabled
            name="clicked"
          />
        </span>
        <span className="w-1/5 truncate">
          {list.opened_time === null ? "-" : FormatDate(list.opened_time)}
        </span>
      </div>
      <ReceiverModal props={list} hasCustomizedData={hasCustomizedData} />
    </openContext.Provider>
  );
}
