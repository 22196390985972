import { Tab } from "../../components/Tab";
import PageWrap from "../../components/Pagewrap";
import { useLocation } from "react-router-dom";
import { useEffect, useState, useContext, createContext } from "react";
import { TabContent } from "../../components/TabContent";
import { getFolderList, getMailList } from "../../api";
import Toolbar from "./Toolbar";
import { menuContext, searchContext, selectedContext } from "../../context";
import { MailListItem } from "../../components/MailListItem";
import Folder from "../../components/Folder";
import CustomDialog from "../../components/CustomDialog";
import { Button } from "@mui/material";
import { useCheckedAll, useSearch, useShowPopup } from "../../hooks";
import CustomAlertDialog from "../../components/CustomAlertDialog";

export const folderContext = createContext();

export default function Mail() {
  const location = useLocation();
  const [folders, setFolderList] = useState([]);
  const [sended, setSendedList] = useState([]);
  const [scheduled, setScheduledList] = useState([]);
  const [draft, setDraftList] = useState([]);

  const { getSearchValue, setSearchValue } = useSearch();
  const searchValue = getSearchValue();
  const { setFalse, getShowPopup, setIsShow } = useShowPopup();
  const {
    setCheckState,
    getCheckState,
    getSelectedItems,
    setSelectedItemsState,
  } = useCheckedAll(
    location.pathname === "/mail/sended"
      ? location.search.length === 0
        ? sended.filter((item) => item.edm_file_id === null).length
        : sended.filter(
            (item) =>
              item.edm_file_id === parseInt(location.search.replace("?", ""))
          ).length
      : location.pathname === "/mail/scheduled"
      ? scheduled.length
      : draft.length
  );
  const selectedItems = getSelectedItems();

  const { setMenu } = useContext(menuContext);

  function fetchData(status) {
    getMailList(status).then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        if (status === "sended") {
          setSendedList(response.data);
        } else if (status === "scheduled") {
          setScheduledList(response.data);
        } else if (status === "draft") {
          setDraftList(response.data);
        }
      }
    });
  }

  useEffect(() => {
    setMenu({ text: ["電子報", "信件管理"], href: ["/", "/mail/sended"] });
  }, [setMenu]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData("sended");
    fetchData("scheduled");
    fetchData("draft");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/mail/sended") {
      getFolderList().then((response) => {
        if (response.success === false) {
          setFalse(true, response.errorMesg);
        } else {
          setFolderList(response.data);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleSearch = (items, status) =>
    items
      .filter(
        (item) =>
          item.title.includes(searchValue) &&
          (location.search.length === 0
            ? item.edm_file_id === null
            : item.edm_file_id === parseInt(location.search.replace("?", "")))
      )
      .map((item, index) => (
        <MailListItem key={index} status={status} {...item} />
      ));

  return (
    <PageWrap
      header={"信件管理"}
      headerChildren={
        <Button
          className="btn svg-btn"
          href="/mail/newmail"
          sx={{
            color: "var(--color-neutral-900)",
            bgcolor: "var(--color-secondary-4)",
            fontSize: 16,
            fontWeight: "normal",
            ":hover": {
              bgcolor: "var(--color-secondary-3)",
            },
          }}
        >
          <i className="ri-add-line"></i>建立新信件
        </Button>
      }
      tabs={
        <>
          <Tab
            text={`已寄送 (${sended.length})`}
            href={"sended"}
            isActive={location.pathname === "/mail/sended"}
          />
          <Tab
            text={`待寄送 (${scheduled.length})`}
            href={"scheduled"}
            isActive={location.pathname === "/mail/scheduled"}
          />
          <Tab
            text={`草稿 (${draft.length})`}
            href={"draft"}
            isActive={location.pathname === "/mail/draft"}
          />
        </>
      }
    >
      <searchContext.Provider value={{ searchValue, setSearchValue }}>
        <selectedContext.Provider
          value={{
            setCheckState,
            getCheckState,
            selectedItems,
            setSelectedItemsState,
          }}
        >
          {location.pathname === "/mail/sended" &&
            location.search.length === 0 && (
              <ul className="mt-6 w-full flex gap-6 flex-wrap">
                <li className="folder">
                  <CustomDialog
                    className="w-full !py-5 !justify-start !bg-transparent"
                    variant="contained"
                    buttonTitle={
                      <div className="flex items-center text-[var(--color-primary-1)]">
                        <i className="ri-add-line mr-4"></i>
                        新增資料夾
                      </div>
                    }
                    dialogTitle={"新增資料夾"}
                    inputProps={{
                      url: "/api/mail/management/create/edm_file",
                      method: "post",
                      props: { file_name: "" },
                    }}
                    reload={true}
                  />
                </li>
                {/* <openContext.Provider value={{}}> */}
                {folders.map((folder) => {
                  return (
                    <Folder
                      key={folder.edm_file_id}
                      id={folder.edm_file_id}
                      title={
                        folder.file_name +
                        `(${
                          sended.filter(
                            (item) => item.edm_file_id === folder.edm_file_id
                          ).length
                        })`
                      }
                    />
                  );
                })}
                {/* </openContext.Provider> */}
              </ul>
            )}
          {folders.length > 0 && location.search.length !== 0 && (
            <h3 className="mt-6">
              {
                folders.filter(
                  (item) =>
                    item.edm_file_id ===
                    parseInt(location.search.replace("?", ""))
                )[0].file_name
              }
              (
              {
                sended.filter(
                  (item) =>
                    item.edm_file_id ===
                    parseInt(location.search.replace("?", ""))
                ).length
              }
              )
            </h3>
          )}
          <folderContext.Provider value={{ folders }}>
            <Toolbar
              props={
                location.pathname === "/mail/sended"
                  ? location.search.length === 0
                    ? sended.filter((item) => item.edm_file_id === null)
                    : sended.filter(
                        (item) =>
                          item.edm_file_id ===
                          parseInt(location.search.replace("?", ""))
                      )
                  : location.pathname === "/mail/scheduled"
                  ? scheduled
                  : draft
              }
            />
          </folderContext.Provider>
          <TabContent isActive={location.pathname === "/mail/sended"}>
            <ul className="flex flex-col gap-3 mt-6">
              {handleSearch(sended, "sended")}
            </ul>
          </TabContent>
          <TabContent isActive={location.pathname === "/mail/scheduled"}>
            <ul className="flex flex-col gap-3 mt-6">
              {handleSearch(scheduled, "scheduled")}
            </ul>
          </TabContent>
          <TabContent isActive={location.pathname === "/mail/draft"}>
            <ul className="flex flex-col gap-3 mt-6">
              {handleSearch(draft, "draft")}
            </ul>
          </TabContent>
        </selectedContext.Provider>
      </searchContext.Provider>
      {getShowPopup().show && (
        <CustomAlertDialog
          show={getShowPopup().show}
          message={getShowPopup().message}
          confirmFunction={() => setIsShow(false)}
        />
      )}
    </PageWrap>
  );
}
