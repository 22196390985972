import { createContext, useEffect, useState } from "react";
import TemplateItem from "./TemplateItem";
import { createTemplate, getTemplateList } from "../../../api";
import { useLocation } from "react-router-dom";
import { readFileContent } from "../../../utils";
import { UploadButton } from "../../../components/Button";
import { useShowPopup, useSearch } from "../../../hooks";
import CustomAlertDialog from "../../../components/CustomAlertDialog";
import SearchInput from "../../../components/SearchInput";

export const templateContext = createContext();

export default function Template({ type }) {
  const [templateList, setTemplateList] = useState([]);
  const [currId, setCurrId] = useState(0);
  const { getSearchValue, setSearchValue } = useSearch();
  const { setFalse, getShowPopup, setIsShow } = useShowPopup();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    getTemplateList(type).then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        setTemplateList(response.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, type]);

  /**
   * 上傳檔案
   */
  const handleUpload = async (e) => {
    let templateFile = e.target;
    await readFileContent(templateFile.files[0]).then((result) => {
      createTemplate(
        templateFile.files[0].name,
        result,
        location.pathname.split("/")[3]
      ).then((response) => {
        if (response.success === false) {
          if (response.tooLarge === true) {
            setFalse(true, "檔案大小超過限制");
          } else {
            setFalse(true, response.errorMesg);
          }
        } else {
          window.location.reload();
        }
      });
    });
  };

  const handleSearch = (items) => {
    return items
      .filter((item) => item.template_name.includes(getSearchValue()))
      .map((result, index) => {
        return <TemplateItem key={index} props={result} />;
      });
  };

  return (
    <>
      <templateContext.Provider value={{ currId, setCurrId }}>
        <div className="flex justify-between mb-6">
          <div>
            <UploadButton
              text={
                <>
                  <i className="ri-upload-2-line"></i>上傳模板
                </>
              }
              onChange={handleUpload}
            />
            <span className="text-[var(--color-neutral-600)] ml-8">
              檔案大小上限: 5MB
            </span>
          </div>
          <SearchInput
            searchValue={getSearchValue()}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div className="h-[calc(100vh-350px)] flex justify-between gap-6">
          <ul className="w-[calc(50%-12px)] flex flex-col gap-3 overflow-y-auto">
            {handleSearch(templateList)}
          </ul>
          <div
            className={
              "w-[calc(50%-12px)] bg-[var(--color-neutral-50)] overflow-auto" +
              (currId === 0 ? " p-6" : "")
            }
            dangerouslySetInnerHTML={{
              __html:
                currId === 0
                  ? "您尚未選取檔案"
                  : templateList.filter(
                      (item) => item.template_id === currId
                    )[0].content,
            }}
          ></div>
        </div>
      </templateContext.Provider>
      {getShowPopup().show && (
        <CustomAlertDialog
          show={getShowPopup().show}
          message={getShowPopup().message}
          confirmFunction={() => setIsShow(false)}
        />
      )}
    </>
  );
}
