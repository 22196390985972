import { useParams } from "react-router-dom";
import { getMailEffectiveness } from "../../../../api";
import { useLayoutEffect, useState } from "react";
import { FormatDate, getLocalStorage } from "../../../../utils";
import { Chart } from "react-chartjs-2";
import {
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  Chart as ChartJS,
  registerables,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { LinearProgress } from "@mui/material";
import RatioItem from "./RatioItem";
import { useShowPopup } from "../../../../hooks";
import CustomAlertDialog from "../../../../components/CustomAlertDialog";

ChartJS.register(
  ...registerables,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  zoomPlugin
);

/**
 * 成效數據
 */
export default function Effectiveness() {
  const params = useParams();
  const [info, setInfo] = useState({});
  const [dateLabel, setDateLabel] = useState([]);
  const [dateData, setDateData] = useState([]);
  const [timeLabel, setTimeLabel] = useState([]);
  const [timeData, setTimeData] = useState([]);
  const { setFalse, getShowPopup, setIsShow } = useShowPopup();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    getMailEffectiveness(params.id).then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        setInfo(response.data);
        if (
          response.data.opened_date.length !== 0 &&
          response.data.opened_time.length !== 0
        ) {
          //排序日期
          let date = response.data.opened_date.sort((a, b) => {
            return new Date(Object.keys(a)) - new Date(Object.keys(b));
          });

          let labelOfDate = [];
          date.map((result) => {
            return labelOfDate.push(Object.keys(result).toString());
          });
          setDateLabel(labelOfDate);

          let dataOfDate = [];
          date.map((result) => {
            return dataOfDate.push(Object.values(result).toString());
          });
          setDateData(dataOfDate);

          //排序時間
          let time = response.data.opened_time.sort((a, b) => {
            let value1 = Object.keys(a).toString().split("~");
            let value2 = Object.keys(b).toString().split("~");
            return value1[0] - value2[0];
          });

          let labelOfTime = [];
          time.map((result) => {
            return labelOfTime.push(Object.keys(result).toString());
          });
          setTimeLabel(labelOfTime);

          let dataOfTime = [];
          time.map((result) => {
            return dataOfTime.push(Object.values(result).toString());
          });
          setTimeData(dataOfTime);
        } else {
          setDateLabel(0);
          setDateData(0);
          setTimeLabel(0);
          setTimeData(0);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  /**
   * 開信日期
   */
  const DateData = {
    labels: dateLabel,
    datasets: [
      {
        label: "開信日期",
        data: dateData,
        backgroundColor:
          getLocalStorage("mode") === "1" ? "#3297E3" : "#F5B518",
        barPercentage: 0.2,
      },
    ],
  };

  /**
   * 開信時間
   */
  const TimeData = {
    labels: timeLabel,
    datasets: [
      {
        label: "開信時間",
        // data: ["00-03","03-06","06-09","09-12","12-15","15-18","18-21","21-24"],
        data: timeData,
        backgroundColor:
          getLocalStorage("mode") === "1" ? "#3297E3" : "#F5B518",
        barPercentage: 0.2,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        position: "right",
        grid: {
          display: true,
          color: "rgba(183, 183, 183, 1)",
        },
        beginAtZero: true,
        suggestedMin: 0,
        suggestedMax: info.opened_count,
        ticks: {
          stepSize: 5,
          font: {
            size: 16,
          },
          callback: function (value, index, ticks) {
            return value + "人";
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 16,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      zoom: {
        zoom: {
          pinch: {
            enabled: false, // Enable pinch zooming
          },
          wheel: {
            enabled: true, // Enable wheel zooming
          },
          mode: "x",
        },
      },
    },
  };

  return (
    <>
      <div className="flex justify-between gap-6 my-6">
        <div className="w-full bg-[var(--color-neutral-50)] p-9">
          <p>寄送時間：{info.send_time && FormatDate(info.send_time)}</p>
          <p>寄送人數：{info.count}人</p>
          <p>寄件帳號：{info.name}</p>
        </div>
        <div className="w-full bg-[var(--color-neutral-50)] p-9 flex items-center justify-between gap-[50px]">
          <div>
            <p>第三方寄送中</p>
            <p className="text-[var(--color-neutral-400)]">
              寄送人數：{info.count}人
            </p>
          </div>
          <span className="text-[var(--color-neutral-800)] leading-none">
            <strong className="text-[64px]">{info.queued_count}</strong>人
          </span>
          <LinearProgress
            variant="determinate"
            value={100 - (info.queued_count / info.count) * 100}
            className="flex-1 max-w-[376px]"
            sx={{
              height: 10,
              bgcolor: "var(--color-neutral-200)",
              borderRadius: 2.5,
              "& .MuiLinearProgress-bar": {
                bgcolor: "var(--color-primary-1)",
                borderRadius: 2.5,
              },
            }}
          />
        </div>
      </div>
      <div className="flex flex-wrap gap-6">
        <RatioItem
          color={"blue"}
          title={"到達率"}
          count={info.arrived_count}
          totalCount={info.count}
        />
        <RatioItem
          color={"blue"}
          title={"開信率"}
          count={info.opened_count}
          totalCount={info.count}
        />
        <RatioItem
          color={"blue"}
          title={"點擊率"}
          count={info.clicked_count}
          totalCount={info.opened_count}
        />
        <RatioItem
          color={"yellow"}
          title={"無效信箱"}
          count={info.bounced_count}
          totalCount={info.count}
        />
        <RatioItem
          color={"yellow"}
          title={"封鎖率"}
          count={info.blocked_count}
          totalCount={info.count}
        />
      </div>
      <div className="flex gap-6 mt-6">
        <div className="w-[calc(50%-12px)] bg-white rounded-md p-6">
          <h5>開信日期</h5>
          <div className="mt-6">
            <Chart type="bar" data={DateData} options={options}></Chart>
          </div>
        </div>
        <div className="w-[calc(50%-12px)] bg-white rounded-md p-6">
          <h5>開信時間</h5>
          <div className="mt-6">
            <Chart type="bar" data={TimeData} options={options}></Chart>
          </div>
        </div>
      </div>
      {getShowPopup().show && (
        <CustomAlertDialog
          show={getShowPopup().show}
          message={getShowPopup().message}
          confirmFunction={() => setIsShow(false)}
        />
      )}
    </>
  );
}
