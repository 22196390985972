import { Link } from "react-router-dom";

export default function Submenu({ text, href, isActive }) {
  return (
    <li>
      <Link
        className={"block submenu " + (isActive ? "activeSubmenu" : "")}
        to={href}
      >
        <span>{text}</span>
      </Link>
    </li>
  );
}
