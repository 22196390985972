import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { PrimaryButton } from "../Button";
import { useEffect } from "react";
import { useOpen } from "../../hooks";

export default function CustomAlertDialog({ show, message, confirmFunction }) {
  const { getOpen, setOpen } = useOpen();

  useEffect(() => {
    setOpen(show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleClose = () => {
    setOpen(false);
    confirmFunction();
  };

  return (
    <Dialog
      open={getOpen()}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": { borderRadius: 5, p: 3, maxWidth: "none" },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          fontWeight: "normal",
          p: 0,
          fontSize: 32,
          lineHeight: 1.5,
        }}
        id="alert-dialog-title"
      >
        {message.title}
      </DialogTitle>
      <DialogContent
        id="alert-dialog-description"
        sx={{
          textAlign: "center",
          mt: "29px",
          p: 0,
          color: "var(--color-neutral-600)",
        }}
      >
        {message.content}
      </DialogContent>
      <DialogActions sx={{ p: 0, mt: "29px", justifyContent: "center" }}>
        <PrimaryButton text={"確認"} onClick={handleClose} margin={"0px"} />
      </DialogActions>
    </Dialog>
  );
}
