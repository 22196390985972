import { createContext } from "react";

/**
 * 導覽列麵包屑context
 */
export const menuContext = createContext();

/**
 * 搜尋欄context
 */
export const searchContext = createContext();

/**
 * 選取context
 */
export const selectedContext = createContext();

/**
 * popup設定context
 */
export const openContext = createContext();

/**
 * 群組context
 */
export const teamContext = createContext();