import { useNavigate } from "react-router-dom";
import { copyTeam, deleteTeam, getParticularTeamReceiver } from "../../../api";
import CustomDialog from "../../../components/CustomDialog";
import { useEffect, useState } from "react";
import { useShowPopup } from "../../../hooks";
import CustomAlertDialog from "../../../components/CustomAlertDialog";

export default function GroupItem({ props, showSortModal }) {
  const [length, setLength] = useState(0);
  const { setFalse, getShowPopup, setIsShow } = useShowPopup();

  const navigate = useNavigate();

  useEffect(() => {
    getParticularTeamReceiver(props.team_id).then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        setLength(response.data.length);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.team_id]);

  const handleNavigate = () => {
    if (!showSortModal) {
      navigate(`/receiverlist/grouplist/${props.team_id}`);
    }
  };

  return (
    <>
      <li className="list-shadow list-hover-bg">
        <button
          className="flex flex-1 items-center gap-4 p-6"
          onClick={handleNavigate}
        >
          <i
            className={
              (showSortModal ? "ri-menu-fill" : "ri-folder-fill") +
              " text-[var(--color-neutral-400)]"
            }
          ></i>
          {props.name}({length})
        </button>
        <div className="flex gap-9 mr-6">
          <CustomDialog
            icon={<i className="ri-pencil-line"></i>}
            dialogTitle={"重新命名"}
            inputProps={{
              url: `/api/contact/update/team/${props.team_id}`,
              method: "patch",
              props: { name: props.name },
            }}
            reload={true}
          />
          <CustomDialog
            icon={<i className="ri-file-copy-line"></i>}
            copyFuncion={() => copyTeam(props.team_id, props.name)}
            dialogTitle={"複製成功"}
            reload={true}
          />
          <CustomDialog
            icon={<i className="ri-delete-bin-7-line"></i>}
            confirmFuncion={() => deleteTeam(props.team_id)}
            dialogTitle={"確定要刪除嗎？"}
            dialogContent={
              <>
                <p className="text-center">名單將會移出此群組</p>
                <p className="text-center">而僅在此群組的名單將被刪除</p>
              </>
            }
            reload={true}
          />
        </div>
      </li>
      {getShowPopup().show && (
        <CustomAlertDialog
          show={getShowPopup().show}
          message={getShowPopup().message}
          confirmFunction={() => setIsShow(false)}
        />
      )}
    </>
  );
}
