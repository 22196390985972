import { useRoutes } from "react-router-dom";
import Mail from "../pages/Mail";
import MailInnerPage from "../pages/Mail/InnerPage";
import Detail from "../pages/Mail/InnerPage/Detail";
import Effectiveness from "../pages/Mail/InnerPage/Effectiveness";
import NewMail from "../pages/Mail/NewMail";
import File from "../pages/File";
import Template from "../pages/File/Template";
import Attachment from "../pages/File/Attachment";
import Image from "../pages/File/Image";
import ReceiverList from "../pages/ReceiverList";
import GroupList from "../pages/ReceiverList/Group";
import ImportList from "../pages/ReceiverList/ImportList";
import ReceiverInnerPage from "../pages/ReceiverList/Group/InnerPage";
import Login from "../pages/Login";
import Account from "../pages/Account";
import Content from "../pages/Mail/InnerPage/Content";
import Statistics from "../pages/Statistics";
import PersonList from "../pages/ReceiverList/PersonList";
import CustomizedMail from "../pages/File/CustomizedMail";

/**
 * useRoutes [{ path: '', element: component, children: []}]
 * path: 路徑名稱
 * element: 對應的component
 * children: 子路徑
 */
export const Routes = () => {
  let element = useRoutes([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "mail",
      element: <Mail />,
      children: [
        {
          path: "sended", // mail/sended
          element: <Mail />,
          children: [{ path: "?", element: <Mail /> }],
        },
        {
          path: "scheduled",
          element: <Mail />,
        },
        {
          path: "draft",
          element: <Mail />,
        },
      ],
    },
    {
      path: "mail/:id",
      element: <MailInnerPage />,
      children: [
        {
          path: "detail",
          element: <Detail />,
        },
        {
          path: "effectiveness",
          element: <Effectiveness />,
        },
        {
          path: "content",
          element: <Content />,
        },
      ],
    },
    {
      path: "mail/newmail",
      element: <NewMail />,
    },
    {
      path: "file",
      element: <File />,
      children: [
        {
          path: "template/text",
          element: <Template />,
        },
        {
          path: "template/editor",
          element: <Template />,
        },
        {
          path: "attachment",
          element: <Attachment />,
        },
        {
          path: "image",
          element: <Image />,
        },
        {
          path: "customizedmail",
          element: <CustomizedMail />,
        },
      ],
    },
    {
      path: "receiverlist",
      element: <ReceiverList />,
      children: [
        {
          path: "grouplist",
          element: <GroupList />,
          children: [
            {
              path: ":id",
              element: <ReceiverInnerPage />,
            },
          ],
        },
        { path: "personlist", element: <PersonList /> },
        {
          path: "importlist",
          element: <ImportList />,
        },
      ],
    },
    {
      path: "statistics",
      element: <Statistics />,
    },
    {
      path: "account",
      element: <Account />,
    },
  ]);

  return element;
};
