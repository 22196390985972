import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  baseUrl,
  createReceiversByFile,
  createTeam,
  getCreateReceiversExample,
} from "../../../api";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { teamContext } from "../../../context";
import ImportFailed from "../../../components/ImportFailed";
import {
  PrimaryButton,
  SecondaryButton,
  ThirdButton,
} from "../../../components/Button";
import CustomAlertDialog from "../../../components/CustomAlertDialog";
import { useShowPopup } from "../../../hooks";

export default function ImportList() {
  const [groupMode, setGroupMode] = useState("");
  const [value, setValue] = useState("選擇群組");
  const [name, setName] = useState("");
  const [id, setId] = useState();
  const [uploadFile, setUploadFile] = useState();
  const [loading, setLoading] = useState(false);
  const [showTeamList, setShowTeamList] = useState(false);
  const [failedData, setFailedData] = useState();

  const { setFalse, setSuccess, getShowPopup, setIsShow } = useShowPopup();

  const { teamList } = useContext(teamContext);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * 範例下載
   */
  const handleDownload = () => {
    getCreateReceiversExample().then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        let a = document.createElement("a");
        a.href = baseUrl + response.data.url;
        a.download = "result";
        a.click();
      }
    });
  };

  /**
   * 上傳檔案
   */
  const handleUpload = (e) => {
    setUploadFile(e.target.files[0]);
  };

  /**
   * 選擇既有群組或新增群組
   */
  const handleGroupModeChange = (e) => {
    setGroupMode(e.target.value);
    setValue("選擇群組");
    setName("");
  };

  const handleSetName = (e) => {
    setName(e.target.value);
  };

  /**
   * 既有群組選擇群組
   */
  const handleClick = (e) => {
    setValue(e.target.value);
    setId(parseInt(e.target.name));
    setShowTeamList(false);
  };

  const handleShowTeamList = () => {
    setShowTeamList((prev) => !prev);
  };

  /**
   * 點擊取消按鈕將重設所有資料
   */
  const handleCancel = () => {
    setUploadFile();
    setGroupMode("");
    setName("");
    setValue("選擇群組");
    setId();
  };

  /**
   * 匯入按鈕執行動作
   */
  const handleConfirm = () => {
    setLoading(true);
    if (uploadFile && (id || name.length > 0)) {
      let team_id = id;
      if (groupMode === "create") {
        const result = teamList.filter((item) => item.name === name);
        if (!result.length > 0) {
          createTeam(name).then((response) => {
            if (response.success === false) {
              setFalse(true, response.errorMesg);
            } else {
              setId(response.data.team_id);
              team_id = response.data.team_id;
            }
          });
          let formdata = new FormData();
          formdata.append("team_id", JSON.stringify([team_id]));
          formdata.append("file", uploadFile);
          handleCreate(formdata);
        } else {
          setFalse(true, "群組名稱已重複，請更改群組名稱");
          setLoading(false);
        }
      } else {
        let formdata = new FormData();
        formdata.append("team_id", JSON.stringify([team_id]));
        formdata.append("file", uploadFile);
        handleCreate(formdata);
      }
    } else {
      setSuccess(true, "請填寫完整", "檔案及群組不得為空");
      setLoading(false);
    }
  };

  /**
   * 新增聯絡人
   */
  const handleCreate = (formdata) => {
    createReceiversByFile(formdata).then((response) => {
      document.querySelector(".progress-container").classList.remove("hidden");
      setLoading(false);
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        setTimeout(() => {
          setFailedData((prev) => ({
            ...prev,
            totalLength:
              response.data.success_count + response.data.failed_emails.length,
            success_count: response.data.success_count,
            failedData: response.data.failed_emails,
          }));
        }, 3000);
      }
    });
  };

  /**
   * 回到名單
   */
  const handleBack = () => {
    setFailedData();
    navigate("/receiverlist/grouplist");
  };

  /**
   * 重新上傳
   */
  const handleReupload = () => {
    setFailedData();
    setUploadFile();
    setGroupMode("");
    setName("");
    setValue("選擇群組");
    setId();
  };

  if (failedData) {
    return (
      <ImportFailed
        type={"receiver"}
        data={failedData}
        handleBack={handleBack}
        handleReupload={handleReupload}
      />
    );
  }

  return (
    <>
      <div className="bg-white">
        <form className="p-9 flex flex-col gap-4 w-[850px]">
          <label className="mb-0">
            上傳 .xlsx 檔案<span className="req">*</span>
          </label>
          <div className="flex items-center">
            <input
              id="csv-file"
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              required
              className="flex-1 border-[1px] border-[var(--color-neutral-400)]"
              onChange={handleUpload}
            />
            <SecondaryButton
              text={"範例下載"}
              classname="btn third-btn !ml-4"
              onClick={handleDownload}
            />
          </div>
          <FormControl>
            <FormLabel
              id="demo-row-radio-buttons-group-label"
              className="!text-[var(--color-neutral-900)]"
            >
              加入群組<span className="req">*</span>
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              sx={{ gap: 4.5 }}
              value={groupMode}
              onChange={handleGroupModeChange}
            >
              <FormControlLabel
                value="origin"
                control={<Radio sx={{ mr: 1 }} size="small" />}
                label="既有群組"
                sx={{ m: 0, "& .MuiRadio-root": { p: 0 } }}
              />
              <FormControlLabel
                value="create"
                control={<Radio sx={{ mr: 1 }} size="small" />}
                label="新增群組"
                sx={{ m: 0, "& .MuiRadio-root": { p: 0 } }}
              />
            </RadioGroup>
            {groupMode === "origin" && (
              <div className="relative">
                <button
                  type="button"
                  className="select w-full flex justify-between items-center h-9 mt-4"
                  onClick={handleShowTeamList}
                >
                  {value}
                  <i className="ri-arrow-down-s-line"></i>
                </button>
                <ul
                  className={
                    "w-full option-list max-h-40 overflow-auto " +
                    (!showTeamList ? "hidden" : "")
                  }
                >
                  {teamList.map((result, index) => {
                    return (
                      <li key={index}>
                        <button
                          type="button"
                          name={result.team_id}
                          value={result.name}
                          onClick={handleClick}
                          className="border-none dropdown"
                        >
                          {result.name}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {groupMode === "create" && (
              <TextField
                placeholder="請填寫群組名稱"
                sx={{
                  mt: 2,
                  "& .MuiInputBase-input": { py: 0.75, px: 2, height: 24 },
                }}
                value={name}
                onChange={handleSetName}
              />
            )}
          </FormControl>
          <div>
            <PrimaryButton
              text={"匯入"}
              margin={"0px!important"}
              disabled={loading}
              onClick={handleConfirm}
            />
            <ThirdButton
              text={"取消"}
              margin={"16px!important"}
              onClick={handleCancel}
            />
          </div>
          <div className="progress-container hidden">
            <div className="progress progress-animate"></div>
          </div>
        </form>
      </div>
      {getShowPopup().show && (
        <CustomAlertDialog
          show={getShowPopup().show}
          message={getShowPopup().message}
          confirmFunction={() => setIsShow(false)}
        />
      )}
    </>
  );
}
