import GroupItem from "./GroupItem";
import { useContext, useEffect, useState } from "react";
import { getLocalStorage, setTeamPosition } from "../../../utils";
import { teamContext } from "../../../context";
import { useParams } from "react-router-dom";
import CustomDialog from "../../../components/CustomDialog";
import { ReactSortable } from "react-sortablejs";
import ReceiverInnerPage from "./InnerPage";
import { Button } from "@mui/material";

export default function GroupList() {
  const [position, setPosition] = useState([]);
  const [showSortModal, setShowSortModal] = useState(false);

  const { teamList } = useContext(teamContext);

  const param = useParams();

  const localstorage = getLocalStorage("team_position");

  useEffect(() => {
    if (localstorage !== "") {
      let data = [];
      localstorage.split("/").forEach((item) => {
        data.push(JSON.parse(item));
      });
      //假如localstorage有排序過的team，就將teamList有在裡面的按其排序
      //沒排序過的team就放最上面
      const set = new Set();
      let filterIn = [];
      //有排序過
      data.forEach((data) =>
        teamList.forEach((team) => {
          if (data.team_id === team.team_id && !set.has(data.team_id)) {
            set.add(data.team_id);
            filterIn.push(data);
          }
        })
      );
      //檢查是否有修改名稱
      filterIn.forEach((item) => {
        teamList.forEach((team) => {
          if (item.team_id === team.team_id) {
            item.name = team.name;
          }
        });
      });
      //沒排序過
      const filterOut = teamList.filter((team) =>
        !set.has(team.team_id) ? set.add(team.team_id) : false
      );
      setPosition(filterOut.concat(filterIn));
    } else {
      setPosition(teamList);
    }
  }, [teamList, localstorage]);

  //點擊排序群組及完成排序執行動作
  const handleClick = () => {
    let data = "";
    position.forEach((item, index) => {
      data += JSON.stringify(item) + (index < teamList.length - 1 ? "/" : "");
    });
    setTeamPosition(data);
    setShowSortModal(!showSortModal);
  };

  return (
    <>
      {!param.id ? (
        <>
          <CustomDialog
            className="btn svg-btn !mb-6 !mr-6 !bg-[var(--color-secondary-4)] hover:!bg-[var(--color-secondary-3)]"
            variant="contained"
            buttonTitle={
              <>
                <i className="ri-add-line"></i>新增群組
              </>
            }
            dialogTitle={"新增群組"}
            inputProps={{
              url: "/api/contact/create/team",
              method: "post",
              props: { name: "" },
            }}
            reload={true}
          />
          <Button
            sx={{
              color: "text-[var(--color-neutral-900)]",
              fontWeight: "normal",
              fontSize: 16,
            }}
            className={
              "btn svg-btn third-btn !mb-6 " +
              (showSortModal && "absolute z-50")
            }
            onClick={handleClick}
          >
            <i className="ri-sort-asc"></i>
            {!showSortModal ? "排序群組" : "完成"}
          </Button>
          {showSortModal ? (
            <ReactSortable
              tag={"ul"}
              className={
                "flex flex-col gap-3 " + (showSortModal && "relative z-50")
              }
              list={position}
              setList={setPosition}
            >
              {position.map((item, index) => (
                <li id={item.team_id} key={index}>
                  <GroupItem props={item} showSortModal={showSortModal} />
                </li>
              ))}
            </ReactSortable>
          ) : (
            <ul
              className={
                "flex flex-col gap-3 " + (showSortModal && "relative z-10")
              }
            >
              {position.map((result, index) => {
                return (
                  <GroupItem
                    key={index}
                    props={result}
                    showSortModal={showSortModal}
                  />
                );
              })}
            </ul>
          )}
          {showSortModal && (
            <div className="w-full h-full bg-black bg-opacity-20 fixed left-0 top-0 z-40"></div>
          )}
        </>
      ) : (
        <ReceiverInnerPage />
      )}
    </>
  );
}
