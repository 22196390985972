import { useContext } from "react";
import { ReceiverModal } from "../../components/ReceiverModal";
import { selectedContext, openContext } from "../../context";
import { Checkbox } from "@mui/material";
import { useOpen } from "../../hooks";

/**
 * 群組內頁、所有名單component
 */
export default function PersonItem({ style, form }) {
  const { getOpen, setOpen } = useOpen();
  const open = getOpen();
  const { selectedItems, setSelectedItemsState } = useContext(selectedContext);

  const checkboxHandler = (e) => {
    let isSelected = e.target.checked;
    let value = parseInt(e.target.value);
    setSelectedItemsState(
      isSelected,
      [...selectedItems, value],
      selectedItems.filter((id) => id !== value)
    );
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <openContext.Provider value={{ open, setOpen }}>
        <div className="table" style={style} onClick={handleOpen}>
          <span className="w-[10%] truncate mr-1">
            <Checkbox
              name="receiver-list"
              value={form.receiver_id}
              checked={selectedItems.includes(form.receiver_id)}
              onChange={checkboxHandler}
              size="sm"
            />
          </span>
          <span className="w-[15%] truncate mr-1">{form.unit}</span>
          <span className="w-[20%] truncate mr-1">{form.receiver_name}</span>
          <span className="w-[30%] truncate mr-1">{form.email}</span>
          <span className="w-[15%] truncate mr-1">{form.phone}</span>
          <span className="w-[10%] truncate">
            {form.status === "normal"
              ? "正常"
              : form.status === "invalid"
              ? "無效信箱"
              : form.status === "blocked"
              ? "被封鎖"
              : "黑名單"}
          </span>
        </div>
        <ReceiverModal props={form} />
      </openContext.Provider>
    </>
  );
}
