import { useContext } from "react";
import { imgContext } from ".";
import { baseUrl } from "../../../api";
import { selectedContext } from "../../../context";

export default function ImageItem({ props }) {
  const { setCurrId } = useContext(imgContext);
  const { selectedItems, setSelectedItems } = useContext(selectedContext);

  /**
   * 選取圖片
   */
  const checkboxHandler = (e) => {
    let isSelected = e.target.checked;
    let value = parseInt(e.target.value);

    if (isSelected) {
      setSelectedItems([...selectedItems, value]);
      setCurrId(value);
    } else {
      setSelectedItems((prevData) => {
        return prevData.filter((id) => {
          return id !== value;
        });
      });
      const newArr = selectedItems.filter((item) => item !== value);
      setCurrId(newArr[newArr.length - 1] || 0);
    }
  };

  return (
    <li>
      <label className="img-label w-[236px] h-[236px] border-4 border-white m-0 hover:shadow-lg overflow-hidden">
        <input
          type="checkbox"
          value={props.image_id}
          className="invisible absolute top-0"
          checked={selectedItems.includes(props.image_id)}
          onChange={checkboxHandler}
        />
        <img
          src={baseUrl + props.url}
          alt={props.image_name}
          className="h-[236px] object-cover object-center"
        />
      </label>
    </li>
  );
}
