export default function SearchInput({ searchValue, onChange }) {
  return (
    <div className="w-[236px] h-9 bg-[var(--color-neutral-50)] rounded-[5px] border-[1px] border-[var(--color-neutral-400)] flex items-center">
      <i className="ri-search-line pl-[3px] pr-2 text-[var(--color-neutral-600)]"></i>
      <input
        type="search"
        placeholder="Search..."
        className="text-base w-full border-0 p-0 focus:outline-0"
        value={searchValue}
        onChange={onChange}
      />
    </div>
  );
}
