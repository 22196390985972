/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import ContactList from "./ContactList";
import { useLocation, useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import TemplatePop from "./TemplatePop";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { getLocalStorage } from "../../../utils";
import {
  createMail,
  deleteMails,
  getAttachmentList,
  getCustomizedMail,
  getParticularMail,
  getTemplateList,
  updateMail,
} from "../../../api";
import Editor from "ckeditor5-custom-build";
import AttachmentPop from "./AttachmentPop";
import CustomDialog from "../../../components/CustomDialog";
import CustomizedMailPop from "./CustomizedMailPop";
import { useOpen, useShowPopup } from "../../../hooks";
import CustomAlertDialog from "../../../components/CustomAlertDialog";
import { ThirdButton } from "../../../components/Button";

export const templateContext = createContext();
export const attachmentContext = createContext();
export const receiverContext = createContext();
export const customizedMailContext = createContext();

export default function MailContent() {
  const [form, setForm] = useState({
    receiver: [],
    sender: getLocalStorage("mode") === "1" ? "E-port" : "Lucker",
    sender_email:
      getLocalStorage("mode") === "1"
        ? "eport_info@eporttw.com"
        : "info1@luckertw.com",
    title: "",
    content: "",
    status: "schedule",
    send_time: "",
    contentMode: "textMode",
  });
  const [remark, setRemark] = useState("");
  const [previewData, setPreviewData] = useState("");

  const [sendtimeMode, setSendtimeMode] = useState("");

  const [diff, setDiff] = useState(false);

  const [modeChangeModalOpen, setModeChangeModalOpen] = useState(false);

  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [selectedReceivers, setSelectedReceivers] = useState([]);
  const [selectedCustomizedMail, setSelectedCustomizedMail] = useState({});

  const [templateList, setTemplateList] = useState([]);
  const [customizedList, setCustomizedList] = useState([]);

  const [showCustomizedVariable, setShowCustomizedVariable] = useState(false);

  const { setFalse, getShowPopup, setIsShow } = useShowPopup();
  const { getOpen, setOpen } = useOpen();

  const ref = useRef();
  const handleBackdropClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setShowCustomizedVariable(false);
    }
  };

  const location = useLocation();
  const navigate = useNavigate();

  const id =
    location.search.length > 0 ? location.search.replace("?", "") : null;

  useEffect(() => {
    getTemplateList(form.contentMode.replace("Mode", "")).then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        setTemplateList(response.data);
      }
    });
  }, [form.contentMode]);

  useEffect(() => {
    const ids = selectedAttachments.map((item) => item.id);
    const names = selectedAttachments.map((item) => item.name);
    getAttachmentList().then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        // setAttachmentList(response.data);
        const result = response.data.filter(
          (item) => names.indexOf(item.attachment_name) > -1
        );
        if (
          result.filter((item) => ids.indexOf(item.attachment_id) === -1)
            .length > 0
        ) {
          setSelectedAttachments(
            result.map((item) => {
              return { id: item.attachment_id, name: item.attachment_name };
            })
          );
          setForm((prev) => ({
            ...prev,
            attachments: result.map((item) => item.attachment_id),
          }));
        }
      }
    });
  }, [selectedAttachments]);

  useEffect(() => {
    getCustomizedMail().then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        setCustomizedList(response.data);
      }
    });
  }, []);

  useLayoutEffect(() => {
    setSelectedReceivers([]);
    window.scrollTo(0, 0);
    if (id !== null) {
      getParticularMail(id).then((response) => {
        if (response.success === false) {
          setFalse(true, response.errorMesg);
        } else {
          for (const key in response.data) {
            if (Array.isArray(response.data[key])) {
              if (key === "attachment_id_array") {
                getAttachmentList().then((response2) => {
                  if (response2.success === false) {
                    setFalse(true, response2.errorMesg);
                  } else {
                    let result = [];
                    response2.data.forEach((item) => {
                      response.data[key].forEach((item2) => {
                        if (
                          item.attachment_name === item2.name &&
                          result.indexOf(item.attachment_id) === -1
                        ) {
                          result.push({
                            id: item.attachment_id,
                            name: item.attachment_name + "." + item2.ext,
                          });
                        }
                      });
                    });
                    if (result.length > 0) {
                      setSelectedAttachments(result);
                      setForm((prev) => ({
                        ...prev,
                        attachments: result.map((item) => item.id),
                      }));
                    }
                  }
                });
              } else if (key === "receiver" && response.data[key][0]) {
                if (response.data[key][0].email !== null) {
                  setForm((prev) => ({
                    ...prev,
                    receiver: response.data[key].map((result) => {
                      if (Object.keys(result) === "email") {
                        return Object.values(result).toString();
                      } else {
                        return Object.values(result)[0];
                      }
                    }),
                  }));
                } else {
                  setForm((prev) => ({ ...prev, receiver: null }));
                }
              }
            } else if (response.data[key] !== null) {
              if (key === "remark") {
                setRemark(response.data[key]);
              } else if (key === "template_id") {
                setSelectedTemplate({
                  id: response.data[key],
                  content: response.data["content"],
                });
                getTemplateList("text").then((response2) => {
                  if (response2.success === false) {
                    setFalse(true, response2.errorMesg);
                  } else {
                    setTemplateList(response2.data);
                    const result = response2.data.filter(
                      (item) => item.template_id === response.data[key]
                    );
                    if (result.length > 0) {
                      setForm((prev) => ({ ...prev, contentMode: "textMode" }));
                    } else {
                      setForm((prev) => ({
                        ...prev,
                        contentMode: "editorMode",
                      }));
                    }
                  }
                });
              } else if (key === "customized_data_id") {
                getCustomizedMail().then((response2) => {
                  if (response2.success === false) {
                    setFalse(true, response2.errorMesg);
                  } else {
                    setCustomizedList(response2.data);
                    const result = response2.data.filter(
                      (item) => item.customized_data_id === response.data[key]
                    );
                    if (result.length > 0) {
                      setSelectedCustomizedMail({
                        id: response.data[key],
                        headers: result[0].headers,
                      });
                      setForm((prev) => ({ ...prev, receiver: null }));
                      setForm((prev) => ({
                        ...prev,
                        [key]: response.data[key],
                      }));
                    }
                  }
                });
              } else if (key === "content") {
                if (response.data[key].includes("editorMode")) {
                  setForm((prev) => ({ ...prev, contentMode: "editorMode" }));
                  setForm((prev) => ({
                    ...prev,
                    content: response.data[key].replace(
                      "<div class='editorMode'></div>",
                      ""
                    ),
                  }));
                } else {
                  setForm((prev) => ({ ...prev, contentMode: "textMode" }));
                  setForm((prev) => ({
                    ...prev,
                    content: response.data[key].replace(
                      "<div class='textMode'></div>",
                      ""
                    ),
                  }));
                }
              } else if (key === "send_time") {
                const now = new Date();
                const formatSendTime = new Date(response.data[key]);
                if (formatSendTime > now) {
                  setSendtimeMode("ordertime");
                }
                setForm((prev) => ({ ...prev, [key]: response.data[key] }));
              } else {
                setForm((prev) => ({ ...prev, [key]: response.data[key] }));
              }
            }
          }
        }
      });
    }
    setForm((prev) => ({ ...prev, status: "schedule" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  /**
   * 判斷個人化信件的變數與信件內容是否有差異
   */
  useEffect(() => {
    if (selectedCustomizedMail.headers) {
      for (let i = 0; i < form.content.length; i++) {
        if (form.content.includes("{{var:", i)) {
          if (form.content.includes("}}", i)) {
            let startStr = form.content.indexOf("{{var:", i) + 6;
            let endStr = form.content.indexOf("}}", startStr);
            if (
              startStr !== -1 &&
              endStr !== -1 &&
              selectedCustomizedMail.headers.indexOf(
                form.content.substring(startStr, endStr)
              ) === -1
            ) {
              setDiff(true);
              return;
            } else {
              setDiff(false);
            }
          }
        }
      }
    }
  }, [form.content, selectedCustomizedMail.headers]);

  const handleFormChange = (e) => {
    setForm((form) => ({ ...form, [e.target.name]: e.target.value }));
  };

  const handleSetReceivers = (value) => {
    setSelectedReceivers(value);
    setForm((prev) => ({ ...prev, receiver: value }));
  };

  const handleSetCustomizedMail = (value) => {
    if (value) {
      setSelectedCustomizedMail({
        id: parseInt(value.id),
        headers: value.headers,
      });
      setForm((prev) => ({
        ...prev,
        customized_data_id: parseInt(value.id),
        receiver: null,
      }));
    } else {
      setSelectedCustomizedMail({});
      setForm((prev) => ({
        ...prev,
        receiver: [],
      }));
      delete form.customized_data_id;
    }
  };

  /**
   * 純文字模式和編輯器模式轉換
   */
  const handleModeChange = () => {
    setModeChangeModalOpen(true);
  };

  const handleSetTemplate = (value) => {
    if (value) {
      setSelectedTemplate({ id: parseInt(value.id), content: value.content });
      setForm((prev) => ({
        ...prev,
        template_id: parseInt(value.id),
        content: value.content,
      }));
    } else {
      setSelectedTemplate({});
      setForm((prev) => ({ ...prev, content: "" }));
      delete form.template_id;
    }
  };

  /**
   * 顯示新增個人化變數列表
   */
  const handleShowCustomizedVariable = () => {
    setShowCustomizedVariable((prev) => !prev);
  };

  /**
   * 將個人化變數新增到content
   */
  const handleAddCustomizedVariable = (e) => {
    setForm((prev) => ({
      ...prev,
      content: prev.content + "{{var:" + e.target.innerText + "}}",
    }));
    setShowCustomizedVariable(false);
  };

  const handleSetAttachment = (value) => {
    setSelectedAttachments(value);
    if (value.length === 0) {
      delete form.attachments;
    } else {
      setForm((prev) => ({
        ...prev,
        attachments: value.map((item) => item.id),
      }));
    }
  };

  /**
   * 轉換編輯器模式時出現popup提示
   */
  const handleModeChangeModalClose = (e) => {
    setModeChangeModalOpen(!modeChangeModalOpen);
    if (e.target.innerText === "確認") {
      if (form.contentMode === "textMode") {
        setForm((prev) => ({ ...prev, contentMode: "editorMode" }));
      } else {
        setForm((prev) => ({ ...prev, contentMode: "textMode" }));
      }
      setForm((prev) => ({ ...prev, content: "" }));

      delete form.template_id;
      setSelectedTemplate({});
    }
  };

  /**
   * 寄送時間選項
   */
  const handleModeSendTimeChange = (e) => {
    const value = e.target.value;
    setSendtimeMode(value);
    if (value === "now") {
      setForm((prev) => ({ ...prev, send_time: value }));
    }
  };

  const handleRemarkChange = (e) => {
    setRemark(e.target.value);
    if (e.target.value.length > 0) {
      setForm((prev) => ({ ...prev, remark: e.target.value }));
    } else {
      delete form.remark;
    }
  };

  //暫存按鈕執行動作
  const handleStore = () => {
    form.status = "draft";
    if (id === null) {
      createMail(form).then((response) => {
        if (response.success === false) {
          setFalse(true, response.errorMesg);
        } else {
          if (response.data.failed_email_array.length > 0) {
            setFalse(
              true,
              <>
                <p className="text-center">
                  正確名單已暫存成功或寄出，<br></br>失敗名單如下：
                </p>
                <ul>
                  {response.data.failed_email_array.map((item) => (
                    <li className="text-center">{item}</li>
                  ))}
                </ul>
              </>,
              "/mail/draft"
            );
          } else {
            navigate("/mail/draft");
          }
        }
      });
    } else {
      updateMail(form, id).then((response) => {
        if (response.success === false) {
          setFalse(true, response.errorMesg);
        } else {
          if (response.data.failed_email_array.length > 0) {
            setFalse(
              true,
              <>
                <p className="text-center">
                  正確名單已暫存成功或寄出，<br></br>失敗名單如下：
                </p>
                <ul>
                  {response.data.failed_email_array.map((item) => (
                    <li className="text-center">{item}</li>
                  ))}
                </ul>
              </>,
              "/mail/draft"
            );
          } else {
            navigate("/mail/draft");
          }
        }
      });
    }
  };

  //刪除按鈕執行動作
  const handleDelete = () => {
    if (id === null) {
      navigate(-1);
    } else {
      deleteMails([id]).then((response) => {
        if (response.success === false) {
          setFalse(true, response.errorMesg);
        } else {
          navigate("/mail/draft");
        }
      });
    }
  };

  //預覽按鈕執行動作
  const handleOpen = async () => {
    if (selectedCustomizedMail.id) {
      const id = customizedList.filter(
        (item) => item.customized_data_id === selectedCustomizedMail.id
      );
      if (id.length > 0) {
        const firstData = id[0].first_data;
        setPreviewData(form.content);
        for (const key in firstData) {
          setPreviewData((prev) =>
            prev.replaceAll(`{{var:${key}}}`, firstData[key])
          );
        }
      }
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <receiverContext.Provider value={{ selectedReceivers, handleSetReceivers }}>
      <attachmentContext.Provider
        value={{ selectedAttachments, handleSetAttachment }}
      >
        <templateContext.Provider
          value={{ templateList, selectedTemplate, handleSetTemplate }}
        >
          <customizedMailContext.Provider
            value={{
              customizedList,
              selectedCustomizedMail,
              handleSetCustomizedMail,
            }}
          >
            <div
              className="bg-[var(--color-neutral-50)] p-9 relative"
              onClick={handleBackdropClick}
            >
              <form className="flex flex-col w-[850px] gap-6">
                <div className="flex gap-6">
                  <FormControl className="w-2/5">
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className="!text-[var(--color-neutral-900)]"
                    >
                      寄件人
                    </FormLabel>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      disabled
                      sx={{
                        "& .MuiOutlinedInput-input": {
                          px: 2,
                          py: "10px",
                          border: 1,
                          borderColor: "var(--color-neutral-400)",
                          bgcolor: "var(--color-neutral-100)",
                          ":disabled": {
                            WebkitTextFillColor: "var(--color-neutral-600)",
                          },
                        },
                      }}
                      name="sender"
                      value={form.sender}
                      onChange={handleFormChange}
                    />
                  </FormControl>
                  <FormControl className="w-3/5">
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className="!text-[var(--color-neutral-900)]"
                    >
                      寄件人 E-mail
                    </FormLabel>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      disabled
                      sx={{
                        "& .MuiOutlinedInput-input": {
                          px: 2,
                          py: "10px",
                          border: 1,
                          borderColor: "var(--color-neutral-400)",
                          bgcolor: "var(--color-neutral-100)",
                          ":disabled": {
                            WebkitTextFillColor: "var(--color-neutral-600)",
                          },
                        },
                      }}
                      name="email"
                      value={form.sender_email}
                      onChange={handleFormChange}
                    />
                  </FormControl>
                </div>
                <FormControl className="w-full">
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    className="!text-[var(--color-neutral-900)]"
                  >
                    收件人<span className="req">*</span>
                  </FormLabel>
                  <div className="flex">
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      sx={{
                        "& .MuiOutlinedInput-input": {
                          px: 2,
                          py: "10px",
                          border: 1,
                          borderColor: "var(--color-neutral-400)",
                          ":disabled": {
                            borderColor: "var(--color-neutral-400)",
                            bgcolor: "var(--color-neutral-100)",
                          },
                        },
                      }}
                      className="flex-1"
                      name="receiver"
                      value={
                        !form.customized_data_id
                          ? form.receiver
                          : "已使用個人化信件的內部名單"
                      }
                      onChange={handleFormChange}
                      disabled={form.customized_data_id}
                    />
                    <ContactList />
                    <CustomizedMailPop />
                  </div>
                </FormControl>
                <FormControl className="w-full">
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    className="!text-[var(--color-neutral-900)]"
                  >
                    主旨<span className="req">*</span>
                  </FormLabel>
                  <TextField
                    type="text"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        px: 2,
                        py: "10px",
                        border: 1,
                        borderColor: "var(--color-neutral-400)",
                      },
                    }}
                    name="title"
                    value={form.title}
                    onChange={handleFormChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    className="!text-[var(--color-neutral-900)]"
                  >
                    內容<span className="req">*</span>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue={"textMode"}
                    sx={{ gap: 2 }}
                    value={form.contentMode}
                    onChange={handleModeChange}
                  >
                    <FormControlLabel
                      name="contentMode"
                      value="textMode"
                      control={<Radio sx={{ mr: 1 }} />}
                      label="HTML模式"
                      sx={{ m: 0, "& .MuiRadio-root": { p: 0 } }}
                    />
                    <FormControlLabel
                      name="contentMode"
                      value="editorMode"
                      control={<Radio sx={{ mr: 1 }} />}
                      label="編輯器模式"
                      sx={{ m: 0, "& .MuiRadio-root": { p: 0 } }}
                    />
                  </RadioGroup>
                  <Dialog
                    sx={{
                      "& .MuiDialog-paper": { borderRadius: 5, p: 3 },
                    }}
                    open={modeChangeModalOpen}
                    onClose={handleModeChangeModalClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle
                      sx={{
                        fontWeight: "normal",
                        p: 0,
                        fontSize: 32,
                        lineHeight: 1.5,
                      }}
                      id="alert-dialog-title"
                    >
                      確定要變更模式嗎？
                    </DialogTitle>
                    <DialogContent sx={{ p: 0 }}>
                      <DialogContentText
                        id="alert-dialog-description"
                        sx={{ textAlign: "center", mt: "29px" }}
                      >
                        已編輯的內容將會空白
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      sx={{
                        p: 0,
                        mt: "29px",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={handleModeChangeModalClose}
                        sx={{
                          fontWeight: "normal",
                          borderRadius: "6px",
                          color: "var(--color-primary-1)",
                          borderColor: "var(--color-primary-1)",
                          px: 6,
                          py: 1,
                          fontSize: "16px",
                          ":hover": {
                            bgcolor: "var(--color-primary-5)",
                            borderColor: "var(--color-primary-2)",
                          },
                        }}
                      >
                        取消
                      </Button>
                      <Button
                        sx={{
                          fontWeight: "normal",
                          borderRadius: "6px",
                          color: "var(--color-neutral-50)",
                          bgcolor: "var(--color-primary-1)",
                          px: 6,
                          py: 1,
                          ml: 1,
                          fontSize: "16px",
                          ":hover": {
                            bgcolor: "var(--color-primary-2)",
                          },
                        }}
                        variant="contained"
                        onClick={handleModeChangeModalClose}
                        autoFocus
                      >
                        確認
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <div className="flex items-center my-4">
                    <TemplatePop
                      type={form.contentMode === "textMode" ? "text" : "editor"}
                    />
                    <CustomDialog
                      className="svg-btn third-btn !ml-4"
                      variant="contained"
                      buttonTitle={
                        <>
                          <i className="ri-save-line"></i>
                          儲存新模板
                        </>
                      }
                      dialogTitle={"模板名稱"}
                      inputProps={{
                        url: "/api/file/create/template",
                        method: "post",
                        props: {
                          content: form.content,
                          type:
                            form.contentMode === "textMode" ? "text" : "editor",
                          name: "",
                        },
                      }}
                      showChildrenPopup={true}
                    />
                    {selectedCustomizedMail.headers && (
                      <div
                        className="relative ml-4 border-[1px] border-[var(--color-neutral-400)] rounded-[3px]"
                        ref={ref}
                      >
                        <button
                          className="w-full flex justify-between items-center px-4 py-[2px]"
                          type="button"
                          onClick={handleShowCustomizedVariable}
                        >
                          新增個人化變數
                          <i className="ri-arrow-drop-down-line"></i>
                        </button>
                        <ul
                          className={
                            "absolute w-full top-[40px] shadow-md z-10 " +
                            (!showCustomizedVariable && "hidden")
                          }
                        >
                          {selectedCustomizedMail.headers.map((item, index) => (
                            <li
                              key={index}
                              className="w-full bg-[var(--color-neutral-50)]"
                            >
                              <button
                                type="button"
                                className="w-full pl-[30px] py-[6px] text-left hover:bg-[var(--color-neutral-100)]"
                                onClick={handleAddCustomizedVariable}
                              >
                                {item}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  {form.contentMode === "textMode" ? (
                    <textarea
                      className="w-full border-[1px] border-[var(--color-neutral-400)] h-[300px]"
                      name="content"
                      value={form.content}
                      onChange={handleFormChange}
                    ></textarea>
                  ) : (
                    <div className="w-full">
                      <CKEditor
                        editor={Editor}
                        data={form.content}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setForm((prev) => ({
                            ...prev,
                            content: data,
                          }));
                        }}
                      />
                    </div>
                  )}
                </FormControl>
                <div className="flex">
                  <AttachmentPop />
                  <ul className="flex items-center gap-4 flex-1 ml-4">
                    {selectedAttachments.map((selected, index) => {
                      return <li key={index}>{selected.name}</li>;
                    })}
                  </ul>
                </div>
                <FormControl>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    className="!text-[var(--color-neutral-900)]"
                  >
                    寄送時間<span className="req">*</span>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    sx={{ gap: 4.5 }}
                    value={sendtimeMode}
                    onChange={handleModeSendTimeChange}
                  >
                    <FormControlLabel
                      value="now"
                      control={<Radio sx={{ mr: 1 }} />}
                      label="立刻寄送"
                      sx={{ m: 0, "& .MuiRadio-root": { p: 0 } }}
                    />
                    <FormControlLabel
                      value="ordertime"
                      control={<Radio sx={{ mr: 1 }} />}
                      label="指定時間寄送"
                      sx={{ m: 0, "& .MuiRadio-root": { p: 0 } }}
                    />
                  </RadioGroup>
                  {sendtimeMode === "ordertime" && (
                    <input
                      id="date"
                      type="datetime-local"
                      className="w-full mt-4 py-[10px] outline outline-1 outline-[var(--color-neutral-400)]"
                      name="send_time"
                      value={form.send_time}
                      onChange={handleFormChange}
                    />
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    className="!text-[var(--color-neutral-900)]"
                  >
                    備註
                  </FormLabel>
                  <TextField
                    type="text"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        px: 2,
                        py: "10px",
                        border: 1,
                        borderColor: "var(--color-neutral-400)",
                      },
                    }}
                    name="remark"
                    value={remark}
                    onChange={handleRemarkChange}
                  />
                </FormControl>
              </form>
            </div>
            <div className="w-[886px] flex justify-between p-9 pr-0">
              <div>
                <ThirdButton text={"刪除"} onClick={handleDelete} />
                <ThirdButton
                  text={"暫存"}
                  disabled={
                    form.title.length === 0 ||
                    form.content.length === 0 ||
                    (!selectedCustomizedMail.id && form.receiver === null) ||
                    (selectedCustomizedMail.id && form.receiver !== null)
                  }
                  margin={2}
                  onClick={handleStore}
                />
              </div>
              <div>
                <ThirdButton
                  text={"預覽"}
                  disabled={
                    form.title.length === 0 || form.content.length === 0
                  }
                  onClick={handleOpen}
                />
                <Dialog
                  open={getOpen()}
                  onClose={handleClose}
                  sx={{
                    "& .MuiDialog-paper": {
                      p: 4.5,
                      borderRadius: 5,
                      maxWidth: "none",
                      minWidth: "50%",
                    },
                  }}
                >
                  <DialogTitle
                    component={"h3"}
                    sx={{
                      fontSize: 28,
                      fontWeight: "normal",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      p: 0,
                    }}
                  >
                    {form.title}
                    <button onClick={handleClose}>
                      <i className="ri-close-line"></i>
                    </button>
                  </DialogTitle>
                  <DialogContent sx={{ p: 0 }}>
                    <Divider sx={{ my: 2 }} />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: previewData ? previewData : form.content,
                      }}
                    ></div>
                  </DialogContent>
                </Dialog>
                {diff ? (
                  <CustomDialog
                    className={"btn primary-btn !ml-4"}
                    disabled={
                      form.title.length === 0 ||
                      form.content.length === 0 ||
                      form.send_time === null ||
                      (!selectedCustomizedMail.id && form.receiver === null) ||
                      (selectedCustomizedMail.id && form.receiver !== null)
                    }
                    buttonTitle={"寄送"}
                    dialogTitle={"個人化信件欄位"}
                    dialogContent={
                      "信件內容欄位與檔案內容欄位不符，確定要寄送嗎？"
                    }
                    confirmFuncion={() =>
                      id === null ? createMail(form) : updateMail(form, id)
                    }
                    navigatePath={"/mail/sended"}
                    reload={true}
                  />
                ) : (
                  <CustomDialog
                    className={"btn primary-btn !ml-4"}
                    disabled={
                      form.title.length === 0 ||
                      form.content.length === 0 ||
                      form.send_time === null ||
                      (!selectedCustomizedMail.id && form.receiver === null) ||
                      (selectedCustomizedMail.id && form.receiver !== null)
                    }
                    buttonTitle={"寄送"}
                    dialogTitle={"確定要寄送嗎？"}
                    confirmFuncion={() =>
                      id === null ? createMail(form) : updateMail(form, id)
                    }
                    navigatePath={"/mail/sended"}
                    reload={true}
                  />
                )}
              </div>
            </div>
            {getShowPopup().show && (
              <CustomAlertDialog
                show={getShowPopup().show}
                message={getShowPopup().message}
                confirmFunction={() => {
                  setIsShow(false);
                  if (getShowPopup().path) {
                    navigate(getShowPopup().path);
                  }
                }}
              />
            )}
          </customizedMailContext.Provider>
        </templateContext.Provider>
      </attachmentContext.Provider>
    </receiverContext.Provider>
  );
}
