import { useContext } from "react";
import { templateContext } from ".";
import { FormatDate } from "../../../utils";
import { baseUrl, copyTemplate, deleteTemplate } from "../../../api";
import CustomDialog from "../../../components/CustomDialog";
import { Button } from "@mui/material";

export default function TemplateItem({ props }) {
  const { currId, setCurrId } = useContext(templateContext);

  /**
   * 目前選取的檔案
   */
  const handleClick = (e) => {
    if (currId === e) {
      setCurrId(0);
    } else {
      setCurrId(e);
    }
  };

  return (
    <li
      className={
        "template-item" +
        (currId === props.template_id ? " active-template-item" : "")
      }
    >
      <button
        className="flex-1 overflow-hidden p-6"
        onClick={() => handleClick(props.template_id)}
      >
        <p className="truncate mb-2">{props.template_name}</p>
        <div className="text-[var(--color-neutral-400)] truncate text-left">
          <i className="ri-time-line text-base mr-[9px]"></i>
          <span>{FormatDate(props.update_date)}</span>
        </div>
      </button>
      <div className="flex gap-9 mr-6">
        <CustomDialog
          icon={<i className="ri-pencil-line"></i>}
          dialogTitle={"重新命名"}
          inputProps={{
            url: `/api/file/update/template/${props.template_id}`,
            method: "patch",
            props: { name: props.template_name },
          }}
          reload={true}
        />
        <CustomDialog
          icon={<i className="ri-file-copy-line"></i>}
          copyFuncion={() => copyTemplate(props.template_id)}
          dialogTitle={"複製成功"}
          reload={true}
        />
        <Button
          sx={{
            color: "var(--color-neutral-900)",
            p: 0,
            minWidth: "auto",
            ":hover": { bgcolor: "transparent" },
          }}
          href={baseUrl + props.url}
          target="_blank"
          download={props.template_name}
        >
          <i className="ri-download-2-line"></i>
        </Button>
        <CustomDialog
          icon={<i className="ri-delete-bin-7-line"></i>}
          confirmFuncion={() => deleteTemplate(props.template_id)}
          dialogTitle={"確定要刪除嗎？"}
          reload={true}
        />
      </div>
    </li>
  );
}
