import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  baseUrl,
  createCustomizedMail,
  getCustomizedMail,
  getCustomizedMailExample,
} from "../../../api";
import CustomizedMailItem from "./CustomizedMailItem";
import ImportFailed from "../../../components/ImportFailed";
import {
  PrimaryButton,
  SecondaryButton,
  UploadButton,
} from "../../../components/Button";
import { useOpen, useSearch, useShowPopup } from "../../../hooks";
import CustomAlertDialog from "../../../components/CustomAlertDialog";
import SearchInput from "../../../components/SearchInput";

export default function CustomizedMail() {
  const { getSearchValue, setSearchValue } = useSearch();
  const { getOpen, setOpen } = useOpen();
  const [data, setData] = useState([]);
  const [failedData, setFailedData] = useState();
  const { setFalse, getShowPopup, setIsShow } = useShowPopup();

  useEffect(() => {
    setFailedData();
    getCustomizedMail().then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        setData(response.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 範例下載按鈕執行動作
   */
  const handleDownload = () => {
    getCustomizedMailExample().then((response) => {
      if (response.success === false) {
      } else {
        let a = document.createElement("a");
        a.href = baseUrl + response.data.url;
        a.download = "result";
        a.click();
      }
    });
  };

  /**
   * 檔案規範popup
   */
  const handleOpen = () => {
    setOpen(true);
  };

  /**
   * 檔案規範popup
   */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * 上傳檔案
   */
  const handleUpload = (e) => {
    let formdata = new FormData();
    let customizedmailFile = e.target;
    if (customizedmailFile.files[0] !== undefined) {
      formdata.append("name", customizedmailFile.files[0].name.split(".")[0]);
      formdata.append(
        "file",
        customizedmailFile.files[0],
        customizedmailFile.files[0].name
      );
      createCustomizedMail(formdata).then((response) => {
        if (response.success === false) {
          if (response.errorMesg.includes("failed_emails")) {
            const mesg = response.errorMesg.replaceAll("Error: ", "");
            setFailedData((prev) => ({
              ...prev,
              totalLength: JSON.parse(mesg).count,
              failedData: JSON.parse(mesg).failed_emails,
            }));
          } else {
            setFalse(true, response.errorMesg);
          }
        } else {
          setFailedData((prev) => ({
            ...prev,
            totalLength: data.length,
            failedData: [],
          }));
        }
      });
    }
  };

  const handleSearch = (data) => {
    return data
      .filter(
        (item) =>
          item.customized_data_name.includes(getSearchValue()) ||
          item.ext.includes(getSearchValue())
      )
      .map((item) => (
        <CustomizedMailItem
          id={item.customized_data_id}
          name={item.customized_data_name}
          update_date={item.update_date}
          ext={item.ext}
          url={item.url}
        />
      ));
  };

  /**
   * 回到列表
   */
  const handleBack = () => {
    window.location.reload();
    setFailedData();
  };

  return (
    <>
      {!failedData ? (
        <>
          <div className="flex justify-between mb-6">
            <div className="">
              <UploadButton
                text={
                  <>
                    <i className="ri-upload-2-line"></i>.xlsx檔案
                  </>
                }
                accept=".xlsx,.xls,.csv"
                onChange={handleUpload}
              />
              <SecondaryButton
                classname="btn third-btn svg-btn !ml-8"
                text={
                  <>
                    <i className="ri-file-excel-2-line"></i>
                    範例下載
                  </>
                }
                onClick={handleDownload}
              />
              <button
                className="underline text-[var(--color-primary-1)] ml-8"
                onClick={handleOpen}
              >
                檔案規範
              </button>
              <Dialog
                open={getOpen()}
                onClose={handleClose}
                sx={{ "& .MuiDialog-paper": { borderRadius: 5, p: 3 } }}
              >
                <DialogTitle
                  component={"h2"}
                  sx={{
                    fontSize: 32,
                    fontWeight: "normal",
                    textAlign: "center",
                    p: 0,
                    mb: "29px",
                  }}
                >
                  檔案規範
                </DialogTitle>
                <DialogContent sx={{ p: 0, mb: "29px" }}>
                  <ol className="text-[var(--color-neutral-600)] p3">
                    <li>1.檔案內的欄位標題只能使用英文</li>
                    <li>2.所有欄位不得為空值</li>
                    <li>3.E-mail必填，並確認格式</li>
                    <li>4.檔案名單不會匯入至名單管理中</li>
                  </ol>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center" }}>
                  <PrimaryButton text={"確認"} onClick={handleClose} />
                </DialogActions>
              </Dialog>
            </div>
            <SearchInput
              searchValue={getSearchValue()}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <div>
            <ul className="h-[calc(100vh-350px)] flex flex-col gap-6 overflow-y-auto">
              {handleSearch(data)}
            </ul>
          </div>
        </>
      ) : (
        <ImportFailed
          type={"customized"}
          data={failedData}
          handleReupload={handleBack}
        />
      )}
      {getShowPopup().show && (
        <CustomAlertDialog
          show={getShowPopup().show}
          message={getShowPopup().message}
          confirmFunction={() => setIsShow(false)}
        />
      )}
    </>
  );
}
