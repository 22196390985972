import { useParams } from "react-router-dom";
import { getMailDetail, getTeamList } from "../../../../api";
import { useEffect, useState } from "react";
import { sortFunc } from "../../../../utils";
import { Backdrop, CircularProgress } from "@mui/material";
import MailDetailItem from "../../../../components/MailDetailItem";
import { teamContext } from "../../../../context";
import { FixedSizeList } from "react-window";
import { SortButton } from "../../../../components/Button";
import {
  useBackdropClick,
  useSearch,
  useShowPopup,
  useSort,
} from "../../../../hooks";
import CustomAlertDialog from "../../../../components/CustomAlertDialog";
import SearchInput from "../../../../components/SearchInput";

const valueList = [
  { chi: "所有收件人", eng: "all" },
  { chi: "到達人數", eng: "arrived" },
  { chi: "開信人數", eng: "opened" },
  { chi: "點擊人數", eng: "clicked" },
  { chi: "封鎖人數", eng: "blocked" },
  { chi: "無效信箱", eng: "bounced" },
  { chi: "未開信名單", eng: "unopened" },
];

/**
 * 信件明細
 */
export default function Detail() {
  const params = useParams();
  const [hasCustomizedData, setHasCustomizedData] = useState(false);
  const [fetchData, setFetchData] = useState([]);
  const [value, setValue] = useState("所有收件人");
  const [userList, setUserList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getSearchValue, setSearchValue } = useSearch();
  const { setFalse, getShowPopup, setIsShow } = useShowPopup();
  const { getClicked, sortByPosition, updateClicked } = useSort(4);
  const { backdropClick } = useBackdropClick();

  const handleBackdropClick = (e) => backdropClick(e, setIsLoading(false));

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    getMailDetail(params.id).then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        setHasCustomizedData(
          response.data.customized_data_id !== null ? true : false
        );
        let list = [];
        response.data.data.forEach((element) => {
          element.data.forEach((item) => {
            list.push(item);
          });
        });
        setFetchData(response.data.data);
        setUserList(list);
      }
      setIsLoading(false);
    });
    getTeamList().then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        setTeamList(response.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);

    const result = fetchData.map((items) => {
      const data = items.data.filter(
        (item) =>
          (item.unit !== null && item.unit.includes(value)) ||
          item.receiver_name.includes(value) ||
          (item.email !== null && item.email.includes(value)) ||
          (item.receiver_email !== null && item.receiver_email.includes(value))
      );
      return { email_status: items.email_status, data: data };
    });
    let list = [];
    result.forEach((element) => {
      element.data.forEach((item) => {
        list.push(item);
      });
    });
    setUserList(list);
  };

  /**
   * 將data整理成符合使用者列表的格式
   * @param {Array} items
   * @returns {Array}
   */
  function concatList(items) {
    let list = [];
    items.forEach((element) => {
      element.data.forEach((item) => {
        list.push(item);
      });
    });
    return list;
  }

  function filterResult(value) {
    return fetchData.filter((res) => res.email_status === value);
  }

  /**
   * 設定列表（到達人數、開信人數...）
   */
  const handleClick = (e) => {
    const email_status = e.target.name;
    setValue(e.target.value);

    if (email_status === "all") {
      setUserList(concatList(fetchData));
    } else if (email_status === "arrived") {
      const result = fetchData.filter(
        (res) =>
          res.email_status === "sent" ||
          res.email_status === "opened" ||
          res.email_status === "clicked"
      );
      setUserList(concatList(result));
    } else if (email_status === "opened") {
      const result = fetchData.filter(
        (res) => res.email_status === "opened" || res.email_status === "clicked"
      );
      setUserList(concatList(result));
    } else if (email_status === "unopened") {
      const result = fetchData.filter((res) => res.email_status === "sent");
      setUserList(concatList(result));
    } else {
      const result = filterResult(email_status);
      setUserList(concatList(result));
    }
  };

  const sortbtnClickHandler = (e) => {
    let position = parseInt(e.target.value);
    const updateClick = updateClicked(position);
    sortByPosition(updateClick);
    sortFunc(updateClick[position], userList, e.target.name, () => {
      if (value === "所有收件人") {
        return setUserList(concatList(fetchData));
      } else if (value === "到達人數") {
        const result = fetchData.filter(
          (res) =>
            res.email_status === "sent" ||
            res.email_status === "opened" ||
            res.email_status === "clicked"
        );
        return setUserList(concatList(result));
      } else if (value === "開信人數") {
        const result = fetchData.filter(
          (res) =>
            res.email_status === "opened" || res.email_status === "clicked"
        );
        return setUserList(concatList(result));
      } else if (value === "開信人數") {
        const result = filterResult("clicked");
        return setUserList(concatList(result));
      } else if (value === "封鎖人數") {
        const result = filterResult("blocked");
        return setUserList(concatList(result));
      } else if (value === "無效信箱") {
        const result = filterResult("bounced");
        return setUserList(concatList(result));
      } else if (value === "未開信名單") {
        const result = filterResult("sent");
        return setUserList(concatList(result));
      }
    });
  };

  const item = ({ index, style, data }) => (
    <MailDetailItem
      key={index}
      style={style}
      index={index}
      data={fetchData}
      list={data[index]}
      hasCustomizedData={hasCustomizedData}
    />
  );

  return (
    <>
      {isLoading && (
        <Backdrop open={isLoading} onClick={handleBackdropClick}>
          <CircularProgress />
        </Backdrop>
      )}
      {!isLoading && (
        <teamContext.Provider value={{ teamList, setTeamList }}>
          <>
            <div className="bg-[var(--color-neutral-50)] p-9">
              <div className="flex justify-between">
                <h2>{`${value}(${userList.length})`}</h2>
                <div className="flex gap-2 items-center">
                  <SearchInput
                    searchValue={getSearchValue()}
                    onChange={handleSearch}
                  />
                  <div className="relative group">
                    <button className="select w-[236px] flex justify-between items-center">
                      {value}
                      <i className="ri-arrow-down-s-line leading-none"></i>
                    </button>
                    <ul className="hidden w-[236px] option-list group-hover:block">
                      {valueList.map((result, index) => {
                        return (
                          <li key={index}>
                            <button
                              name={result.eng}
                              value={result.chi}
                              onClick={handleClick}
                              className="dropdown"
                            >
                              {result.chi}
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="flex items-center py-4 px-10 mt-6 bg-[var(--color-neutral-100)]">
                <span className="w-[10%] font-bold mr-1">編號</span>
                <span className="w-[15%] font-bold mr-1">
                  <label className="flex items-center gap-[2px] m-0 cursor-pointer">
                    <input
                      type="button"
                      name="unit"
                      value={0}
                      className="absolute top-0 invisible"
                      onClick={sortbtnClickHandler}
                    />
                    單位
                    <SortButton props={getClicked(0)} />
                  </label>
                </span>
                <span className="w-[15%] font-bold mr-1">
                  <label className="flex items-center gap-[2px] m-0 cursor-pointer">
                    <input
                      type="button"
                      name="receiver_name"
                      value={1}
                      className="absolute top-0 invisible"
                      onClick={sortbtnClickHandler}
                    />
                    聯絡人
                    <SortButton props={getClicked(1)} />
                  </label>
                </span>
                <span className="w-[30%] font-bold mr-1">
                  <label className="flex items-center gap-[2px] m-0 cursor-pointer">
                    <input
                      type="button"
                      name="email"
                      value={2}
                      className="absolute top-0 invisible"
                      onClick={sortbtnClickHandler}
                    />
                    Email
                    <SortButton props={getClicked(2)} />
                  </label>
                </span>
                <span className="w-[10%] font-bold mr-1">點擊</span>
                <span className="w-1/5 font-bold">
                  <label className="flex items-center gap-[2px] m-0 cursor-pointer">
                    <input
                      type="button"
                      name="opened_time"
                      value={3}
                      className="absolute top-0 invisible"
                      onClick={sortbtnClickHandler}
                    />
                    開信時間
                    <SortButton props={getClicked(3)} />
                  </label>
                </span>
              </div>
              <FixedSizeList
                height={
                  window.innerHeight > userList.length * 68
                    ? userList.length * 68
                    : window.innerHeight - 152
                }
                itemCount={userList.length}
                itemSize={68}
                width={"100%"}
                itemData={userList}
              >
                {item}
              </FixedSizeList>
            </div>
            {getShowPopup().show && (
              <CustomAlertDialog
                show={getShowPopup().show}
                message={getShowPopup().message}
                confirmFunction={() => setIsShow(false)}
              />
            )}
          </>
        </teamContext.Provider>
      )}
    </>
  );
}
