import { useParams } from "react-router-dom";
import { baseUrl, getMailContent } from "../../../../api";
import { useEffect, useState } from "react";
import { useShowPopup } from "../../../../hooks";
import CustomAlertDialog from "../../../../components/CustomAlertDialog";

export default function Content() {
  const params = useParams();
  const [mail, setMail] = useState("");
  const [attachments, setAttacments] = useState([]);
  const { setFalse, getShowPopup, setIsShow } = useShowPopup();

  useEffect(() => {
    window.scrollTo(0, 0);
    getMailContent(params.id).then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        setMail(response.data.content);
        setAttacments(response.data.attachment_id_array);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  return (
    <>
      <div
        className="bg-[var(--color-neutral-50)] p-9"
        dangerouslySetInnerHTML={{ __html: mail }}
      ></div>
      {attachments !== undefined && (
        <ul className="flex gap-6 mt-6">
          {attachments.map((result) => {
            return (
              <li className="bg-[var(--color-neutral-50)]">
                <a
                  href={baseUrl + result.url}
                  target="_blank"
                  rel="noreferrer"
                  className="block p-9"
                >
                  {result.name}
                </a>
              </li>
            );
          })}
        </ul>
      )}
      {getShowPopup().show && (
        <CustomAlertDialog
          show={getShowPopup().show}
          message={getShowPopup().message}
          confirmFunction={() => setIsShow(false)}
        />
      )}
    </>
  );
}
