import { useEffect, useState } from "react";
import AttachmentItem from "./AttachmentItem";
import { createAttachment, getAttachmentList } from "../../../api";
import { useLocation } from "react-router-dom";
import { UploadButton } from "../../../components/Button";
import { useSearch, useShowPopup } from "../../../hooks";
import CustomAlertDialog from "../../../components/CustomAlertDialog";
import SearchInput from "../../../components/SearchInput";

export default function Attachment() {
  const [attachment, setAttachment] = useState([]);
  const { getSearchValue, setSearchValue } = useSearch();
  const { setFalse, getShowPopup, setIsShow } = useShowPopup();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    getAttachmentList().then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        setAttachment(response.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const handleUpload = (e) => {
    var formdata = new FormData();
    let attachmentFile = e.target;
    if (attachmentFile.files[0]) {
      const type = attachmentFile.files[0].type.split("/")[1];
      formdata.append(
        "name",
        attachmentFile.files[0].name.split("." + type)[0]
      );
      formdata.append(
        "file",
        attachmentFile.files[0],
        attachmentFile.files[0].name
      );

      createAttachment(formdata).then((response) => {
        if (response.success === false) {
          if (response.errorMesg.includes("file size too large")) {
            setFalse(true, "檔案大小超過限制");
          } else {
            setFalse(true, response.errorMesg);
          }
        } else {
          window.location.reload();
        }
      });
    }
  };

  const handleSearch = (data) => {
    return data
      .filter(
        (item) =>
          item.attachment_name.includes(getSearchValue()) ||
          item.ext.includes(getSearchValue())
      )
      .map((item) => (
        <AttachmentItem
          key={item.attachment_id}
          id={item.attachment_id}
          name={item.attachment_name}
          url={item.url}
          ext={item.ext}
          update_date={item.update_date}
        />
      ));
  };

  return (
    <>
      <div className="flex justify-between mb-6">
        <div>
          <UploadButton
            text={
              <>
                <i className="ri-upload-2-line"></i>上傳檔案
              </>
            }
            onChange={handleUpload}
          />
          <span className="text-[var(--color-neutral-600)] ml-8">
            檔案大小上限: 5MB
          </span>
        </div>
        <SearchInput
          searchValue={getSearchValue()}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      <ul className="h-[calc(100vh-350px)] flex flex-col gap-6 overflow-y-auto">
        {handleSearch(attachment)}
      </ul>
      {getShowPopup().show && (
        <CustomAlertDialog
          show={getShowPopup().show}
          message={getShowPopup().message}
          confirmFunction={() => setIsShow(false)}
        />
      )}
    </>
  );
}
