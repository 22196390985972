import { useContext } from "react";
import { sidebarContext } from "../../App";

/**
 *
 * @typedef {Object} Props
 * @property {string} header
 * @property {component} headerChildren
 * @property {component} tabs
 * @property {component} children
 * @param {Props} props
 */
export default function PageWrap({ header, headerChildren, tabs, children }) {
  const { showSidebar } = useContext(sidebarContext);
  return (
    <div
      className={
        "bg-[#F6F6F6] p-9 mt-[60px] h-[calc(100vh-60px)] transition-all overflow-auto" +
        (showSidebar ? " ml-[312px]" : "")
      }
    >
      <div className="flex justify-between items-center">
        <h1>{header}</h1>
        {headerChildren}
      </div>
      {tabs && <ul className="flex my-6">{tabs}</ul>}
      <div className="w-full">{children}</div>
    </div>
  );
}
