import { useNavigate } from "react-router-dom";
import loginImg from "../../images/login.png";
import { useEffect, useState } from "react";
import { login, userRequest } from "../../api";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  TextField,
} from "@mui/material";
import { getLocalStorage, setLocalStorage } from "../../utils";
import { PrimaryButton } from "../../components/Button";
import { useShowPopup } from "../../hooks";
import CustomAlertDialog from "../../components/CustomAlertDialog";

export default function Login() {
  const [useremail, setUseremail] = useState(getLocalStorage("email"));
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(getLocalStorage("email") === "");

  const { setFalse, getShowPopup, setIsShow } = useShowPopup();

  const navigate = useNavigate();

  useEffect(() => {
    if (getLocalStorage("email") !== "") {
      setChecked(true);
    } else {
      setChecked(false);
    }
    if (getLocalStorage("token") !== "") {
      navigate("/mail/sended", { replace: true });
    }
  }, [navigate]);

  const handleLogin = () => {
    setLoading(true);
    login(useremail, password).then((response) => {
      setLoading(false);
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        setLocalStorage(
          response.data.name,
          useremail,
          password,
          response.data.token,
          response.data.platform_id
        );

        if (!checked) {
          localStorage.removeItem("email");
        }

        userRequest.interceptors.request.use(
          (config) => {
            config.headers["Authorization"] = getLocalStorage("token");
            return config;
          },
          (err) => Promise.reject(err)
        );

        navigate("/mail/sended", { replace: true });
      }
    });
  };

  const handleUseremail = (e) => {
    setUseremail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  /**
   * 記住信箱
   */
  const handleChecked = (e) => {
    let isSelected = e.target.checked;
    if (isSelected) {
      setChecked(true);
      localStorage.setItem("email", useremail);
    } else {
      setChecked(false);
      localStorage.removeItem("email");
    }
  };

  return (
    <>
      <div className="flex">
        <div className="flex-1 h-screen bg-[#e3f2fb] flex justify-center items-center">
          <img src={loginImg} alt="login-img" className="m-auto w-[548px]" />
        </div>
        <div className="flex-1 h-screen flex justify-center items-center">
          <form className="p-9 flex flex-col items-center gap-6 w-1/2">
            <h1 className="text-center mb-6">登入</h1>
            <FormControl fullWidth>
              <FormLabel sx={{ color: "var(--color-neutral-800)" }}>
                聯絡信箱<span className="req">*</span>
              </FormLabel>
              <TextField
                placeholder="請輸入信箱"
                type="email"
                required
                value={useremail}
                onChange={handleUseremail}
                sx={{
                  "& .MuiInputBase-input": { px: 2, py: 0.75 },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--color-neutral-400)",
                  },
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel sx={{ color: "var(--color-neutral-800)" }}>
                密碼<span className="req">*</span>
              </FormLabel>
              <TextField
                placeholder="請輸入密碼"
                type="password"
                required
                value={password}
                onChange={handlePassword}
                sx={{
                  "& .MuiInputBase-input": { px: 2, py: 0.75 },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--color-neutral-400)",
                  },
                }}
              />
            </FormControl>
            <FormControlLabel
              sx={{
                m: 0,
                color: "var(--color-neutral-800)",
                "& .MuiCheckbox-root": { p: 0, mr: 1.75 },
              }}
              control={<Checkbox size="small" />}
              label="記住聯絡信箱"
              checked={checked}
              onChange={handleChecked}
            />
            <PrimaryButton
              text={"登入"}
              disabled={loading}
              onClick={handleLogin}
            />
          </form>
        </div>
      </div>
      {getShowPopup().show && (
        <CustomAlertDialog
          show={getShowPopup().show}
          message={getShowPopup().message}
          confirmFunction={() => setIsShow(false)}
        />
      )}
    </>
  );
}
