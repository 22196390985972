import Bread from "../Bread";
import { useContext, useRef } from "react";
import { sidebarContext } from "../../App";
import { IconButton } from "@mui/material";
import { getLocalStorage } from "../../utils";
import { useOpen } from "../../hooks";
import { Link } from "react-router-dom";
import eportLogo from "../../images/eport-logo.png";
import luckerLogo from "../../images/lucker-logo.png";

export default function Navbar() {
  const { getOpen, setOpen } = useOpen();
  const { setShowSidebar } = useContext(sidebarContext);

  const handleShowSidebar = () => setShowSidebar((prev) => !prev);
  const handleShowList = () => setOpen((prev) => !prev);

  const ref = useRef();
  const handleBackdropClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setOpen(false);
    }
  };

  return (
    <nav className="nav" onClick={handleBackdropClick}>
      <div className="flex items-center">
        <IconButton
          className="w-8 h-8"
          sx={{
            bgcolor: "var(--color-primary-4)",
            mr: 3,
            ":hover": { bgcolor: "var(--color-primary-4)" },
          }}
          onClick={handleShowSidebar}
        >
          <i className="ri-menu-line text-[var(--color-primary-1)]"></i>
        </IconButton>
        <Link to={"/"}>
          {getLocalStorage("mode") === "1" ? (
            <img src={eportLogo} alt="" className="h-9" />
          ) : (
            <img src={luckerLogo} alt="" className="h-9" />
          )}
        </Link>
        <Bread />
      </div>
      <button
        ref={ref}
        className="text-[14px] leading-5 relative"
        onClick={handleShowList}
      >
        {getLocalStorage("name")}
        <i className="ri-arrow-down-s-line text-[10px] ml-[3px]"></i>
        <ul
          className={
            "w-[124px] option-list top-8 right-0 text-base" +
            (!getOpen() ? " hidden" : "")
          }
        >
          <li className="dropdown">
            <Link to={"/account"}>更改密碼</Link>
          </li>
          <li className="dropdown">
            <Link
              to={"/"}
              replace
              onClick={() => {
                localStorage.removeItem("token");
                localStorage.removeItem("mode");
              }}
            >
              登出
            </Link>
          </li>
        </ul>
      </button>
    </nav>
  );
}
