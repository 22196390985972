import CustomDialog from "../CustomDialog";
import { deleteFolder } from "../../api";
import { useOpen } from "../../hooks";
import { Link } from "react-router-dom";

export default function Folder({ id, title }) {
  const { getOpen, setOpen } = useOpen();

  const handleClick = () => setOpen((prev) => !prev);

  return (
    <li className="folder relative">
      <Link
        className="flex-1 flex items-center p-6 text-[var(--color-neutral-900)]"
        to={`/mail/sended?${id}`}
      >
        <i className="ri-folder-fill text-[var(--color-neutral-400)] leading-none mr-4"></i>
        {title}
      </Link>
      <button className="p-6" onClick={handleClick}>
        <i className="ri-more-2-fill"></i>
      </button>
      <ul
        className={
          "option-list w-[146px] -bottom-12 right-2" +
          (!getOpen() ? " hidden" : "")
        }
      >
        <li>
          <CustomDialog
            fullWidth
            className="dropdown !justify-start !rounded-none"
            buttonTitle={"重新命名"}
            dialogTitle={"重新命名"}
            inputProps={{
              url: "/api/mail/management/update/edm_file/name",
              method: "patch",
              props: { edm_file_id: id, file_name: title.split("(")[0] },
            }}
            reload={true}
          />
        </li>
        <li>
          <CustomDialog
            fullWidth
            className="dropdown !justify-start !rounded-none"
            buttonTitle={"刪除"}
            dialogTitle={"確定要刪除嗎？"}
            dialogContent={"內部信件也將同時被刪除"}
            confirmFuncion={() => deleteFolder(id)}
            reload={true}
          />
        </li>
      </ul>
    </li>
  );
}
