import Pagewrap from "../../../components/Pagewrap";
import { Tab } from "../../../components/Tab";
import { TabContent } from "../../../components/TabContent";
import Effectiveness from "./Effectiveness";
import Detail from "./Detail";
import { useLocation } from "react-router-dom";
import Content from "./Content";

/**
 * 信件內頁
 */
export default function MailInnerPage() {
  const location = useLocation();

  return (
    <Pagewrap
      header={location.state}
      tabs={
        <>
          <Tab
            href={"effectiveness"}
            mailInfo={location.state}
            text={"成效數據"}
            isActive={location.pathname.includes("effectiveness")}
          />
          <Tab
            href={"detail"}
            mailInfo={location.state}
            text={"信件明細"}
            isActive={location.pathname.includes("detail")}
          />
          <Tab
            href={"content"}
            mailInfo={location.state}
            text={"檢視活動"}
            isActive={location.pathname.includes("content")}
          />
        </>
      }
    >
      <TabContent
        isActive={location.pathname.includes("effectiveness")}
        children={<Effectiveness />}
      />
      <TabContent
        isActive={location.pathname.includes("detail")}
        children={<Detail />}
      />
      <TabContent
        isActive={location.pathname.includes("content")}
        children={<Content />}
      />
    </Pagewrap>
  );
}
